/* eslint-disable no-unused-vars */
import { defineStore } from "pinia";
import http from "../config/apiConfig";

export const usePaymentGroupStore = defineStore("paymentGroup", {
  actions: {
    async paymentGroupinfoFetch(payload) {
      return new Promise((resolve, reject) => {
        try {
          const pathUrl = "/pawn-svc/paymentGrp/info/" + payload.uid;
          const response = http.get(pathUrl);
          resolve(response);
        } catch (e) {
          reject(e);
        }
      });
    },
    async paymentGrouplistFetch(payload) {
      const body = {
        page: {
          index: payload.index,
          size: payload.size,
          sort: "",
        },
        filter: {
          cusIdNumber: payload.cusIdNumber,
          "cusName:contains": payload.cusName,
          status: payload.status,
        },
      };
      return new Promise((resolve, reject) => {
        try {
          const pathUrl = "/pawn-svc/paymentGrp/list";
          const response = http.post(pathUrl, body);
          resolve(response);
        } catch (e) {
          reject(e);
        }
      });
    },

    async paymentGroupHistoryFetch(payload) {
      const body = {
        page: {
          index: payload.index,
          size: payload.size,
          sort: "",
        },
        filter: {
          shopUid: payload.shopUid,
          cusUid: payload.cusUid,
          refNumber: "",
          fromDate: payload.fromDate,
          toDate: payload.toDate,
        },
      };
      return new Promise((resolve, reject) => {
        try {
          const pathUrl = "/pawn-svc/paymentGrp/history";
          const response = http.post(pathUrl, body);
          resolve(response);
        } catch (e) {
          reject(e);
        }
      });
    },

    async paymentGroupnewFetch(payload) {
      const body = {};
      return new Promise((resolve, reject) => {
        try {
          const pathUrl = "/pawn-svc/paymentGrp/new";
          const response = http.post(pathUrl, body);
          resolve(response);
        } catch (e) {
          reject(e);
        }
      });
    },
    async paymentGroupupdateFetch(payload) {
      const body = {
        uid: payload.uid,
        paySide: payload.paySide, // PAY, RECEIVE, NONE
        totalPay: payload.totalPay,
        roundTotalPay: payload.roundTotalPay,
        totalReceive: payload.totalReceive,
        roundTotalReceive: payload.roundTotalReceive,
        totalFeeReceive: payload.totalFeeReceive,
        totalFeePay: payload.totalFeePay,
        remark: payload.remark,
        version: payload.version,
      };
      return new Promise((resolve, reject) => {
        try {
          const pathUrl = "/pawn-svc/paymentGrp/update";
          const response = http.put(pathUrl, body);
          resolve(response);
        } catch (e) {
          reject(e);
        }
      });
    },

    async paymentGroupDeleteFetch(payload) {
      return new Promise((resolve, reject) => {
        try {
          const pathUrl = "/pawn-svc/paymentGrp/delete/" + payload.uid;
          const response = http.delete(pathUrl);
          resolve(response);
        } catch (e) {
          reject(e);
        }
      });
    },
    async updateFPImageFetch(payload) {
      const body = {
        paymentGroupUid: payload.paymentGroupUid,
        fpImageUid: payload.fpImageUid,
        fpTemplateUid: payload.fpTemplateUid,
        version: payload.version,
        otp: payload.otp,
      };
      return new Promise((resolve, reject) => {
        try {
          const pathUrl = "/pawn-svc/paymentGrp/update/fpImage";
          const response = http.put(pathUrl, body);
          resolve(response);
        } catch (e) {
          reject(e);
        }
      });
    },
  },
});
