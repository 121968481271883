<template>
  <div class="container-fluid">
    <div class="loading-state" v-if="loading">
      <ProgressSpinner fill="transparent" aria-label="Loading" />
    </div>
    <div class="row mb-5">
      <div class="col-12">
        <ul id="transactionsTab" class="nav nav-tabs" role="tablist">
          <li class="nav-item" role="presentation">
            <button id="first-transactions" class="nav-link" data-bs-toggle="tab" data-bs-target="#firstTransactions"
              type="button" role="tab" aria-controls="firstTransactions" aria-selected="true" @click="onClickTab('1')">
              {{ $t("content.gmoneyhis") }}
            </button>
          </li>

          <li class="nav-item" role="presentation">
            <button id="second-transactions" class="nav-link" data-bs-toggle="tab" data-bs-target="#secondTransactions"
              type="button" role="tab" aria-controls="secondTransactions" aria-selected="false"
              @click="onClickTab('2')">
              {{ $t("content.gmoneybiz") }}
            </button>
          </li>
        </ul>

        <div id="transactionsTabContent" class="tab-content">
          <div id="firstTransactions" class="tab-pane fade show active" role="tabpanel"
            aria-labelledby="first-transactions">
            <div class="card mt-2">
              <div class="card-header mb-0">
                <h5>{{ $t("content.gmoneyhis") }}</h5>
              </div>
              <div class="card-body pt-0">
                <div class="row">
                  <div class="col-lg-3">
                    <label class="form-label ms-0">{{ $t("content.sdate") }}</label>
                    <VueDatePicker v-model="start_date" :format="format" auto-apply :teleport="true"
                      :max-date="new Date()" :enable-time-picker="false" month-name-format="long" :clearable="false"
                      :text-input="textInputOptions" fluid />
                  </div>

                  <div class="col-lg-3">
                    <label class="form-label ms-0">{{ $t("content.edate") }}</label>
                    <VueDatePicker v-model="end_date" :format="format" auto-apply :teleport="true"
                      :max-date="new Date()" :enable-time-picker="false" month-name-format="long" :clearable="false"
                      :text-input="textInputOptions" fluid />
                  </div>

                  <div class="col-lg-3">
                    <label class="form-label ms-0">{{
                      $t("content.amnsourcemoney")
                    }}</label>
                    <select v-model="search_sourcemoney" class="form-select">
                      <option value="" selected>{{ $t("content.optall") }}</option>
                      <optgroup :label="$t('apps.mcash')">
                        <option v-for="(item, index) in listcashAcc" :value="item.uid" :key="index">
                          {{ item.name }}
                        </option>
                      </optgroup>
                      <optgroup :label="$t('content.amnbankacc')">
                        <option v-for="(item, index) in listbankpawn" :value="item.uid" :key="index">
                          {{ item.shortName }}
                        </option>
                      </optgroup>
                      <optgroup :label="$t('content.source_others')">
                        <option value="OTHERS" selected>{{ $t("content.amnothers") }}</option>
                      </optgroup>
                    </select>
                  </div>

                  <div class="col-lg-3">
                    <label class="form-label ms-0">{{
                      $t("content.tosource")
                    }}</label>
                    <select v-model="search_target" class="form-select">
                      <option value="" selected>{{ $t("content.optall") }}</option>
                      <optgroup :label="$t('apps.mcash')">
                        <option v-for="(item, index) in listcashAcc" :value="item.uid" :key="index">
                          {{ item.name }}
                        </option>
                      </optgroup>
                      <optgroup :label="$t('content.amnbankacc')">
                        <option v-for="(item, index) in listbankpawn" :value="item.uid" :key="index">
                          {{ item.shortName }}
                        </option>
                      </optgroup>
                      <optgroup :label="$t('content.source_others')">
                        <option value="OTHERS" selected>{{ $t("content.amnothers") }}</option>
                      </optgroup>
                    </select>
                  </div>

                  <div class="col-lg-3">
                    <label class="form-label ms-0">{{ $t("content.trans") }}</label>
                    <select class="form-select" v-model="search_trans">
                      <option value="" selected>{{ $t("content.optall") }}</option>
                      <option value="txDeposit" selected> {{ $t(datas.types[0].lb) }}
                      </option>
                      <option value="txWithdrawal" selected> {{
                        $t(datas.types[1].lb) }}
                      </option>
                      <option value="txTransfer" selected> {{ $t(datas.types[2].lb) }}
                      </option>
                    </select>
                  </div>

                  <div class="col-lg-3">
                    <label class="form-label ms-0">{{ $t("content.transc") }}</label>
                    <select class="form-select" v-model="search_cat" @change="checkSubCat">
                      <option value="" selected>{{ $t("content.optall") }}</option>
                      <option v-for="(a, opt1) in datas.FinancAll" :key="opt1" :value="a.val">
                        {{ a.items }}
                      </option>
                    </select>
                  </div>

                  <div class="col-lg-3">
                    <label class="form-label ms-0">{{ $t("content.transc_sub") }}</label>
                    <select class="form-select" v-model="search_subcat" :disabled="search_cat === ''">
                      <option value="" selected>{{ $t("content.optall") }}</option>
                      <option v-for="(a, opt1) in listsubcat" :key="opt1" :value="a.uid">
                        {{ a.name }}
                      </option>
                    </select>
                  </div>




                </div>

                <div class="row mt-4">
                  <div class="col-3 d-flex justify-content-between align-items-center"></div>
                  <div class="col-9 d-flex justify-content-end align-items-center">
                    <div class="me-3">
                      <material-button id="cardSearch-fn" color="primary" class="float-lg-start float-sm-end mb-3"
                        @click="loadDataApi" :disabled="start_date > end_date">
                        <i class="material-icons material-symbols-outlined">search</i> {{ $t("buttons.sear") }}
                      </material-button>
                    </div>
                    <div class="me-3">
                      <material-button id="cardSearch" color="danger" class="float-lg-start float-sm-end mb-3"
                        @click="cancelBtn">
                        <i class="material-icons material-symbols-outlined">close</i>
                        {{ btnCancel }}
                      </material-button>
                    </div>
                  </div>
                </div>
                <hr />
                <div class="row">
                  <div class="table-responsive">
                    <table class="table table-flush">
                      <thead class="thead-light">
                        <tr>
                          <th>#</th>
                          <th>{{ $t("content.dant") }}</th>
                          <th>{{ $t("content.transc") }}</th>
                          <th>{{ $t("content.transc_sub") }}</th>
                          <th>{{ $t("content.transac") }}</th>
                          <th>{{ $t("content.fromsource") }}</th>
                          <th>{{ $t("content.tosource") }}</th>
                          <th>{{ $t("content.amount") }}</th>
                          <th>{{ $t("content.detail") }}</th>
                          <th>{{ $t("content.ticketref") }}</th>
                          <th>{{ $t("content.cname") }}</th>
                          <th>{{ $t("content.file") }}</th>
                          <th>{{ $t("content.act") }}</th>
                        </tr>
                      </thead>
                      <tbody>

                        <tr v-for="(row, index) in listfundTx" :key="index">
                          <td class="text-sm font-weight-normal">
                            {{ formatNumberString((currentPage - 1) * perPage + index + 1) }}
                          </td>
                          <td class="text-sm font-weight-normal">
                            {{ format_datetime(row.txDate) }}
                          </td>
                          <td class="text-sm font-weight-normal">
                            {{ filterfundCategory(row.fundCategory) }}
                          </td>
                          <td class="text-sm font-weight-normal">
                            {{ filterfundTranType(row.fundTranTypeId) }}
                          </td>
                          <td class="text-sm font-weight-normal">
                            <span v-if="row.txType === 'TRANSFER'">
                              {{ $t(datas.types[2].lb) }}
                            </span>
                            <span v-else-if="row.txType === 'WITHDRAW'">
                              {{ $t(datas.types[1].lb) }}
                            </span>
                            <span v-else-if="row.txType === 'DEPOSIT'">
                              {{ $t(datas.types[0].lb) }}
                            </span>
                          </td>
                          <td class="text-sm font-weight-normal">
                            <span v-if="row.sourceType !== 'OTHERS'">
                              {{ filterMoneySource(row.sourceUid) }}
                            </span>
                            <span v-else>
                              {{ $t("content.amnothers") }} ({{ row.sourceAccNumber }})
                            </span>
                          </td>
                          <td class="text-sm font-weight-normal text-center">
                            <span v-if="row.targetUid === '0' && row.targetType !== 'OTHERS'">
                              -
                            </span>
                            <span v-else-if="row.targetType !== 'OTHERS'">
                              {{ filterMoneySource(row.targetUid) }}
                            </span>
                            <span v-else>
                              {{ $t("content.amnothers") }} ({{ row.targetAccNumber }})
                            </span>
                          </td>
                          <td class="text-sm font-weight-normal">
                            {{ formatNumberDecimalString(row.txAmount) }}
                          </td>
                          <td class="text-sm font-weight-normal">
                            {{ row.detail }}
                          </td>
                          <td class="text-sm font-weight-normal">
                            {{ row.refNumber }}
                          </td>
                          <td class="text-sm font-weight-normal">
                            {{ row.cusName }}
                          </td>
                          <td class="text-sm font-weight-normal">
                            <material-button color="primary" variant="outline" class="rounded-circle"
                              v-if="row.refDocUid !== '0'" @click="getFileRef(row)">
                              <i class="material-icons material-symbols-outlined">attach_file</i>
                            </material-button>
                            <material-button color="primary" data-bs-toggle="modal" data-bs-target="#attachmentsModal"
                              v-show="false" id="attachmenDialog">
                              <i class="material-icons material-symbols-outlined">attach_file</i>
                            </material-button>
                          </td>
                          <td class="text-sm font-weight-normal">
                            <material-button color="info" variant="outline" class="rounded-circle me-1"
                              data-bs-toggle="tooltip" data-bs-placement="top" :title="btnEdit"
                              :disabled="format_date(row.txDate) !== format_date(new Date())"
                              @click="editRowItems(row)">
                              <i class="material-icons material-symbols-outlined">edit</i>
                            </material-button>
                            <material-button color="danger" variant="outline" class="rounded-circle"
                              data-bs-toggle="tooltip" data-bs-placement="top" :title="btnDel"
                              @click="deleteRowItems(row)"
                              :disabled="format_date(row.txDate) !== format_date(new Date())">
                              <i class=" material-icons material-symbols-outlined">delete</i>
                            </material-button>
                          </td>
                        </tr>
                        <tr v-if="listfundTx.length === 0">
                          <td colspan="12" class="text-sm font-weight-normal">
                            <p style="text-align: center">No Record Found</p>
                          </td>
                        </tr>
                      </tbody>
                      <tfoot>
                        <tr>
                          <td colspan="13" style="text-align: right">Total Rows : {{ totalRows_s }}</td>
                        </tr>
                      </tfoot>
                    </table>
                  </div>
                </div>
                <pre></pre>
                <div style="display: flex; justify-content: flex-end">
                  <ul v-show="listfundTx.length > 0" class="pagination justify-content-end">
                    <div class="project-container">
                      <div class="example-one">
                        <vue-awesome-paginate v-model="currentPage" :total-items="totalRows" :items-per-page="perPage"
                          @click="onClickHandler" />
                      </div>
                    </div>
                  </ul>
                </div>
              </div>
            </div>
          </div>

          <div id="secondTransactions" class="tab-pane fade" role="tabpanel" aria-labelledby="second-transactions">
            <div class="card mt-2">
              <div class="card-header mb-0">
                <h5>{{ $t("content.gmoneybiz") }}</h5>
              </div>
              <div class="card-body pt-0">

                <div class="row">
                  <div class="col-3 mb-4">
                    <label class="form-label ms-0">{{ $t("content.issuedate") }}</label>
                    <VueDatePicker v-model="fundtx_detail.txDate" :format="format" auto-apply :teleport="true" disabled
                      :max-date="new Date()" :enable-time-picker="false" month-name-format="long" :clearable="false"
                      :text-input="textInputOptions" fluid />
                  </div>
                  <div class="col-lg-3">
                    <label class="form-label ms-0">{{ $t("content.transc") }}</label>
                    <select class="form-select" v-model="fundtx_detail.fundCategory" @change="checkSubCat_AE"
                      :disabled="fundtx_detail.uid !== ''">
                      <option value="" selected disabled>{{ $t("content.select") }}</option>
                      <option v-for="(a, opt1) in datas.FinancAll" :key="opt1" :value="a.val">
                        {{ a.items }}
                      </option>
                    </select>
                  </div>
                  <div class="col-lg-3">
                    <label class="form-label ms-0">{{ $t("content.transc_sub") }}</label>
                    <select class="form-select" v-model="fundtx_detail.fundTranTypeId"
                      :disabled="fundtx_detail.fundCategory === '' || fundtx_detail.uid !== ''" @change="checkTrans_AE">
                      <option value="" selected disabled>{{ $t("content.select") }}</option>
                      <option v-for="(a, opt1) in listsubcat_ae" :key="opt1" :value="a.uid">
                        {{ a.name }}
                      </option>
                    </select>
                  </div>

                  <div class="col-lg-3" v-if="listtransc_ae.length > 0">
                    <label class="form-label ms-0">{{ $t("content.trans") }}</label>
                    <select class="form-select" v-model="fundtx_detail.txType"
                      :disabled="fundtx_detail.fundTranTypeId === '' || fundtx_detail.uid !== ''"
                      @change="checkSource_AE">
                      <option value="" selected disabled>{{ $t("content.select") }}</option>
                      <option v-if="listtransc_ae[0].txDeposit" value="txDeposit" selected> {{ $t(datas.types[0].lb) }}
                      </option>
                      <option v-if="listtransc_ae[0].txWithdrawal" value="txWithdrawal" selected> {{
                        $t(datas.types[1].lb) }}
                      </option>
                      <option v-if="listtransc_ae[0].txTransfer" value="txTransfer" selected> {{ $t(datas.types[2].lb)
                        }}
                      </option>
                    </select>
                  </div>
                  <div v-else class="col-lg-3">
                    <label class="form-label ms-0">{{ $t("content.trans") }}</label>
                    <select class="form-select" v-model="fundtx_detail.txType"
                      :disabled="fundtx_detail.fundTranTypeId === '' || fundtx_detail.uid !== ''">
                      <option value="" selected disabled>{{ $t("content.select") }}</option>
                    </select>
                  </div>
                </div>

                <div class="row" v-if="fundtx_detail.sourceUid !== 'OTHERS' && fundtx_detail.targetUid !== 'OTHERS'">
                  <div class="col-lg-3" v-if="listsource_ae.length > 0">
                    <label class="form-label ms-0">{{ $t("content.fromsource") }}</label>
                    <select v-model="fundtx_detail.sourceUid" class="form-select" @change="changeSource"
                      :disabled="fundtx_detail.txType === '' || listsource_ae[0].srcNone || fundtx_detail.uid !== ''">
                      <option value="0" disabled selected>{{ $t("content.select") }}</option>
                      <optgroup :label="$t('apps.mcash')" :disabled="!listsource_ae[0].srcCashAccount">
                        <option v-for="(item, index) in listcashAcc" :value="item.uid" :key="index">
                          {{ item.name }}
                        </option>
                      </optgroup>
                      <optgroup :label="$t('content.amnbankacc')" :disabled="!listsource_ae[0].srcBankAccount">
                        <option v-for="(item, index) in listbankpawn" :value="item.uid" :key="index">
                          {{ item.shortName }}
                        </option>
                      </optgroup>
                      <optgroup :label="$t('content.source_others')" v-if="fundtx_detail.fundCategory === 'EXTERNAL'
                        || fundtx_detail.fundCategory === 'DIRECTOR_LOAN' || fundtx_detail.fundCategory === 'SELLING_PRODUCTS'
                        || fundtx_detail.fundCategory === 'FEE' || fundtx_detail.fundCategory === 'ACCOUNT_ADJUST'"
                        :disabled="(!listsource_ae[0].srcCashAccount && !listsource_ae[0].srcBankAccount) || fundtx_detail.targetUid === 'OTHERS'">
                        <option value="OTHERS" selected>{{ $t("content.amnothers") }}</option>
                      </optgroup>
                    </select>
                  </div>
                  <div v-else class="col-lg-3">
                    <label class="form-label ms-0">{{ $t("content.fromsource") }}</label>
                    <select v-model="fundtx_detail.sourceUid" class="form-select" disabled>
                      <option value="0" disabled selected>{{ $t("content.select") }}</option>
                    </select>
                  </div>

                  <div v-if="listsource_ae.length > 0" class="col-lg-3">
                    <label class="form-label ms-0">{{ $t("content.tosource") }}</label>
                    <select v-model="fundtx_detail.targetUid" class="form-select" @change="changeTarget"
                      :disabled="fundtx_detail.txType === '' || listsource_ae[0].dstNone || fundtx_detail.uid !== ''">
                      <option value="0" disabled selected>{{ $t("content.select") }}</option>
                      <optgroup :label="$t('apps.mcash')" :disabled="!listsource_ae[0].dstCashAccount">
                        <option v-for="(item, index) in listcashAcc" :value="item.uid" :key="index">
                          {{ item.name }}
                        </option>
                      </optgroup>
                      <optgroup :label="$t('content.amnbankacc')" :disabled="!listsource_ae[0].dstBankAccount">
                        <option v-for="(item, index) in listbankpawn" :value="item.uid" :key="index">
                          {{ item.shortName }}
                        </option>
                      </optgroup>
                      <optgroup :label="$t('content.source_others')" v-if="fundtx_detail.fundCategory === 'EXTERNAL'
                        || fundtx_detail.fundCategory === 'DIRECTOR_LOAN' || fundtx_detail.fundCategory === 'SELLING_PRODUCTS'
                        || fundtx_detail.fundCategory === 'FEE' || fundtx_detail.fundCategory === 'ACCOUNT_ADJUST'"
                        :disabled="(!listsource_ae[0].dstCashAccount && !listsource_ae[0].dstBankAccount) || fundtx_detail.sourceUid === 'OTHERS'">
                        <option value="OTHERS" selected>{{ $t("content.amnothers") }}</option>
                      </optgroup>
                    </select>
                  </div>
                  <div v-else class="col-lg-3">
                    <label class="form-label ms-0">{{ $t("content.tosource") }}</label>
                    <select v-model="fundtx_detail.targetUid" class="form-select" disabled>
                      <option value="0" disabled selected>{{ $t("content.select") }}</option>
                    </select>
                  </div>

                  <div class="flex-auto col-lg-3">
                    <label class="form-label ms-0">
                      {{ $t("content.amount") }}
                    </label>
                    <div class="align-items-center">
                      <InputNumber v-model="fundtx_detail.txAmount" :maxFractionDigits="2" autocomplete="off"
                        @focus="$event.target.select()" fluid />
                    </div>
                  </div>

                  <div class="col-lg-3">
                    <div class="d-flex align-items-center mb-1">
                      <label class="form-label ms-0">
                        {{ $t("content.file") }}
                      </label>
                    </div>
                    <input id="upload" type="file" accept="application/pdf,image/*" @change="addFile" />
                  </div>
                </div>

                <div class="row" v-if="fundtx_detail.sourceUid === 'OTHERS'">
                  <div class="col-lg-3" v-if="listsource_ae.length > 0">
                    <label class="form-label ms-0">{{ $t("content.fromsource") }}</label>
                    <select v-model="fundtx_detail.sourceUid" class="form-select" @change="changeSource"
                      :disabled="fundtx_detail.txType === '' || listsource_ae[0].srcNone">
                      <option value="0" disabled selected>{{ $t("content.select") }}</option>
                      <optgroup :label="$t('apps.mcash')" :disabled="!listsource_ae[0].srcCashAccount">
                        <option v-for="(item, index) in listcashAcc" :value="item.uid" :key="index">
                          {{ item.name }}
                        </option>
                      </optgroup>
                      <optgroup :label="$t('content.amnbankacc')" :disabled="!listsource_ae[0].srcBankAccount">
                        <option v-for="(item, index) in listbankpawn" :value="item.uid" :key="index">
                          {{ item.shortName }}
                        </option>
                      </optgroup>
                      <optgroup :label="$t('content.source_others')" v-if="fundtx_detail.fundCategory === 'EXTERNAL'
                        || fundtx_detail.fundCategory === 'DIRECTOR_LOAN' || fundtx_detail.fundCategory === 'SELLING_PRODUCTS'
                        || fundtx_detail.fundCategory === 'FEE' || fundtx_detail.fundCategory === 'ACCOUNT_ADJUST'"
                        :disabled="(!listsource_ae[0].srcCashAccount && !listsource_ae[0].srcBankAccount) || fundtx_detail.targetUid === 'OTHERS'">
                        <option value="OTHERS" selected>{{ $t("content.amnothers") }}</option>
                      </optgroup>
                    </select>
                  </div>
                  <div v-else class="col-lg-3">
                    <label class="form-label ms-0">{{ $t("content.fromsource") }}</label>
                    <select v-model="fundtx_detail.sourceUid" class="form-select" disabled>
                      <option value="0" disabled selected>{{ $t("content.select") }}</option>
                    </select>
                  </div>

                  <div v-if="fundtx_detail.sourceUid === 'OTHERS'" class="col-lg-6">
                    <label class="form-label ms-0">{{ $t("content.detail") }}{{ $t("content.fromsource") }}</label>
                    <material-input v-model="fundtx_detail.sourceAccNumber" type="text" autocomplete="off" />
                  </div>

                  <div v-if="listsource_ae.length > 0" class="col-lg-3">
                    <label class="form-label ms-0">{{ $t("content.tosource") }}</label>
                    <select v-model="fundtx_detail.targetUid" class="form-select" @change="changeTarget"
                      :disabled="fundtx_detail.txType === '' || listsource_ae[0].dstNone">
                      <option value="0" disabled selected>{{ $t("content.select") }}</option>
                      <optgroup :label="$t('apps.mcash')" :disabled="!listsource_ae[0].dstCashAccount">
                        <option v-for="(item, index) in listcashAcc" :value="item.uid" :key="index">
                          {{ item.name }}
                        </option>
                      </optgroup>
                      <optgroup :label="$t('content.amnbankacc')" :disabled="!listsource_ae[0].dstBankAccount">
                        <option v-for="(item, index) in listbankpawn" :value="item.uid" :key="index">
                          {{ item.shortName }}
                        </option>
                      </optgroup>
                      <optgroup :label="$t('content.source_others')" v-if="fundtx_detail.fundCategory === 'EXTERNAL'
                        || fundtx_detail.fundCategory === 'DIRECTOR_LOAN' || fundtx_detail.fundCategory === 'SELLING_PRODUCTS'
                        || fundtx_detail.fundCategory === 'FEE' || fundtx_detail.fundCategory === 'ACCOUNT_ADJUST'"
                        :disabled="(!listsource_ae[0].dstCashAccount && !listsource_ae[0].dstBankAccount) || fundtx_detail.sourceUid === 'OTHERS'">
                        <option value="OTHERS" selected>{{ $t("content.amnothers") }}</option>
                      </optgroup>
                    </select>
                  </div>
                  <div v-else class="col-lg-3">
                    <label class="form-label ms-0">{{ $t("content.tosource") }}</label>
                    <select v-model="fundtx_detail.targetUid" class="form-select" disabled>
                      <option value="0" disabled selected>{{ $t("content.select") }}</option>
                    </select>
                  </div>

                  <div class="flex-auto col-lg-6">
                    <label class="form-label ms-0">
                      {{ $t("content.amount") }}
                    </label>
                    <div class="align-items-center">
                      <InputNumber v-model="fundtx_detail.txAmount" :maxFractionDigits="2" autocomplete="off"
                        @focus="$event.target.select()" fluid />
                    </div>
                  </div>

                  <div class="col-lg-6">
                    <div class="d-flex align-items-center mb-1">
                      <label class="form-label ms-0">
                        {{ $t("content.file") }}
                      </label>
                    </div>
                    <input id="upload" type="file" accept="application/pdf,image/*" @change="addFile" />
                  </div>
                </div>

                <div class="row" v-if="fundtx_detail.targetUid === 'OTHERS'">
                  <div class="col-lg-3" v-if="listsource_ae.length > 0">
                    <label class="form-label ms-0">{{ $t("content.fromsource") }}</label>
                    <select v-model="fundtx_detail.sourceUid" class="form-select" @change="changeSource"
                      :disabled="fundtx_detail.txType === '' || listsource_ae[0].srcNone">
                      <option value="0" disabled selected>{{ $t("content.select") }}</option>
                      <optgroup :label="$t('apps.mcash')" :disabled="!listsource_ae[0].srcCashAccount">
                        <option v-for="(item, index) in listcashAcc" :value="item.uid" :key="index">
                          {{ item.name }}
                        </option>
                      </optgroup>
                      <optgroup :label="$t('content.amnbankacc')" :disabled="!listsource_ae[0].srcBankAccount">
                        <option v-for="(item, index) in listbankpawn" :value="item.uid" :key="index">
                          {{ item.shortName }}
                        </option>
                      </optgroup>
                      <optgroup :label="$t('content.source_others')" v-if="fundtx_detail.fundCategory === 'EXTERNAL'
                        || fundtx_detail.fundCategory === 'DIRECTOR_LOAN' || fundtx_detail.fundCategory === 'SELLING_PRODUCTS'
                        || fundtx_detail.fundCategory === 'FEE' || fundtx_detail.fundCategory === 'ACCOUNT_ADJUST'"
                        :disabled="(!listsource_ae[0].srcCashAccount && !listsource_ae[0].srcBankAccount) || fundtx_detail.targetUid === 'OTHERS'">
                        <option value="OTHERS" selected>{{ $t("content.amnothers") }}</option>
                      </optgroup>
                    </select>
                  </div>
                  <div v-else class="col-lg-3">
                    <label class="form-label ms-0">{{ $t("content.fromsource") }}</label>
                    <select v-model="fundtx_detail.sourceUid" class="form-select" disabled>
                      <option value="0" disabled selected>{{ $t("content.select") }}</option>
                    </select>
                  </div>

                  <div v-if="listsource_ae.length > 0" class="col-lg-3">
                    <label class="form-label ms-0">{{ $t("content.tosource") }}</label>
                    <select v-model="fundtx_detail.targetUid" class="form-select" @change="changeTarget"
                      :disabled="fundtx_detail.txType === '' || listsource_ae[0].dstNone">
                      <option value="0" disabled selected>{{ $t("content.select") }}</option>
                      <optgroup :label="$t('apps.mcash')" :disabled="!listsource_ae[0].dstCashAccount">
                        <option v-for="(item, index) in listcashAcc" :value="item.uid" :key="index">
                          {{ item.name }}
                        </option>
                      </optgroup>
                      <optgroup :label="$t('content.amnbankacc')" :disabled="!listsource_ae[0].dstBankAccount">
                        <option v-for="(item, index) in listbankpawn" :value="item.uid" :key="index">
                          {{ item.shortName }}
                        </option>
                      </optgroup>
                      <optgroup :label="$t('content.source_others')" v-if="fundtx_detail.fundCategory === 'EXTERNAL'
                        || fundtx_detail.fundCategory === 'DIRECTOR_LOAN' || fundtx_detail.fundCategory === 'SELLING_PRODUCTS'
                        || fundtx_detail.fundCategory === 'FEE' || fundtx_detail.fundCategory === 'ACCOUNT_ADJUST'"
                        :disabled="(!listsource_ae[0].dstCashAccount && !listsource_ae[0].dstBankAccount) || fundtx_detail.sourceUid === 'OTHERS'">
                        <option value="OTHERS" selected>{{ $t("content.amnothers") }}</option>
                      </optgroup>
                    </select>
                  </div>
                  <div v-else class="col-lg-3">
                    <label class="form-label ms-0">{{ $t("content.tosource") }}</label>
                    <select v-model="fundtx_detail.targetUid" class="form-select" disabled>
                      <option value="0" disabled selected>{{ $t("content.select") }}</option>
                    </select>
                  </div>

                  <div v-if="fundtx_detail.targetUid === 'OTHERS'" class="col-lg-6">
                    <label class="form-label ms-0">{{ $t("content.detail") }}{{ $t("content.tosource") }}</label>
                    <material-input v-model="fundtx_detail.targetAccNumber" type="text" autocomplete="off" />
                  </div>

                  <div class="flex-auto col-lg-6">
                    <label class="form-label ms-0">
                      {{ $t("content.amount") }}
                    </label>
                    <div class="align-items-center">
                      <InputNumber v-model="fundtx_detail.txAmount" :maxFractionDigits="2" autocomplete="off"
                        @focus="$event.target.select()" fluid />
                    </div>
                  </div>

                  <div class="col-lg-6">
                    <div class="d-flex align-items-center mb-1">
                      <label class="form-label ms-0">
                        {{ $t("content.file") }}
                      </label>
                    </div>
                    <input id="upload" type="file" accept="application/pdf,image/*" @change="addFile" />
                  </div>

                </div>



                <div class="row">
                  <div class="col-lg-12">
                    <label class="form-label ms-0">{{ $t("content.detail") }}</label>
                    <material-input v-model="fundtx_detail.detail" type="text" autocomplete="off" />
                  </div>

                  <div class="col-lg-6">
                    <label class="form-label ms-0">{{ $t("content.ticketref") }}</label>
                    <material-input v-model="fundtx_detail.refNumber" type="text" autocomplete="off"
                      :disabled="fundtx_detail.fundCategory !== 'EXTERNAL' && fundtx_detail.fundCategory !== 'ACCOUNT_ADJUST'" />
                  </div>

                  <div class="col-lg-6">
                    <label class="form-label ms-0">{{ $t("content.cname") }}</label>
                    <material-input v-model="fundtx_detail.cusName" type="text" autocomplete="off"
                      :disabled="fundtx_detail.fundCategory !== 'EXTERNAL' && fundtx_detail.fundCategory !== 'ACCOUNT_ADJUST'" />
                  </div>
                </div>

                <div class="row no-gutters mt-3">
                  <div class="col-12 d-flex justify-content-end">
                    <div>
                      <material-button color="default" class="float-lg-start float-sm-end mb-3 me-3" @click="clearItem">
                        <i class="material-icons material-symbols-outlined">close</i>
                        {{ btnCancel }}
                      </material-button>

                      <material-button color="primary" class="float-lg-start float-sm-end mb-3"
                        :disabled="fundtx_detail.fundCategory === '' || fundtx_detail.fundTranTypeId === ''
                          || fundtx_detail.txType === '' || fundtx_detail.sourceUid === '0' || (listsource_ae.length > 0 && !listsource_ae[0].dstNone && fundtx_detail.targetUid === '0')
                          || fundtx_detail.txAmount === 0 || fundtx_detail.txAmount === null || fundtx_detail.detail.trim() === ''
                          || fundtx_detail.sourceUid === fundtx_detail.targetUid || (fundtx_detail.sourceUid === 'OTHERS' && fundtx_detail.sourceAccNumber.trim() === '')
                          || (fundtx_detail.targetUid === 'OTHERS' && fundtx_detail.targetAccNumber.trim() === '')
                          || (fundtx_detail.fundCategory === 'ACCOUNT_ADJUST' && (fundtx_detail.refNumber === null || fundtx_detail.cusName === null))
                          || (fundtx_detail.fundCategory === 'ACCOUNT_ADJUST' && (fundtx_detail.refNumber.trim() === '' || fundtx_detail.cusName.trim() === ''))" @click="confirmNewRecord">
                        <i class="material-icons material-symbols-outlined">save</i>
                        {{ btnSave }}
                      </material-button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- Attachments Dialog -->
        <div id="attachmentsModal" class="modal fade" tabindex="-1" aria-labelledby="lookupModalLabel"
          aria-hidden="true">
          <div class="modal-dialog modal-dialog-centered" style="max-width: 520px">
            <div class="modal-content">
              <div class="modal-header">
                <h4 class="modal-title">{{ $t("content.detail") }}</h4>
                <button type="button" class="close" data-bs-dismiss="modal" aria-label="Close">
                  <i class="material-icons material-symbols-outlined">close</i>
                </button>
              </div>

              <div class="modal-body">
                <div id="attachmentsImages" class="carousel slide" data-bs-ride="carousel">
                  <div class="carousel-indicators" id="demo2">
                  </div>
                  <div class="carousel-inner" id="demo3">
                  </div>
                  <button class="carousel-control-prev" type="button" data-bs-target="#attachmentsImages"
                    data-bs-slide="prev">
                    <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                    <span class="visually-hidden">Previous</span>
                  </button>
                  <button class="carousel-control-next" type="button" data-bs-target="#attachmentsImages"
                    data-bs-slide="next">
                    <span class="carousel-control-next-icon" aria-hidden="true"></span>
                    <span class="visually-hidden">Next</span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>
  </div>
</template>

<script setup>
/* eslint-disable no-unused-vars */
import ProgressSpinner from 'primevue/progressspinner';
import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css'
import InputNumber from 'primevue/inputnumber';
import { reactive, inject, computed, ref, onMounted } from "vue";
import { useI18n } from "vue-i18n";

import MaterialButton from "@/components/MaterialButton.vue";
import MaterialInput from "@/components/MaterialInput.vue";
import ExampleFive from "@/examples/ExampleOne";


import { useCashAccountStore } from '@/stores/cashaccount'
import { useShopBankStore } from '@/stores/shopbank'
import { useFinancialTranTypeStore } from '@/stores/financialtrantype'
import { useFundTxStore } from '@/stores/fundtx'
import { useDocumentStore } from '@/stores/document'

import axios from "axios";
import * as st from "@/config/setting.js";
import moment from 'moment'


const storec = useCashAccountStore()
const storesb = useShopBankStore()
const storef = useFinancialTranTypeStore()
const storefund = useFundTxStore()
const storedoc = useDocumentStore()

const format = (date) => {
  return moment(String(date)).local().format('DD/MM/YYYY');
}

const textInputOptions = {
  format: 'dd/MM/yyyy'
};

const { t } = useI18n();
const swal = inject("$swal");


const datas = reactive({
  addNew: false,
  rowEdit: false,
  coreCopy: true,
  coreBox1: "",
  file: [],
  demo1: [
    { items: "ปิดยอดเงินสด" },
    { items: "ปิดยอดบัญชีธนาคาร" },
    { items: "ธุรกรรมเงินสด" },
    { items: "ธุรกรรมบัญชีธนาคาร" },
  ],
  demo2: [{ items: "ฝาก" }, { items: "ถอน" }],
  demo3: [{ items: "เงินสด" }, { items: "เงินโอน" }],
  demo4: [{ items: "Bank1" }, { items: "Bank2" }],
  demo2s: [
    { items: computed(() => t("content.select")) },
    { items: computed(() => t("content.ctranstde")) },
    { items: computed(() => t("content.ctranstwi")) },
  ],
  FinancAll: [
    { items: computed(() => t("content.ctranstin")), val: 'INTERNAL' },
    { items: computed(() => t("content.ctranstout")), val: 'EXTERNAL' },
    { items: computed(() => t("content.direct_lo")), val: 'DIRECTOR_LOAN' },
    { items: computed(() => t("content.sell_pr")), val: 'SELLING_PRODUCTS' },
    { items: computed(() => t("content.fee")), val: 'FEE' },
    { items: computed(() => t("content.ctranstexp")), val: 'EXPENSE' },
    { items: computed(() => t("content.ctranstacc")), val: 'ACCOUNT_CLOSURE' },
    { items: computed(() => t("content.acc_adj")), val: 'ACCOUNT_ADJUST' },
  ],
  Financ1: [
    { items: computed(() => t("content.select")) },
    { items: computed(() => t("content.ctranstca")) },
    { items: computed(() => t("content.ctranstbc")) },
    { items: computed(() => t("content.ctranstii")) },
  ],
  Financ2: [
    { items: computed(() => t("content.select")) },
    { items: computed(() => t("content.ctranstoi")) },
  ],
  Financ3: [
    { items: computed(() => t("content.select")) },
    { items: computed(() => t("content.ctranstge")) },
  ],
  Financ4: [
    { items: computed(() => t("content.select")) },
    { items: computed(() => t("content.ctranstcs")) },
    { items: computed(() => t("content.ctranstco")) },
    { items: computed(() => t("content.ctranstuc")) },
  ],
  options: [
    {
      items: computed(() => t("content.transac") + " -> " + t("content.actpawn1")),
    },
    {
      items: computed(() => t("content.transac") + " -> " + t("content.actpawn2")),
    },
    {
      items: computed(() => t("content.transac") + " -> " + t("content.actpawn3")),
    },
    {
      items: computed(() => t("content.transac") + " -> " + t("content.actpawn4")),
    },
    {
      items: computed(() => t("content.transac") + " -> " + t("content.actpawn5")),
    },
    {
      items: computed(() => t("content.transac") + " -> " + t("content.actpawn6")),
    },
    {
      items: computed(() => t("content.transac") + " -> " + t("content.deposite")),
    },
    {
      items: computed(() => t("content.transac") + " -> " + t("content.withdraw")),
    },
    {
      items: computed(() => t("content.inshop") + " -> " + t("content.transcash")),
    },
    {
      items: computed(() => t("content.inshop") + " -> " + t("content.transbank")),
    },
    {
      items: computed(() => t("content.inshop") + " -> " + t("content.transin")),
    },
    {
      items: computed(() => t("content.outshop") + " -> " + t("content.transout")),
    },
    {
      items: computed(() => t("content.expense") + " -> " + t("content.genexp")),
    },
    {
      items: computed(() => t("content.accclose") + " -> " + t("content.cashshort")),
    },
    {
      items: computed(() => t("content.accclose") + " -> " + t("content.cashover")),
    },
    {
      items: computed(() => t("content.accclose") + " -> " + t("content.unreccash")),
    },
  ],
  types: [
    {
      id: "selectedDepo",
      vl: 0,
      lb: "content.ctranstde",
    },
    {
      id: "selectedWithd",
      vl: 1,
      lb: "content.ctranstwi",
    },
    {
      id: "selectedTrans",
      vl: 2,
      lb: "content.ctransttr",
    },
  ],
});


const btnSave = computed(() => t("buttons.save"));
const btnCancel = computed(() => t("buttons.canc"));
const shop_detail = ref(null)
const listcashAcc = ref([]);
const listbankpawn = ref([]);
const start_date = ref(new Date(new Date().setHours(0, 0, 0, 0)));
const end_date = ref(new Date());
const listfinancialtrans = ref([]);
const listsubcat = ref([]);
const listfundTx = ref([]);
const listsubcat_ae = ref([]);
const listtransc_ae = ref([]);
const listsource_ae = ref([]);

const search_cat = ref('');
const search_subcat = ref('');
const search_trans = ref('');
const search_sourcemoney = ref('');
const search_target = ref('');

const currentPage = ref(1);
const totalRows = ref(1);
const perPage = ref(10);
const totalRows_s = ref();

const fundtx_detail = ref({
  uid: '',
  txDate: new Date(),
  fundCategory: '',
  fundTranTypeId: '',
  txType: '',
  sourceType: '',
  sourceUid: '0',
  targetType: '',
  targetUid: '0',
  txAmount: '',
  detail: '',
  refDocUid: '0',
  version: '',
  targetAccNumber: '',
  sourceAccNumber: '',
  cusName: '',
  refNumber: '',

});

const thumb_name = ref("");
const thumb = ref(null);
const thumb_id = ref('');
const loading = ref(false);
const images = ref([])


const onClickHandler = () => {
  updatePagination()
};

async function addFile(e) {
  thumb.value = null
  thumb_name.value = ""
  let date = new Date()
  if (e.target.files[0] !== undefined) {
    thumb.value = await toBase64(e.target.files[0])
    thumb_name.value = 'file-' + date.getFullYear() + date.getMonth() + 1
      + date.getDate() + date.getHours() + date.getMinutes() + date.getSeconds()
    if (e.target.files[0].type === "image/jpeg") {
      thumb_name.value = thumb_name.value + ".jpg"
    } else if (e.target.files[0].type === "image/png") {
      thumb_name.value = thumb_name.value + ".png"
    } else if (e.target.files[0].type === 'application/pdf') {
      thumb_name.value = thumb_name.value + ".pdf"
    }
  }

}


const toBase64 = file => new Promise((resolve, reject) => {
  const reader = new FileReader();
  reader.readAsDataURL(file);
  reader.onload = () => resolve(reader.result.replace('data:', '')
    .replace(/^.+,/, ''));
  reader.onerror = reject;
});


function format_datetime(value) {
  if (value) {
    return moment(String(value)).local().format('DD/MM/YYYY HH:mm')
  }
}

function format_date(value) {
  if (value) {
    return moment(String(value)).local().format('DD/MM/YYYY')
  } else {
    return '-'
  }
}

const getshopInfoApi = async (val) => {
  shop_detail.value = null
  const response = await storec.cashaccountInfoFromSessionFetch();
  if (response) {
    if (response.status === 200) {
      if (response.data.rtnDesc === "success") {
        // console.log("cashAccShopApi : " + JSON.stringify(response.data.data));
        shop_detail.value = response.data.data
        await loadCashAccDataApi()
        await loadBankPawnApi()
        await loadFinancialtransApi()
        await loadDataApi()
        // await getfundCategoryApi()
        // getTxTypeApi()

      }
    }
  }
}

getshopInfoApi()

const loadBankPawnApi = async (val) => {
  listbankpawn.value = [];
  let data = "";
  data = {
    index: 0,
    size: 100,
    bankUid: '',
    shortName: '',
    acctNumber: '',
    shopUid: shop_detail.value.uid
  };
  const payload = data;
  // console.log("payload : " + JSON.stringify(payload));
  const response = await storesb.shopbankListFetch(payload);
  // console.log("loadBankPawnApi : " + JSON.stringify(response.data));
  if (response) {
    if (response.status === 200) {
      if (response.data.rtnDesc === "success") {
        // listbankpawn.value = response.data.data.list;
        let listd = response.data.data.list;
        for (let i = 0; i < listd.length; i++) {
          if (listd[i].channels.includes('BO_WEB')) {
            listbankpawn.value.push(listd[i])
          }
        }

      }
    }
  }
}


const loadCashAccDataApi = async (val) => {
  listcashAcc.value = [];
  let data = "";
  data = {
    index: 0,
    size: 100,
    name: "",
    shopUid: shop_detail.value.uid
  };
  const payload = data;
  // console.log("payload : " + JSON.stringify(payload));
  const response = await storec.cashaccountListFetch(payload);

  // console.log("loadCashAccDataApi : " + JSON.stringify(response.data));
  if (response) {
    if (response.status === 200) {
      if (response.data.rtnDesc === "success") {
        // listcashAcc.value = response.data.data.list;
        let listd = response.data.data.list;
        for (let i = 0; i < listd.length; i++) {
          if (listd[i].channels.includes('BO_WEB')) {
            listcashAcc.value.push(listd[i])

          }
        }

      }
    }
  }
}

const loadFinancialtransApi = async (val) => {
  listfinancialtrans.value = [];
  let data = "";
  data = {
    index: 0,
    size: 100,
    name: "",
    shopUid: shop_detail.value.uid
  };
  const payload = data;
  // console.log("payload : " + JSON.stringify(payload));
  const response = await storef.financialTranTypeListFetch(payload);
  // console.log("loadFinancialtransApi : " + JSON.stringify(response.data));
  if (response) {
    if (response.status === 200) {
      if (response.data.rtnDesc === "success") {
        listfinancialtrans.value = response.data.data.list;
      }
    }
  }
}

const getTxTypeApi = async () => {

  const response = await storefund.getTxTypeFetch();
  if (response) {
    if (response.status === 200) {
      if (response.data.rtnDesc === "success") {
        // console.log("TxType : " + JSON.stringify(response.data.data));
      }
    }
  }
}


const getfundCategoryApi = async () => {

  const response = await storefund.getfundCategoryInfoFetch();
  if (response) {
    if (response.status === 200) {
      if (response.data.rtnDesc === "success") {
        // console.log("fund : " + JSON.stringify(response.data.data));
      }
    }
  }
}

function cancelBtn() {
  start_date.value = new Date(new Date().setHours(0, 0, 0, 0));
  end_date.value = new Date();
  search_cat.value = '';
  search_subcat.value = '';
  search_trans.value = '';
  search_sourcemoney.value = ''
  currentPage.value = 1
  listfundTx.value = []
  totalRows_s.value = ''
  search_target.value = ''
  loadDataApi()
}

const loadDataApi = async (val) => {
  let data = "";
  let sd = ''
  let ed = ''
  sd = moment(new Date(start_date.value)).format('YYYY-MM-DD')
  ed = moment(new Date(end_date.value)).format('YYYY-MM-DD')

  if (currentPage.value !== 1) {
    if (listfundTx.value.length === 1 && val === 'del') {
      currentPage.value = currentPage.value - 1
    }
  }

  let sourceType = ''
  let sourceUid = ''
  let targetType = ''
  let targetUid = ''
  if (search_sourcemoney.value !== '') {
    if (search_sourcemoney.value !== 'OTHERS') {
      sourceType = checkMoneySource(search_sourcemoney.value)
      sourceUid = search_sourcemoney.value
    } else {
      sourceType = 'OTHERS'
    }
  }

  if (search_target.value !== '') {
    if (search_target.value !== 'OTHERS') {
      targetType = checkMoneySource(search_target.value)
      targetUid = search_target.value
    } else {
      targetType = 'OTHERS'
    }
  }

  if (search_sourcemoney.value !== '' || search_cat.value !== '' || search_subcat.value !== '' || search_target.value !== '' && val !== 'del') {
    currentPage.value = 1
  }

  let txType = ''
  if (search_trans.value === 'txTransfer') {
    txType = "TRANSFER"
  } else if (search_trans.value === 'txWithdrawal') {
    txType = "WITHDRAW"

  } else if (search_trans.value === 'txDeposit') {
    txType = "DEPOSIT"
  }

  data = {
    index: currentPage.value - 1,
    size: perPage.value,
    start: sd,
    end: ed,
    fundCategory: search_cat.value,
    fundTranTypeId: search_subcat.value,
    txType: txType,
    sourceType: sourceType,
    sourceUid: sourceUid,
    targetType: targetType,
    targetUid: targetUid,

  };


  const payload = data;
  // console.log("payload : " + JSON.stringify(payload));
  const response = await storefund.fundTxListFetch(payload);
  // console.log("loadDataApi : " + JSON.stringify(response.data));
  if (response) {
    if (response.status === 200) {
      if (response.data.rtnDesc === "success") {
        listfundTx.value = response.data.data.list;
        totalRows.value = response.data.data.ext.total
        totalRows_s.value = formatNumberString(totalRows.value)
      }
    }
  }
}


async function updatePagination() {
  listfundTx.value = []
  let data = "";
  let sd = ''
  let ed = ''
  sd = moment(new Date(start_date.value)).format('YYYY-MM-DD')
  ed = moment(new Date(end_date.value)).format('YYYY-MM-DD')

  let sourceType = ''
  let sourceUid = ''
  let targetType = ''
  let targetUid = ''
  if (search_sourcemoney.value !== '') {
    if (search_sourcemoney.value !== 'OTHERS') {
      sourceType = checkMoneySource(search_sourcemoney.value)
      sourceUid = search_sourcemoney.value
    } else {
      sourceType = 'OTHERS'
    }
  }

  if (search_target.value !== '') {
    if (search_target.value !== 'OTHERS') {
      targetType = checkMoneySource(search_target.value)
      targetUid = search_target.value
    } else {
      targetType = 'OTHERS'
    }
  }

  let txType = ''
  if (search_trans.value === 'txTransfer') {
    txType = "TRANSFER"
  } else if (search_trans.value === 'txWithdrawal') {
    txType = "WITHDRAW"

  } else if (search_trans.value === 'txDeposit') {
    txType = "DEPOSIT"
  }


  data = {
    index: currentPage.value - 1,
    size: perPage.value,
    start: sd,
    end: ed,
    fundCategory: search_cat.value,
    fundTranTypeId: search_subcat.value,
    txType: txType,
    sourceType: sourceType,
    sourceUid: sourceUid,
    targetType: targetType,
    targetUid: targetUid,

  };


  const payload = data;
  // console.log("payload : " + JSON.stringify(payload));
  const response = await storefund.fundTxListFetch(payload);
  // console.log("loadDataApi : " + JSON.stringify(response.data));
  if (response) {
    if (response.status === 200) {
      if (response.data.rtnDesc === "success") {
        listfundTx.value = response.data.data.list;
        totalRows.value = response.data.data.ext.total
        totalRows_s.value = formatNumberString(totalRows.value)
      }
    }
  }
}


function checkMoneySource(str) {
  let side = ''
  if (str !== '0') {
    let ld = filterBank(str)
    if (ld.length > 0) {
      side = "BANK_ACCOUNT"
    } else {
      ld = filterCash(str)
      if (ld.length > 0) {
        side = "CASH_ACCOUNT"
      }
    }
  }
  return side
}


function filterCash(str) {
  return listcashAcc.value.filter((c) => c.uid === str);
}

function filterBank(str) {
  return listbankpawn.value.filter((c) => c.uid === str);
}


function filterfundCategory(str) {
  let side = ''
  side = datas.FinancAll.filter((c) => c.val === str);
  return side[0].items
}

function filterfundTranType(str) {
  let side = ''
  side = listfinancialtrans.value.filter((c) => c.uid === str);
  return side[0].name
}


function filterMoneySource(str) {
  let side = ''
  if (str !== '0') {
    let ld = filterBank(str)
    if (ld.length > 0) {
      side = ld[0].shortName
    } else {
      ld = filterCash(str)
      if (ld.length > 0) {
        side = ld[0].name
      }
    }
  }
  return side
}


function formatNumberString(num) {
  return String(num).replace(
    /(\d)(?=(\d{3})+(?!\d))/g,
    "$1,"
  );
}

function formatNumberDecimalString(num) {
  if (num !== null && num !== undefined && num !== '') {
    num = num.toFixed(2)
  } else {
    num = 0
    num = num.toFixed(2)
  }
  return String(num).replace(
    /(\d)(?=(\d{3})+(?!\d))/g,
    "$1,"
  );
}



function checkSubCat() {
  listsubcat.value = []
  search_subcat.value = ''
  // search_trans.value = ''
  // listtransc.value = []

  if (search_cat.value !== '') {
    let ld = listfinancialtrans.value.filter((c) => c.finCat === search_cat.value);
    if (ld.length > 0) {
      listsubcat.value = ld
      // console.log("listsubcat : " + JSON.stringify(listsubcat.value));
    }
  }

  return listsubcat.value
}


function checkSubCat_AE() {
  listsubcat_ae.value = []
  fundtx_detail.value.fundTranTypeId = ''
  fundtx_detail.value.txType = ''
  listtransc_ae.value = []
  fundtx_detail.value.sourceUid = '0'
  fundtx_detail.value.targetUid = '0'
  fundtx_detail.value.sourceType = ''
  fundtx_detail.value.targetType = ''
  fundtx_detail.value.targetAccNumber = ''
  fundtx_detail.value.sourceAccNumber = ''
  fundtx_detail.value.cusName = ''
  fundtx_detail.value.refNumber = ''
  listsource_ae.value = []
  if (fundtx_detail.value.fundCategory !== '') {
    let ld = listfinancialtrans.value.filter((c) => c.finCat === fundtx_detail.value.fundCategory);
    if (ld.length > 0) {
      listsubcat_ae.value = ld
      // console.log("listsubcat : " + JSON.stringify(listsubcat.value));
    }
  }

  return listsubcat_ae.value
}

function checkTrans_AE() {
  fundtx_detail.value.txType = ''
  listtransc_ae.value = []
  fundtx_detail.value.sourceUid = '0'
  fundtx_detail.value.targetUid = '0'
  fundtx_detail.value.sourceType = ''
  fundtx_detail.value.targetType = ''
  fundtx_detail.value.targetAccNumber = ''
  fundtx_detail.value.sourceAccNumber = ''
  fundtx_detail.value.cusName = ''
  fundtx_detail.value.refNumber = ''
  listsource_ae.value = []
  if (fundtx_detail.value.fundTranTypeId !== '') {
    let ld = listsubcat_ae.value.filter((c) => c.uid === fundtx_detail.value.fundTranTypeId);
    if (ld.length > 0) {
      listtransc_ae.value = ld
      // console.log("listtransc_ae : " + JSON.stringify(listtransc_ae.value));
    }
  }
  return listtransc_ae.value
}


function checkSource_AE() {
  // console.log("txType : " + fundtx_detail.value.txType);
  fundtx_detail.value.sourceUid = '0'
  fundtx_detail.value.targetUid = '0'
  fundtx_detail.value.sourceType = ''
  fundtx_detail.value.targetType = ''
  fundtx_detail.value.targetAccNumber = ''
  fundtx_detail.value.sourceAccNumber = ''
  listsource_ae.value = []
  if (fundtx_detail.value.txType !== '') {
    listsource_ae.value = listtransc_ae.value
  }
  return listsource_ae.value
}

function changeSource() {

  fundtx_detail.value.sourceAccNumber = ''
  if (fundtx_detail.value.sourceUid === 'OTHERS' && fundtx_detail.value.targetUid === 'OTHERS') {
    fundtx_detail.value.targetUid = '0'
    // console.log("Source 1: " + fundtx_detail.value.sourceUid);
    // console.log("Target 1: " + fundtx_detail.value.targetUid);
  }
}

function changeTarget() {

  fundtx_detail.value.targetAccNumber = ''
  if (fundtx_detail.value.targetUid === 'OTHERS' && fundtx_detail.value.sourceUid === 'OTHERS') {
    fundtx_detail.value.sourceUid = '0'
    // console.log("Source 2: " + fundtx_detail.value.sourceUid);
    // console.log("Target 2: " + fundtx_detail.value.targetUid);
  }
}


async function confirmNewRecord() {
  swal({
    title: "บันทึกธุรกรรม",
    text: "คุณต้องการบันทึกธุรกรรมนี้ใช่ไหม",
    icon: "info",
    allowOutsideClick: false,
    showCloseButton: false,
    showCancelButton: true,
    closeOnConfirm: false,
    focusConfirm: false,
    animation: "slide-from-top",
    confirmButtonText: t("buttons.conf"),
    cancelButtonText: t("buttons.canc"),
  }).then((result) => {
    if (result.isConfirmed) {
      if (fundtx_detail.value.uid === '') {
        addfundTx()
      } else {
        updatefundTx()
      }

    } else if (result.dismiss == "cancel") {
      swal("ยกเลิกรายการ", "", "error")
    }
  });
}

async function deleteRowItems(item) {
  swal({
    title: "ลบรายการ",
    text: "คุณต้องการลบรายการนี้ใช่ไหม",
    icon: "warning",
    allowOutsideClick: false,
    showCloseButton: false,
    showCancelButton: true,
    closeOnConfirm: false,
    focusConfirm: false,
    animation: "slide-from-top",
    confirmButtonText: t("buttons.conf"),
    cancelButtonText: t("buttons.canc"),
  }).then((result) => {
    if (result.isConfirmed) {
      if (item.uid !== "") {
        deletefundTx(item.uid)
      }
    } else if (result.dismiss == "cancel") {
      swal("ยกเลิกรายการ", "", "error")
    }
  });
}

async function editRowItems(item) {
  swal({
    title: "แก้ไขรายการ",
    text: "คุณต้องการแก้ไขรายการนี้ใช่ไหม",
    icon: "info",
    allowOutsideClick: false,
    showCloseButton: false,
    showCancelButton: true,
    closeOnConfirm: false,
    focusConfirm: false,
    animation: "slide-from-top",
    confirmButtonText: t('buttons.conf'),
    cancelButtonText: t('buttons.canc'),
  }).then((result) => {
    if (result.isConfirmed) {
      clearItem()
      if (item.uid !== '') {
        fundtx_detail.value = {
          uid: item.uid,
          txDate: item.txDate,
          fundCategory: item.fundCategory,
          fundTranTypeId: item.fundTranTypeId,
          txType: item.txType,
          sourceType: item.sourceType,
          sourceUid: item.sourceUid,
          targetType: item.targetType,
          targetUid: item.targetUid,
          txAmount: item.txAmount,
          detail: item.detail,
          refDocUid: item.refDocUid,
          version: item.version,
          targetAccNumber: item.targetAccNumber,
          sourceAccNumber: item.sourceAccNumber,
          cusName: item.cusName,
          refNumber: item.refNumber
        };


        if (fundtx_detail.value.fundCategory !== '' && fundtx_detail.value.fundCategory !== null) {
          let ld = listfinancialtrans.value.filter((c) => c.finCat === fundtx_detail.value.fundCategory);
          if (ld.length > 0) {
            listsubcat_ae.value = ld
          }
        }

        if (fundtx_detail.value.fundTranTypeId !== '' && fundtx_detail.value.fundTranTypeId !== null) {
          let ld = listsubcat_ae.value.filter((c) => c.uid === fundtx_detail.value.fundTranTypeId);
          if (ld.length > 0) {
            listtransc_ae.value = ld
            listsource_ae.value = listtransc_ae.value
          }
        }

        if (fundtx_detail.value.txType === 'TRANSFER') {
          fundtx_detail.value.txType = "txTransfer"
        } else if (fundtx_detail.value.txType === 'WITHDRAW') {
          fundtx_detail.value.txType = "txWithdrawal"
        } else if (fundtx_detail.value.txType === 'DEPOSIT') {
          fundtx_detail.value.txType = "txDeposit"
        }



        if (fundtx_detail.value.sourceType === 'OTHERS') {
          fundtx_detail.value.sourceUid = 'OTHERS'
        }

        if (fundtx_detail.value.targetType === 'OTHERS') {
          fundtx_detail.value.targetUid = 'OTHERS'
        }

        // console.log('edit r : ' + JSON.stringify(item));
        // console.log('fundtx_detail r : ' + JSON.stringify(fundtx_detail.value));

        setsecondTab()
      }

    }
  })
}

function clearItem() {
  thumb.value = null
  fundtx_detail.value = {
    uid: '',
    txDate: new Date(),
    fundCategory: '',
    fundTranTypeId: '',
    txType: '',
    sourceType: '',
    sourceUid: '0',
    targetType: '',
    targetUid: '0',
    txAmount: '',
    detail: '',
    refDocUid: '0',
    version: '',
    targetAccNumber: '',
    sourceAccNumber: '',
  };
  document.getElementById("upload").value = null;
}


async function addfundTx() {
  const payload = fundtx_detail.value
  if (thumb.value !== null) {
    thumb_id.value = '0'
    await addfileApi()
    payload.refDocUid = thumb_id.value
  }

  if (payload.sourceUid !== '0' && payload.sourceUid !== 'OTHERS') {
    payload.sourceType = checkMoneySource(payload.sourceUid)
  }

  if (payload.targetUid !== '0' && payload.targetUid !== 'OTHERS') {
    payload.targetType = checkMoneySource(payload.targetUid)
  } else {
    payload.targetType = null
  }
  payload.txAmount = Number(payload.txAmount).toFixed(2);
  payload.txDate = moment(new Date(payload.txDate)).format('YYYY-MM-DDTHH:mm:ss')

  payload.detail = payload.detail.trim();
  // console.log('addfundTx p : ' + JSON.stringify(payload));
  const response = await storefund.fundTxAddFetch(payload);
  // console.log('addfundTx r : ' + JSON.stringify(response.data));
  if (response) {
    if (response.status === 200) {
      if (response.data.rtnDesc === "success") {
        swal("บันทึกสำเร็จ", "", "success");
        await backfirstTab()
      } else {
        swal("บันทึกไม่สำเร็จ", response.data.rtnDesc, "error");
      }
    }
  }
}

async function updatefundTx() {

  const payload = fundtx_detail.value
  if (thumb.value !== null) {
    thumb_id.value = '0'
    await addfileApi()
    payload.refDocUid = thumb_id.value
  }

  if (payload.sourceUid !== '0' && payload.sourceUid !== 'OTHERS') {
    payload.sourceType = checkMoneySource(payload.sourceUid)
  }

  if (payload.targetUid !== '0' && payload.targetUid !== 'OTHERS') {
    payload.targetType = checkMoneySource(payload.targetUid)
  } else {
    payload.targetType = null
  }

  payload.txAmount = Number(payload.txAmount).toFixed(2);
  payload.detail = payload.detail.trim();
  // console.log('updatefundTx p : ' + JSON.stringify(payload));
  const response = await storefund.fundTxUpdateFetch(payload);
  // console.log('updatefundTx r : ' + JSON.stringify(response.data));
  if (response) {
    if (response.status === 200) {
      if (response.data.rtnDesc === "success") {
        swal("บันทึกสำเร็จ", "", "success");
        await backfirstTab()
      } else {
        swal("บันทึกไม่สำเร็จ", response.data.rtnDesc, "error");
      }
    }
  }
}


async function deletefundTx(uid) {
  let payload = ""
  payload = {
    uid: uid
  }
  // console.log('delete p : ' + JSON.stringify(payload));
  const response = await storefund.fundTxDeleteFetch(payload);
  // console.log('delete r : ' + JSON.stringify(response.data));
  if (response) {
    if (response.status === 200) {
      if (response.data.rtnDesc === "success") {
        swal("บันทึกสำเร็จ", "", "success");
        loadDataApi('del')
      } else {
        swal("บันทึกไม่สำเร็จ", response.data.rtnDesc, "error");
      }
    }
  }
}

async function addfileApi() {
  let data = "";
  data = {
    name: thumb_name.value,
    data: thumb.value,
  };

  const payload = data;
  const response = await storedoc.addFetch(payload);
  if (response) {
    if (response.status === 200) {
      if (response.data.rtnDesc === "success") {
        thumb_id.value = response.data.data.uid
      } else {
        swal("บันทึกไฟล์ไม่สำเร็จ", response.data.rtnDesc, "error");
      }
    }
  }
}

async function onClickTab(val) {
  if (val === '1') {
    if (end_date.value >= start_date.value) {
      loadDataApi()
    }
    await setfirstTab()
  } else if (val === '2') {
    await setsecondTab()
  }
}

async function backfirstTab() {
  clearItem()
  await onClickTab('1')
}

async function setfirstTab() {
  if (document.getElementById("second-transactions")) {
    document.getElementById("second-transactions").classList.remove('show', 'active')
  }

  if (document.getElementById("secondTransactions")) {
    document.getElementById("secondTransactions").classList.remove('show', 'active')
  }

  if (document.getElementById("first-transactions")) {
    document.getElementById("first-transactions").classList.add('show', 'active')
  }

  if (document.getElementById("firstTransactions")) {
    document.getElementById("firstTransactions").classList.add('show', 'active')
  }
}


async function setsecondTab() {
  if (document.getElementById("first-transactions")) {
    document.getElementById("first-transactions").classList.remove('show', 'active')
  }

  if (document.getElementById("firstTransactions")) {
    document.getElementById("firstTransactions").classList.remove('show', 'active')
  }
  if (document.getElementById("second-transactions")) {
    document.getElementById("second-transactions").classList.add('show', 'active')
  }

  if (document.getElementById("secondTransactions")) {
    document.getElementById("secondTransactions").classList.add('show', 'active')
  }
}


async function getFileRef(item) {
  loading.value = true
  let url = []
  images.value = []
  url.push(String(st.url_api + "/doc-svc/document/info/" + item.refDocUid));
  if (url.length > 0) {
    await getFile(url)
    if (images.value.length > 0) {
      if (images.value[0].title === 'application/pdf') {
        window.open(images.value[0].src, '_blank').focus();
      } else {
        setGallery2()
        document.getElementById("attachmenDialog").click();
      }

      loading.value = false
    }
  }
}


async function getFile(urls) {
  let file_L = []
  const key = JSON.parse(localStorage.getItem("FR-SDK-nvtx-boapp"));
  const accessToken = key.accessToken;
  axios.defaults.headers["Authorization"] = `Bearer ${accessToken}`;
  const requests = urls.map((url) => axios.get(url));
  await Promise.all(requests).then(allResults => {
    if (allResults) {
      file_L = allResults
      if (file_L !== null) {
        // console.log('file_L : ' + JSON.stringify(file_L))
        for (let i = 0; i < file_L.length; i++) {
          // console.log('file_L : ' + JSON.stringify(file_L.value[i].data.data) + ' file_L 2 : ' + JSON.stringify(file_L.value[i].data.name));
          if (file_L[i].data.data !== null) {
            const file = getFileFromBase64(file_L[i].data.data.data, file_L[i].data.data.name)
            // console.log('file : ' + file.size + ' file t ' + file.type)
            const data = URL.createObjectURL(file);
            let obj = {
              src: data,
              thumbnailImageSrc: data,
              alt: '',
              title: file.type,
              filename: file_L[i].data.data.name,
            }
            images.value.push(obj)
          } else {
            // console.log('no dataFile ')
            loading.value = false
          }

          // console.log('File xx : ', file_L.value[i].file.type + 'File size : ', file_L.value[i].file.size + ' bytes in size  name : ' + file_L.value[i].data.data.name);
        }
      }
    }
  })

}


function getFileFromBase64(string64, fileName) {
  const trimmedString = string64.replace('dataimage/jpegbase64', '');
  var ext = fileName.split('.').pop();
  let type = '';
  let imageContent = null
  if (ext === 'jpg') {
    type = 'image/jpeg'
  } else if (ext === 'png') {
    type = 'image/png'
  } else if (ext === 'pdf') {
    type = 'application/pdf'
  }

  if (type === 'application/pdf') {
    imageContent = atob(string64);
  } else {
    imageContent = atob(trimmedString);
  }

  const buffer = new ArrayBuffer(imageContent.length);
  const view = new Uint8Array(buffer);

  for (let n = 0; n < imageContent.length; n++) {
    view[n] = imageContent.charCodeAt(n);
  }

  const blob = new Blob([buffer], { type });
  return new File([blob], fileName, { lastModified: new Date().getTime(), type });
}




function setGallery2() {

  var x = "", i, y = "";

  for (i = 0; i < images.value.length; i++) {

    if (i == 0) {
      x = x + '<div class="carousel-item active"> ' +
        '<img src="' + images.value[i].src + '" class="d-block w-100" alt="..." />' +
        '</div>';
      y = y + '<button type = "button" data-bs-target="#attachmentsImages" data-bs-slide-to="' + String(i) + '"' +
        ' class="active" aria-current="true" aria-label="Slide ' + Number(i + 1) + '"> </button >';
    } else {
      x = x + '<div class="carousel-item"> ' +
        '<img src="' + images.value[i].src + '" class="d-block w-100" alt="..." />' +
        '</div>';
      y = y + '<button type = "button" data-bs-target="#attachmentsImages" data-bs-slide-to="' + String(i) + '"' +
        ' aria-label="Slide ' + Number(i + 1) + '"> </button >';
    }

  }
  document.getElementById("demo2").innerHTML = y;
  document.getElementById("demo3").innerHTML = x;

}


onMounted(() => {

  if (document.getElementById("first-transactions")) {
    document.getElementById("first-transactions").classList.add('show', 'active')
  }

  if (document.getElementById("firstTransactions")) {
    document.getElementById("firstTransactions").classList.add('show', 'active')
  }
});

</script>

<style lang="scss" scoped>
.loading-state {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: 9999;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
