/* eslint-disable no-unused-vars */
import { defineStore } from "pinia";
import http from "../config/apiConfig";

export const useLostStore = defineStore("lost", {
  actions: {
    async lostInfoFetch(payload) {
      return new Promise((resolve, reject) => {
        try {
          const pathUrl = "/pawn-svc/lost/info/" + payload.uid;
          const response = http.get(pathUrl);
          resolve(response);
        } catch (e) {
          reject(e);
        }
      });
    },

    async lostListFetch(payload) {
      const body = {
        page: {
          index: payload.index,
          size: payload.size,
          sort: "",
        },
        filter: {
          cusUid: payload.cusUid,
          refNumber: payload.refNumber,
        },
      };
      return new Promise((resolve, reject) => {
        try {
          const pathUrl = "/pawn-svc/lost/list";
          const response = http.post(pathUrl, body);
          resolve(response);
        } catch (e) {
          reject(e);
        }
      });
    },
    async lostSearchFetch(payload) {
      const body = {
        page: {
          index: payload.index,
          size: payload.size,
          sort: "",
        },
        filter: {
          cusUid: payload.cusUid,
          refNumber: payload.refNumber,
          fromDate: payload.fromDate,
          toDate: payload.toDate,
        },
      };
      return new Promise((resolve, reject) => {
        try {
          const pathUrl = "/pawn-svc/lost/search";
          const response = http.post(pathUrl, body);
          resolve(response);
        } catch (e) {
          reject(e);
        }
      });
    },
    async lostNewFetch(payload) {
      const body = {
        workCaseUid: payload.workCaseUid, // always required
        remark: payload.remark,
        fpImageUid: payload.fpImageUid,
        fpTemplateUid: payload.fpTemplateUid,
      };
      return new Promise((resolve, reject) => {
        try {
          const pathUrl = "/pawn-svc/lost/new";
          const response = http.post(pathUrl, body);
          resolve(response);
        } catch (e) {
          reject(e);
        }
      });
    },
    async lostUpdateFetch(payload) {
      const body = {
        uid: payload.uid, // always required
        plDocUid: payload.plDocUid,
        remark: payload.remark,
        version: payload.version,
        fpImageUid: payload.fpImageUid,
        fpTemplateUid: payload.fpTemplateUid,
      };
      return new Promise((resolve, reject) => {
        try {
          const pathUrl = "/pawn-svc/lost/update";
          const response = http.post(pathUrl, body);
          resolve(response);
        } catch (e) {
          reject(e);
        }
      });
    },
  },
});
