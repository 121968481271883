<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col-12 mb-3">
        <div class="card h-100 mt-3">
          <div class="card-header mb-0">
            <h5>{{ $t("content.gdashboard") }}</h5>
          </div>
          <div class="card-body pt-0">
            <div class="row">
              <div class="col-6 text-center">
                <h5 class="mt-4 mb-0">
                  {{ $t("content.numberactive") }}
                </h5>
                <div>
                  <h5 class="mb-0 text-info">
                    <b>
                      <span v-if="loading">
                        {{ formatNumberString(dataInfo.numOfActiveWorkCase) }}
                      </span>
                      <span v-else>
                        0
                      </span>
                    </b>
                  </h5>
                </div>
              </div>
              <div class="col-6 text-center">
                <h5 class="mt-4 mb-0">
                  {{ $t("content.numberpostpone") }}
                </h5>
                <div>
                  <h5 class="mb-0 text-info">
                    <b>
                      <span v-if="loading">
                        {{ formatNumberString(dataInfo.numOfPostponeWorkCase) }}
                      </span>
                      <span v-else>
                        0
                      </span>
                    </b>
                  </h5>
                </div>
              </div>
            </div>
            <pre></pre>
            <div class="row">
              <div class="col-lg-4 me-3">
                <span class="form-label m-0">{{ $t("content.sdate") }}</span>
                <div class="d-inline-block mx-4">
                  <VueDatePicker v-model="start_date" :format="format" auto-apply :teleport="true"
                    :max-date="new Date()" :enable-time-picker="false" month-name-format="long" :clearable="false"
                    :text-input="textInputOptions" fluid />
                </div>
              </div>
              <div class="col-lg-4 me-3">
                <span class="form-label m-0">{{ $t("content.edate") }}</span>
                <div class="d-inline-block mx-4">
                  <VueDatePicker v-model="end_date" :format="format" auto-apply :teleport="true" :max-date="new Date()"
                    :enable-time-picker="false" month-name-format="long" :clearable="false"
                    :text-input="textInputOptions" fluid />
                </div>
              </div>
              <div class="col-lg-2 me-3">
                <span class="form-label m-0"></span>
                <div class="d-inline-block mx-4">
                  <material-button id="cardSearch" color="primary" class="float-lg-start float-sm-end mb-3"
                    @click="loadDataInfoApi" :disabled="monthDiff() || start_date > end_date">
                    <i class="material-icons material-symbols-outlined">search</i>
                    {{ search }}
                  </material-button>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-4 text-center">
                <h5 class="mt-4 mb-0">
                  <b> {{ $t("content.amnenter") }} </b>
                </h5>
                <div>
                  <h2 class="mb-0 text-info">
                    <b>
                      <span v-if="loading">
                        {{ formatNumberString(dataInfo.numOfCustomer) }}
                      </span>
                      <span v-else>
                        0
                      </span>
                    </b>
                  </h2>
                </div>
              </div>

              <div class="col-2 text-center">
                <h5 class="mt-4 mb-0">
                  <b> {{ $t("content.numOfCancel") }} </b>
                </h5>
                <div>
                  <h2 class="mb-0 text-info">
                    <b style="color:red;">
                      <span v-if="loading">
                        {{ formatNumberString(dataInfo.numOfCancelTicket) }}
                      </span>
                      <span v-else>
                        0
                      </span>
                    </b>
                  </h2>
                </div>
              </div>

              <div class="col-3 text-center">
                <h5 class="mt-4 mb-0">
                  <b> {{ $t("content.numOfTerminate") }} </b>
                </h5>
                <div>
                  <h2 class="mb-0 text-info">
                    <b style="color:orange;">
                      <span v-if="loading">
                        {{ formatNumberString(dataInfo.numOfTerminate) }}
                      </span>
                      <span v-else>
                        0
                      </span>
                    </b>
                  </h2>
                </div>
              </div>

              <!-- <div class="col-2 text-center">
                <h6 class="mt-4 mb-0">
                  <b>
                    numOfVoidTicket
                  </b>
                </h6>
                <div>
                  <h3 class="mb-0 text-info">
                    <b style="color:orange;">
                      <span v-if="loading">
                        {{ formatNumberString(dataInfo.numOfVoidTicket) }}
                      </span>
                      <span v-else>
                        0
                      </span>
                    </b>
                  </h3>
                </div>
              </div> -->

              <div class="col-3 text-center">
                <h5 class="mt-4 mb-0">
                  <b>
                    {{ $t("content.editafcom") }}
                  </b>
                </h5>
                <div>
                  <h2 class="mb-0 text-info">
                    <b style="color:#656A60;">
                      <span v-if="loading">
                        {{ formatNumberString(dataInfo.numOfEditAfterComplete) }}
                      </span>
                      <span v-else>
                        0
                      </span>
                    </b>
                  </h2>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-6 text-center">
                <h5 class="mt-4 mb-0">
                  {{ $t("content.numberotp") }}
                </h5>
                <div>
                  <h5 class="mb-0 text-info">
                    <b>
                      <span v-if="loading">
                        {{ formatNumberString(dataInfo.numOfOtp) }}
                      </span>
                      <span v-else>
                        0
                      </span>
                    </b>
                  </h5>
                </div>
              </div>
              <div class="col-6 text-center">
                <h5 class="mt-4 mb-0">
                  {{ $t("content.numberotpmi") }}
                </h5>
                <div>
                  <h5 class="mb-0 text-info">
                    <b>
                      <span v-if="loading">
                        {{ formatNumberString(dataInfo.numOfOtpMigration) }}
                      </span>
                      <span v-else>
                        0
                      </span>
                    </b>
                  </h5>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row" v-if="loading">
      <div class="col-lg-12 mb-2">
        <div class="card h-100 mt-2">
          <div class="h-100 mt-2">
            <div class="p-3 pb-0 card-header">
              <div class="d-flex justify-content-between">
                <h5 class="mb-0">
                  <b>{{ $t("content.workProcess") }}
                  </b>
                </h5>
              </div>
            </div>

            <div class="card-body pt-0">
              <div class="row">
                <div class="col-2 text-center">
                  <h5 class="mt-4 mb-0">
                    {{ $t("content.workQ01") }}
                  </h5>
                  <div>
                    <h5 class="mb-0 text-info">
                      <b>
                        <span v-if="loading">
                          {{ formatNumberString(num_q01) }}
                        </span>
                        <span v-else>
                          0
                        </span>
                      </b>
                    </h5>
                  </div>
                </div>

                <div class="col-3 text-center">
                  <h5 class="mt-4 mb-0">

                    {{ $t("content.workQ02") }}

                  </h5>
                  <div>
                    <h5 class="mb-0 text-info">
                      <b>
                        <span v-if="loading">
                          {{ formatNumberString(num_q02) }}
                        </span>
                        <span v-else>
                          0
                        </span>
                      </b>
                    </h5>
                  </div>
                </div>

                <div class="col-3 text-center">
                  <h5 class="mt-4 mb-0">

                    {{ $t("content.workQ03") }}

                  </h5>
                  <div>
                    <h5 class="mb-0 text-info">
                      <b>
                        <span v-if="loading">
                          {{ formatNumberString(num_q03) }}
                        </span>
                        <span v-else>
                          0
                        </span>
                      </b>
                    </h5>
                  </div>
                </div>

                <div class="col-2 text-center">
                  <h5 class="mt-4 mb-0">
                    {{ $t("content.workQ04") }}
                  </h5>
                  <div>
                    <h5 class="mb-0 text-info">
                      <b>
                        <span v-if="loading">
                          {{ formatNumberString(num_q04) }}
                        </span>
                        <span v-else>
                          0
                        </span>
                      </b>
                    </h5>
                  </div>
                </div>

                <div class="col-2 text-center">
                  <h5 class="mt-4 mb-0">
                    {{ $t("content.workQpay") }}
                  </h5>
                  <div>
                    <h5 class="mb-0 text-info">
                      <b>
                        <span v-if="loading">
                          {{ formatNumberString(num_pay) }}
                        </span>
                        <span v-else>
                          0
                        </span>
                      </b>
                    </h5>
                  </div>
                </div>

              </div>
            </div>

          </div>
        </div>
      </div>

      <div class="col-lg-12 mb-2">
        <div class="card h-100 mt-2">
          <div class="row mb-2">
            <div class="col-lg-12 mb-2">
              <div class="h-100 mt-2">
                <div class="p-3 pb-0 card-header">
                  <div class="d-flex justify-content-between">
                    <h5 class="mb-0">
                      <b>{{ $t("content.oldsystemsinfo") }}
                      </b>
                    </h5>
                  </div>
                </div>

                <div class="card-body pt-0">
                  <div class="row">
                    <div class="col-2">
                      <h5 class="mt-4 mb-0 text-start">
                        {{ $t("content.pawntktotal") }} :&nbsp;
                        <span style="color: red;">
                          <b>{{ formatNumberString(sum_pawncase) }}</b>
                        </span>
                      </h5>
                    </div>

                    <div class="col-4">
                      <h5 class="mt-4 mb-0 text-start">
                        {{ $t("content.pawntotal") }} ({{ $t("content.newtktotal")
                        }}) :&nbsp;
                        <span style="color: red;">
                          <b>{{ formatDecimalString(sum_pawnvalue) }}</b>
                        </span>
                      </h5>
                    </div>

                    <div class="col-2">
                      <h5 class="mt-4 mb-0 text-start">
                        {{ $t("content.redeemtktotal") }} :&nbsp;
                        <span style="color: green;">
                          <b>{{ formatNumberString(sum_redeemcase) }}</b>
                        </span>
                      </h5>
                    </div>

                    <div class="col-4">
                      <h5 class="mt-4 mb-0 text-start">
                        {{ $t("content.redeemtotal") }} ({{
                          $t("content.oldtktotal")
                        }}) :&nbsp;
                        <span style="color: green;">
                          <b>{{ formatDecimalString(sum_redeemvalue) }}</b>
                        </span>
                      </h5>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-lg-7 mb-2">
              <div class="h-100 mt-2">
                <div class="p-3 pb-0 card-header">
                  <div class="d-flex justify-content-between">
                    <h5 class="mb-0">
                      <b>{{ $t("content.ovrtransnor") }} :&nbsp;
                        <span class="mb-0 text-info">
                          {{ formatNumberString(Number(dataInfo.numOfPawn) + Number(dataInfo.numOfInterest) +
                            Number(dataInfo.numOfInc) + Number(dataInfo.numOfDec) + Number(dataInfo.numOfRedeem)) }}
                        </span>
                      </b>
                    </h5>
                  </div>
                </div>
                <div class="p-3 mt-4 card-body">
                  <table class="table table-sm table-borderless">
                    <tbody>
                      <tr>
                        <td class="fs-14 text-start" width=15>
                          <span class="badge badge-md badge-dot me-4 d-block text-start">
                            <i :class="legendTimes[0].bg"></i>
                            <span class="text-md text-dark">{{ $t("content.actpawn1") }}
                              <span class="mb-0 text-info">
                                ({{ formatNumberString(dataInfo.numOfPawn) }})
                              </span>
                            </span>
                          </span>
                        </td>
                        <td colspan="3" class="fs-14 text-bold">
                          <span class="text-md text-dark"> {{ $t("content.valticket") }}&nbsp;
                            {{ formatDecimalString(dataInfo.pawnValue) }}</span>
                        </td>
                      </tr>
                      <tr>
                        <td class="fs-14 text-start" width=15>
                          <span class="badge badge-md badge-dot me-4 d-block text-start">
                            <i :class="legendTimes[1].bg"></i>
                            <span class="text-md text-dark">{{ $t("content.actpawn2") }}
                              <span class="mb-0 text-info">
                                ({{ formatNumberString(dataInfo.numOfInterest) }})
                              </span>
                            </span>
                          </span>
                        </td>
                        <td class="fs-14 text-bold">
                          <span class="text-md text-dark"> {{ $t("content.valticket") }}&nbsp;
                            {{ formatDecimalString(dataInfo.interestPawnValue) }}
                          </span>
                        </td>
                        <td class="fs-14 text-bold">
                          <span class="text-md text-dark"> {{ $t("content.amninterest") }}&nbsp;
                            {{ formatDecimalString(dataInfo.interestAmount) }}
                          </span>
                        </td>
                      </tr>
                      <tr>
                        <td class="fs-14 text-start" width=15>
                          <span class="badge badge-md badge-dot me-4 d-block text-start">
                            <i :class="legendTimes[2].bg"></i>
                            <span class="text-md text-dark">{{ $t("content.actpawn3") }}
                              <span class="mb-0 text-info">
                                ({{ formatNumberString(dataInfo.numOfInc) }})
                              </span>
                            </span>
                          </span>
                        </td>
                        <td class="fs-14 text-bold">
                          <span class="text-md text-dark"> {{ $t("content.valticket") }}&nbsp;
                            {{ formatDecimalString(dataInfo.incPawnValue) }}
                          </span>
                        </td>
                        <td class="fs-14 text-bold">
                          <span class="text-md text-dark"> {{ $t("content.amninterest") }}&nbsp;
                            {{ formatDecimalString(dataInfo.incInterestAmount) }}
                          </span>
                        </td>
                        <td class="fs-14 text-bold">
                          <span class="text-md text-dark"> {{ $t("content.amncashdrawerdiff") }}&nbsp;
                            {{ formatDecimalString(dataInfo.incDiff) }}
                          </span>
                        </td>
                      </tr>
                      <tr>
                        <td class="fs-14 text-start" width=15>
                          <span class="badge badge-md badge-dot me-4 d-block text-start">
                            <i :class="legendTimes[3].bg"></i>
                            <span class="text-md text-dark">{{ $t("content.actpawn4") }}
                              <span class="mb-0 text-info">
                                ({{ formatNumberString(dataInfo.numOfDec) }})
                              </span>
                            </span>
                          </span>
                        </td>
                        <td class="fs-14 text-bold">
                          <span class="text-md text-dark"> {{ $t("content.valticket") }}&nbsp;
                            {{ formatDecimalString(dataInfo.decPawnValue) }}
                          </span>
                        </td>
                        <td class="fs-14 text-bold">
                          <span class="text-md text-dark"> {{ $t("content.amninterest") }}&nbsp;
                            {{ formatDecimalString(dataInfo.decInterestAmount) }}
                          </span>
                        </td>
                        <td class="fs-14 text-bold">
                          <span class="text-md text-dark"> {{ $t("content.amncashdrawerdiff") }}&nbsp;
                            {{ formatDecimalString(dataInfo.decDiff) }}
                          </span>
                        </td>
                      </tr>
                      <tr>
                        <td class="fs-14 text-start" width=15>
                          <span class="badge badge-md badge-dot me-4 d-block text-start">
                            <i :class="legendTimes[4].bg"></i>
                            <span class="text-md text-dark">{{ $t("content.actpawn5") }}
                              <span class="mb-0 text-info">
                                ({{ formatNumberString(dataInfo.numOfRedeem) }})
                              </span>
                            </span>
                          </span>
                        </td>
                        <td class="fs-14 text-bold">
                          <span class="text-md text-dark"> {{ $t("content.valticket") }}&nbsp;
                            {{ formatDecimalString(dataInfo.redeemPawnValue) }}
                          </span>
                        </td>
                        <td class="fs-14 text-bold">
                          <span class="text-md text-dark"> {{ $t("content.amninterest") }}&nbsp;
                            {{ formatDecimalString(dataInfo.redeemInterestAmount) }}
                          </span>
                        </td>
                      </tr>
                      <pre></pre>
                      <tr>
                        <td colspan="2" class="fs-14 text-bold">
                          <span class="text-md text-dark">
                            {{ $t("content.actpawn1") }},{{ $t("content.actpawn3") }} :&nbsp;
                            <span style="color: red;">
                              {{ formatDecimalString(sum_painvalue) }}
                            </span> </span>
                        </td>
                        <td colspan="2" class="fs-14 text-bold">
                          <span class="text-md text-dark">
                            {{ $t("content.actpawn2") }},{{ $t("content.actpawn4") }},{{ $t("content.actpawn5") }}
                            :&nbsp;
                            <span style="color: green;">
                              {{ formatDecimalString(sum_inderevalue) }}
                            </span>

                          </span>
                        </td>
                      </tr>
                      <pre></pre>
                      <tr>
                        <td colspan="2" class="fs-14 text-bold">
                          <span class="text-md text-dark">
                            {{ $t("content.sumvalticket") }} :&nbsp;
                            <span style="color: blue;">
                              {{ formatDecimalString(sum_pvalue) }}
                            </span>
                          </span>
                        </td>
                        <td colspan="2" class="fs-14 text-bold">
                          <span class="text-md text-dark">
                            {{ $t("content.sumintticket") }} :&nbsp;
                            <span style="color: green;">
                              {{ formatDecimalString(sum_intvalue) }}
                            </span>
                          </span>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <div class="col-lg-5 mb-4">
              <div class="h-100 mt-3">
                <div class="p-3 pb-0 card-header">
                  <div class="d-flex justify-content-between">
                    <h5 class="mb-0">
                      <b>{{ $t("content.ovrtransmig") }} :&nbsp;
                        <span class="mb-0 text-info">
                          {{ formatNumberString(Number(dataInfo.numOfInterestMigration) +
                            Number(dataInfo.numOfIncMigration) + Number(dataInfo.numOfDecMigration)) }}
                        </span>
                      </b>
                    </h5>

                  </div>
                </div>
                <div class="p-3 mt-4 card-body">
                  <table class="table table-sm table-borderless">
                    <tbody>
                      <tr>
                        <td class="fs-14 text-start" width=15>
                          <span class="badge badge-md badge-dot me-4 d-block text-start">
                            <i :class="legendTimes[1].bg"></i>
                            <span class="text-md text-dark">{{ $t("content.actpawn2") }} ({{ $t("content.transmig")
                              }})
                              <span class="mb-0 text-info">
                                ({{ formatNumberString(dataInfo.numOfInterestMigration) }})
                              </span>
                            </span>
                          </span>
                        </td>
                        <td class="fs-14 text-bold">
                          <span class="text-md text-dark"> {{ $t("content.valticket") }}&nbsp;
                            {{ formatDecimalString(dataInfo.interestPawnValueMigration) }}
                          </span>
                        </td>
                      </tr>
                      <tr>
                        <td class="fs-14 text-start" width=15>
                          <span class="badge badge-md badge-dot me-4 d-block text-start">
                            <i :class="legendTimes[2].bg"></i>
                            <span class="text-md text-dark">{{ $t("content.actpawn3") }} ({{ $t("content.transmig")
                              }})
                              <span class="mb-0 text-info">
                                ({{ formatNumberString(dataInfo.numOfIncMigration) }})
                              </span>
                            </span>
                          </span>
                        </td>
                        <td class="fs-14 text-bold">
                          <span class="text-md text-dark"> {{ $t("content.valticket") }}&nbsp;
                            {{ formatDecimalString(dataInfo.incPawnValueMigration) }}
                          </span>
                        </td>
                      </tr>
                      <tr>
                        <td class="fs-14 text-start" width=15>
                          <span class="badge badge-md badge-dot me-4 d-block text-start">
                            <i :class="legendTimes[3].bg"></i>
                            <span class="text-md text-dark">{{ $t("content.actpawn4") }} ({{ $t("content.transmig")
                              }})
                              <span class="mb-0 text-info">
                                ({{ formatNumberString(dataInfo.numOfDecMigration) }})
                              </span>
                            </span>
                          </span>
                        </td>
                        <td class="fs-14 text-bold">
                          <span class="text-md text-dark"> {{ $t("content.valticket") }}&nbsp;
                            {{ formatDecimalString(dataInfo.decPawnValueMigration) }}
                          </span>
                        </td>
                      </tr>
                      <tr>
                        <td class="fs-14 text-start" width=15>
                          <span class="badge badge-md badge-dot me-4 d-block text-start">
                            <i></i>
                            <span class="text-md text-dark"></span>
                          </span>
                        </td>
                        <td class="fs-14 text-bold">
                          <span class="text-md text-dark"></span>
                        </td>
                      </tr>
                      <tr>
                        <td class="fs-14 text-start" width=15>
                          <span class="badge badge-md badge-dot me-4 d-block text-start">
                            <i></i>
                            <span class="text-md text-dark"></span>
                          </span>
                        </td>
                        <td class="fs-14 text-bold">
                          <span class="text-md text-dark"> </span>
                        </td>
                      </tr>
                      <pre></pre>
                      <tr>
                        <td colspan="2" class="fs-14 text-bold">
                          <span class="text-md text-dark"> {{ $t("content.sumvalticket") }} :&nbsp;
                            <span style="color: red;">
                              {{ formatDecimalString(sum_mvalue) }}
                            </span>
                          </span>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="col-lg-6 mb-4">
        <div class="card h-100 mt-3">
          <div class="p-3 pb-0 card-header">
            <div class="d-flex justify-content-between align-items-center">
              <h5 class="mb-0">
                <b>{{ $t("content.amnqty") }}</b>
              </h5>

              <h2 class="mb-0 text-info">
                <b>
                  {{ formatNumberString(dataInfo.numOfWorkCase) }}
                </b>
              </h2>
            </div>
          </div>
          <div class="p-3 mt-4 card-body">
            <div class="row">
              <div class="col-7 text-start">
                <pie-chart id="chart-times" :chart="{
                  labels: [plegde, interest, addcap, reducecap, redeem],
                  datasets: {
                    label: 'Projects',
                    data: [
                      legendTimes[0].dt,
                      legendTimes[1].dt,
                      legendTimes[2].dt,
                      legendTimes[3].dt,
                      legendTimes[4].dt,
                    ],
                    backgroundColor: colors,
                  },
                }" />
              </div>
              <div class="my-auto col-5">
                <span v-for="(i, pt1) in legendTimes" :key="pt1"
                  class="badge badge-md badge-dot me-4 d-block text-start">
                  <i :class="i.bg"></i>
                  <span class="text-md text-dark">{{ i.hd }} ({{ fmNumber(i.dt) }})</span>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="col-lg-6 mb-4">
        <div class="card h-100 mt-3">
          <div class="p-3 pb-0 card-header">
            <div class="d-flex justify-content-between align-items-center">
              <h5 class="mb-0">
                <b>{{ $t("content.amnmoney") }}</b>
              </h5>
            </div>
          </div>
          <div class="p-3 mt-4 card-body">
            <div class="row">
              <div class="col-7 text-start">
                <pie-chart id="chart-baht" :chart="{
                  labels: [plegde, interest, addcap, reducecap, redeem],
                  datasets: {
                    label: 'Projects',
                    data: [
                      legendBaht[0].dt,
                      legendBaht[1].dt,
                      legendBaht[2].dt,
                      legendBaht[3].dt,
                      legendBaht[4].dt,
                    ],
                    backgroundColor: colors,
                  },
                }" />
              </div>
              <div class="my-auto col-5">
                <span v-for="(i, pt2) in legendBaht" :key="pt2"
                  class="badge badge-md badge-dot me-4 d-block text-start">
                  <i :class="i.bg"></i>
                  <span class="text-md text-dark">{{ i.hd }} ({{ formatNumberDecimalString(i.dt) }})</span>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="col-lg-6 mb-4">
        <div class="card h-100 mt-3">
          <div class="p-3 pb-0 card-header">
            <div class="d-flex justify-content-between align-items-center">
              <h5 class="mb-0">
                <b>{{ $t("content.amncashflow") }}</b>
              </h5>
            </div>
          </div>
          <div class="p-3 pb-0 card-header">
            <div class="d-flex justify-content-between align-items-center">
              <h6 class="mb-0 text-info">
                <b>
                  <span class="text-md text-dark">{{ $t("content.amnoutcome") }} : </span>
                  <span style="color:red">
                    {{ formatDecimalString(dataInfo.payAmount) }}
                  </span>
                </b>
              </h6>
              <h6 class="mb-0 text-info">
                <b>
                  <span class="text-md text-dark">{{ $t("content.amnincome") }} : </span>
                  <span style="color:green">
                    {{ formatDecimalString(dataInfo.receiveAmount) }}
                  </span>
                </b>
              </h6>

              <h6 class="mb-0 text-info">
                <b>
                  <span class="text-md text-dark">{{ $t("content.amninterest") }} : </span>
                  <span class="text-info">
                    {{ formatDecimalString(dataInfo.sumInterest) }}
                  </span>
                </b>
              </h6>
            </div>
          </div>
          <div class="p-3 mt-4 card-body">
            <div class="row">
              <div class="col-7 text-start">
                <pie-chart id="chart-sum" :chart="{
                  labels: [sincome, soutcome, sinterest],
                  datasets: {
                    label: 'Projects',
                    data: [
                      legendSum[1].dt,
                      legendSum[0].dt,
                      legendSum[2].dt,
                    ],
                    backgroundColor: colors2,
                  },
                }" />
              </div>
              <div class="my-auto col-5">
                <span v-for="(i, pt2) in legendSum" :key="pt2" class="badge badge-md badge-dot me-4 d-block text-start">
                  <i :class="i.bg"></i>
                  <span class="text-md text-dark">{{ i.hd }} ({{ formatNumberDecimalString(i.dt) }})</span>
                </span>
              </div>

            </div>

          </div>
        </div>
      </div>

      <div class="col-lg-6 mb-4">
        <div class="card h-100 mt-3">
          <div class="p-3 pb-0 card-header">
            <div class="d-flex justify-content-between align-items-center">
              <h5 class="mb-0">
                <b>{{ $t("content.amnmigflow") }}</b>
              </h5>
            </div>
          </div>
          <div class="p-3 pb-0 card-header">
            <div class="d-flex justify-content-between align-items-center">
              <h6 class="mb-0 text-info">
                <b>
                  <span class="text-md text-dark">{{ $t("content.amnoutcome") }} : </span>
                  <span style="color:red">
                    {{ formatDecimalString(dataInfo.totalPayMigration) }}
                  </span>
                </b>
              </h6>
              <h6 class="mb-0 text-info">
                <b>
                  <span class="text-md text-dark">{{ $t("content.amnincome") }} : </span>
                  <span style="color:green">
                    {{ formatDecimalString(dataInfo.totalReceiveMigration) }}
                  </span>
                </b>
              </h6>
            </div>
          </div>
          <div class="p-3 mt-4 card-body">
            <div class="row">
              <div class="col-7 text-start">
                <pie-chart id="chart-mig" :chart="{
                  labels: [interest, addcap, reducecap],
                  datasets: {
                    label: 'Projects',
                    data: [
                      legendMig[0].dt,
                      legendMig[1].dt,
                      legendMig[2].dt,
                    ],
                    backgroundColor: colors,
                  },
                }" />
              </div>
              <div class="my-auto col-5">
                <span v-for="(i, pt2) in legendMig" :key="pt2" class="badge badge-md badge-dot me-4 d-block text-start">
                  <i :class="i.bg"></i>
                  <span class="text-md text-dark">{{ i.hd }} {{ formatDecimalString(i.dt) }} ({{ fmNumber(i.dt2)
                    }})</span>
                </span>
              </div>
            </div>

          </div>
        </div>
      </div>

      <div class="col-12 mb-4">
        <div class="card h-100 mt-3">
          <div class="p-3 pb-0 card-header">
            <div class="d-flex justify-content-between align-items-center">
              <h5 class="mb-0">
                <b>{{ $t("content.amndue") }}</b>
              </h5>
            </div>
          </div>
          <div class="p-3 mt-2 card-body">
            <div v-if="loading" class="row">
              <div v-for="(a, pt3) in legendDue" :key="pt3" class="col-12 text-start mb-3">
                <div class="d-flex justify-content-between align-items-center">
                  <p class="mb-1">{{ a.hd }}</p>
                  <div class="d-flex align-items-center">
                    <h4 class="mb-0">
                      <b><span :class="a.tx">{{ fmNumber(a.vs) }}</span></b>
                    </h4>
                    &nbsp;
                    <p class="text-sm mb-0">/{{ fmNumber(a.vm) }}</p>
                  </div>
                </div>

                <div class="progress dashboard">
                  <div v-if="a.vs !== 0 && a.vm !== 0" class="progress-bar" :class="a.bg" role="progressbar"
                    :style="'width: ' + calPercent(a.vs, a.vm) + '%;'" :aria-valuenow="calPercent(a.vs, a.vm)"
                    aria-valuemin="0" aria-valuemax="100">
                    {{ Number(calPercent(a.vs, a.vm)).toLocaleString() }}%
                  </div>
                  <div v-else class="progress-bar" :class="a.bg" role="progressbar" :style="'width: ' + 2 + '%;'"
                    :aria-valuenow="0" aria-valuemin="0" aria-valuemax="100">
                    0%
                  </div>
                </div>
              </div>
            </div>


            <!-- <div v-if="selectInt == 'รายวัน'" class="row">
              <div v-for="(a, pt3) in dueDay" :key="pt3" class="col-12 text-start mb-3">
                <div class="d-flex justify-content-between align-items-center">
                  <p class="mb-1">{{ a.hd }}</p>
                  <div class="d-flex align-items-center">
                    <h4 class="mb-0">
                      <b><span :class="a.tx">{{ fmNumber(a.vs) }}</span></b>
                    </h4>
                    &nbsp;
                    <p class="text-sm mb-0">/{{ fmNumber(a.vm) }}</p>
                  </div>
                </div>

                <div class="progress dashboard">
                  <div class="progress-bar" :class="a.bg" role="progressbar"
                    :style="'width: ' + calPercent(a.vs, a.vm) + '%;'" :aria-valuenow="calPercent(a.vs, a.vm)"
                    aria-valuemin="0" aria-valuemax="100">
                    {{ Number(calPercent(a.vs, a.vm)).toLocaleString() }}%
                  </div>
                </div>
              </div>
            </div> -->

            <!-- <div v-else-if="selectInt == 'รายอาทิตย์'" class="row">
              <div v-for="(a, pt4) in dueWeek" :key="pt4" class="col-12 text-start mb-3">
                <div class="d-flex justify-content-between align-items-center">
                  <p class="mb-1">{{ a.hd }}</p>
                  <div class="d-flex align-items-center">
                    <h4 class="mb-0">
                      <b><span :class="a.tx">{{ fmNumber(a.vs) }}</span></b>
                    </h4>
                    &nbsp;
                    <p class="text-sm mb-0">/{{ fmNumber(a.vm) }}</p>
                  </div>
                </div>

                <div class="progress dashboard">
                  <div class="progress-bar" :class="a.bg" role="progressbar"
                    :style="'width: ' + calPercent(a.vs, a.vm) + '%;'" :aria-valuenow="calPercent(a.vs, a.vm)"
                    aria-valuemin="0" aria-valuemax="100">
                    {{ Number(calPercent(a.vs, a.vm)).toLocaleString() }}%
                  </div>
                </div>
              </div>
            </div> -->

            <!-- <div v-else-if="selectInt == 'รายเดือน'" class="row">
              <div v-for="(a, pt5) in dueMonth" :key="pt5" class="col-12 text-start mb-3">
                <div class="d-flex justify-content-between align-items-center mb-1">
                  <p class="mb-1">{{ a.hd }}</p>
                  <div class="d-flex align-items-center">
                    <h4 class="mb-0">
                      <b><span :class="a.tx">{{ fmNumber(a.vs) }}</span></b>
                    </h4>
                    &nbsp;
                    <p class="text-sm mb-0">/{{ fmNumber(a.vm) }}</p>
                  </div>
                </div>

                <div class="progress dashboard">
                  <div class="progress-bar" :class="a.bg" role="progressbar"
                    :style="'width: ' + calPercent(a.vs, a.vm) + '%;'" :aria-valuenow="calPercent(a.vs, a.vm)"
                    aria-valuemin="0" aria-valuemax="100">
                    {{ Number(calPercent(a.vs, a.vm)).toLocaleString() }}%
                  </div>
                </div>
              </div>
            </div> -->

            <div class="row mt-4" style="overflow-x: auto; padding-right: 1em">
              <h5 class="">{{ $t("content.amnticketduelist") }}</h5>
              <div class="table-responsive">
                <table class="table">
                  <thead class="thead-light">
                    <tr>
                      <th>#</th>
                      <th>{{ $t("content.amnduedate") }}</th>
                      <th>{{ $t("content.names") }}</th>
                      <th>{{ $t("content.ticketno") }}</th>
                      <th>{{ $t("content.allweight") }}</th>
                      <th>{{ $t("content.allqty") }}</th>
                      <th>{{ $t("content.allprice") }}</th>
                      <th>{{ $t("content.amninterest") }}</th>
                      <th>{{ $t("content.cmobile") }}</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(row, index) in duelist" :key="index">
                      <td class="text-sm font-weight-normal">{{ formatNumberString((currentPage - 1) * perPage + index
                        +
                        1) }}</td>
                      <td class="text-sm font-weight-normal">{{ format_datetime(row.createDate) }}</td>
                      <td class="text-sm font-weight-normal">{{ row.customerName }}</td>
                      <td class="text-sm font-weight-normal">{{ row.refNumber }}</td>
                    </tr>
                    <tr v-if="duelist.length === 0">
                      <td colspan="11" class="text-sm font-weight-normal">
                        <p style="text-align: center">No Record Found</p>
                      </td>
                    </tr>

                    <!-- <tr v-for="(i, history) in datatable" :key="history">
                      <td class="text-sm font-weight-normal">{{ i.no }}</td>
                      <td class="text-sm font-weight-normal">{{ i.dat }}</td>
                      <td class="text-sm font-weight-normal">{{ i.nam }}</td>
                      <td class="text-sm font-weight-normal">{{ i.new }}</td>
                      <td class="text-sm font-weight-normal">{{ i.wei }}</td>
                      <td class="text-sm font-weight-normal">{{ i.amo }}</td>
                      <td class="text-sm font-weight-normal">{{ i.amo }}</td>
                      <td class="text-sm font-weight-normal">{{ i.int }}</td>
                      <td class="text-sm font-weight-normal">{{ i.mob }}</td>
                    </tr> -->
                  </tbody>
                  <tfoot>
                    <tr>
                      <td colspan="14" style="text-align: right">Total Rows : {{ totalRows_s }}</td>
                    </tr>
                  </tfoot>
                </table>
              </div>
            </div>
            <pre></pre>
            <div style="display: flex; justify-content: flex-end">
              <ul v-show="duelist.length > 0" class="pagination justify-content-end">
                <div class="project-container">
                  <div class="example-one">
                    <vue-awesome-paginate v-model="currentPage" :total-items="totalRows" :items-per-page="perPage"
                      @click="onClickHandler" />
                  </div>
                </div>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <!-- <div class="col-12 mb-4">
        <div class="card h-100 mt-3">
          <div class="p-3 pb-0 card-header">
            <div class="d-flex justify-content-between align-items-center">
              <h5 class="mb-0">
                <b>{{ $t("content.gmoney") }}</b>
              </h5>
            </div>
          </div>
          <div class="p-3 mt-2 card-body">
            <div class="row mb-5">
              <div class="col-12">
                <ul id="dashboardTab" class="nav nav-tabs" role="tablist">
                  <li class="nav-item" role="presentation">
                    <button id="first-dashboard" class="nav-link active" data-bs-toggle="tab"
                      data-bs-target="#firstDashboard" type="button" role="tab" aria-controls="firstDashboard"
                      aria-selected="true">
                      {{ $t("content.amncash") }}
                    </button>
                  </li>

                  <li class="nav-item" role="presentation">
                    <button id="second-dashboard" class="nav-link" data-bs-toggle="tab"
                      data-bs-target="#secondDashboard" type="button" role="tab" aria-controls="secondDashboard"
                      aria-selected="false">
                      {{ $t("content.amnbankacc") }}
                    </button>
                  </li>
                </ul>

                <div id="dashboardTabContent" class="tab-content">
                  <div id="firstDashboard" class="tab-pane fade show active" role="tabpanel"
                    aria-labelledby="first-dashboard">
                    <div class="row mt-3">
                      <div v-for="(a, pt6) in drawers" :key="pt6" class="col-12 text-start mb-3">
                        <div class="d-flex justify-content-between align-items-center mb-1">
                          <div class="d-flex align-items-center">
                            <material-radio :id="a.id" v-model="selectDrawer" name="selectedDrawer"
                              class="radio-horizon m-0 p-0" :value="a.vl">
                              {{ a.hd }} {{ pt6 + 1 }}
                            </material-radio>
                            <span class="ms-1 mt-1 fs-14" :class="a.sc">({{ $t("content.amnbring") }} : {{
                              fmNumber(a.bf) }})
                            </span>
                          </div>
                          <div class="d-flex align-items-center">
                            <span class="mt-1 fs-14">
                              {{ $t("content.amnincome") }} :
                            </span>
                            <span class="ms-1 mt-1 fs-14" :class="a.tx">
                              {{ fmNumber(a.vs) }}
                            </span>
                            &nbsp;
                            <span class="ms-1 mt-1 fs-14">
                              / {{ $t("content.amnoutcome") }} :
                            </span>
                            <span class="ms-1 mt-1 fs-14 text-danger">
                              {{ fmNumber(a.vm) }}
                            </span>
                            <material-button color="primary" variant="outline"
                              class="btn btn-icon-only btn-rounded btn-outline-secondary ms-2 d-flex align-items-center justify-content-center"
                              data-bs-toggle="tooltip" data-bs-placement="top" :title="$t('content.file')"
                              @click="onLookupDetail">
                              <i class="material-icons material-symbols-outlined">attach_file</i>
                            </material-button>
                          </div>
                        </div>

                        <div class="progress dashboard">
                          <div class="progress-bar" :class="a.bg" role="progressbar"
                            :style="'width: ' + calPercent(a.vs, a.vm) + '%;'" :aria-valuenow="calPercent(a.vs, a.vm)"
                            aria-valuemin="0" aria-valuemax="100">
                            {{ Number(calPercent(a.vs, a.vm)).toLocaleString() }}%
                          </div>
                        </div>

                        <div class="d-flex justify-content-end align-items-center mb-1">
                          <span class="mt-1 fs-14"> {{ $t("content.total") }} : </span>
                          <span class="ms-1 mt-1 fs-16" :class="a.tx">
                            {{ fmNumber(a.in) }}
                          </span>
                          <span class="ms-1 mt-1 fs-16"> / {{ fmNumber(a.ou) }}</span>
                        </div>
                      </div>
                    </div>

                    <div v-if="
                      selectDrawer == 'drawer1' ||
                      selectDrawer == 'drawer2' ||
                      selectDrawer == 'drawer3'
                    " class="row mt-3 p-3">
                      <h5 class="mb-3">
                        <u>{{ $t("content.amndrawer") }}</u>
                      </h5>
                      <div v-for="(a, pt8) in detailDrawer" :key="pt8" class="col-12 text-start mb-1">
                        <div class="d-flex justify-content-between align-items-center border-top">
                          <p class="mb-1">{{ a.hd }} ({{ a.cr }})</p>
                          <div class="d-flex align-items-center">
                            <h4 class="mb-0">
                              <b><span :class="a.tx">{{ fmNumber(a.vs) }}</span></b>
                            </h4>
                            &nbsp;
                            <p class="text-sm mb-0">/{{ fmNumber(a.vm) }}</p>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div v-else-if="selectDrawer == 'safe1'" class="row mt-3 p-3">
                      <h5 class="mb-3">
                        <u>{{ $t("content.amnsafe") }}</u>
                      </h5>
                      <div v-for="(a, pt9) in detailSafe" :key="pt9" class="col-12 text-start mb-1">
                        <div class="d-flex justify-content-between align-items-center border-top">
                          <p class="mb-1">{{ a.hd }} ({{ a.cr }})</p>
                          <div class="d-flex align-items-center">
                            <h4 class="mb-0">
                              <b><span :class="a.tx">{{ fmNumber(a.vs) }}</span></b>
                            </h4>
                            &nbsp;
                            <p class="text-sm mb-0">/{{ fmNumber(a.vm) }}</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div id="secondDashboard" class="tab-pane fade" role="tabpanel" aria-labelledby="second-dashboard">
                    <div class="row mt-3">
                      <div v-for="(a, pt7) in banks" :key="pt7" class="col-12 text-start mb-3">
                        <div class="d-flex justify-content-between align-items-center mb-1">
                          <div class="d-flex align-items-center">
                            <span class="mt-1 fs-14">{{ a.hd }} {{ pt7 + 1 }}</span>
                            <span class="ms-1 mt-1 fs-14" :class="a.sc">({{ $t("content.amnbring") }} : {{
                              fmNumber(a.bf) }})
                            </span>
                          </div>
                          <div class="d-flex align-items-center">
                            <span class="mt-1 fs-14">
                              {{ $t("content.amnincome") }} :
                            </span>
                            <span class="ms-1 mt-1 fs-14" :class="a.tx">
                              {{ fmNumber(a.vs) }}
                            </span>
                            &nbsp;
                            <span class="ms-1 mt-1 fs-14">
                              / {{ $t("content.amnoutcome") }} :
                            </span>
                            <span class="ms-1 mt-1 fs-14 text-danger">
                              {{ fmNumber(a.vm) }}
                            </span>
                            <material-button color="primary" variant="outline"
                              class="btn btn-icon-only btn-rounded btn-outline-secondary ms-2 d-flex align-items-center justify-content-center"
                              data-bs-toggle="tooltip" data-bs-placement="top" :title="$t('content.file')"
                              @click="onLookupDetail">
                              <i class="material-icons material-symbols-outlined">attach_file</i>
                            </material-button>
                          </div>
                        </div>

                        <div class="progress dashboard">
                          <div class="progress-bar" :class="a.bg" role="progressbar"
                            :style="'width: ' + calPercent(a.vs, a.vm) + '%;'" :aria-valuenow="calPercent(a.vs, a.vm)"
                            aria-valuemin="0" aria-valuemax="100">
                            {{ Number(calPercent(a.vs, a.vm)).toLocaleString() }}%
                          </div>
                        </div>

                        <div class="d-flex justify-content-end align-items-center mb-1">
                          <span class="mt-1 fs-14"> {{ $t("content.total") }} : </span>
                          <span class="ms-1 mt-1 fs-16" :class="a.tx">
                            {{ fmNumber(a.in) }}
                          </span>
                          <span class="ms-1 mt-1 fs-16"> / {{ fmNumber(a.ou) }}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> -->

    </div>
  </div>
</template>

<script setup>
/* eslint-disable no-unused-vars */
import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css'
import { ref, reactive, computed, inject } from "vue";
import { useI18n } from "vue-i18n";
import apexchart from "vue3-apexcharts";
import MaterialButton from "@/components/MaterialButton.vue";
import MaterialRadioGroup from "@/components/MaterialRadioGroup.vue";
import MaterialRadio from "@/components/MaterialRadio.vue";

import PieChart from "@/examples/Charts/PieChart.vue";
import moment from 'moment'

import { useDashboardStore } from '@/stores/dashboard'
import { useCashAccountStore } from '@/stores/cashaccount'
import { useWorkItemStore } from '@/stores/workItem'


const storedash = useDashboardStore()
const storeca = useCashAccountStore()
const storew = useWorkItemStore()

///// variables
const { t } = useI18n();
const swal = inject("$swal");

const start_date = ref(new Date());
const end_date = ref(new Date());
const sum_pvalue = ref(0);
const sum_mvalue = ref(0);
const sum_painvalue = ref(0);
const sum_inderevalue = ref(0);
const sum_intvalue = ref(0);
const sum_pawncase = ref(0);
const sum_pawnvalue = ref(0);
const sum_redeemcase = ref(0);
const sum_redeemvalue = ref(0);
const num_q01 = ref(0);
const num_q02 = ref(0);
const num_q03 = ref(0);
const num_q04 = ref(0);
const num_pay = ref(0);

const dataInfo = ref({})
const dataDue = ref({})
const duelist = ref([])
const legendDue = ref([]);
const allqueuelist = ref([])
const allpaymentlist = ref([])

const currentPage = ref(1);
const totalRows = ref(1);
const perPage = ref(10);
const totalRows_s = ref();

///// data
const selectInt = ref("รายวัน");
const selectDrawer = ref("drawer1");

const react = reactive({
  byday: 88,
  byweek: 888,
  bymonth: 8888,
  daydone: 102,
  weekdone: 432,
  monthdone: 1543,
  daybaht: 888888.88,
  weekbaht: 8888888.88,
  monthbaht: 88888888.88,
});

const colors = reactive(["#809bce", "#95b8d1", "#b8e0d2", "#d6eadf", "#eac4d5"]);
const colors2 = reactive(["#dfffdd", "#fecac7", "#b8e0d2"]);

const loading = ref(false)

const legendTimes = ref([]);

const legendBaht = ref([]);

const legendSum = ref([]);

const legendMig = ref([]);

const shop_detail = ref({})

const ylabels = reactive([
  t("content.amnincome"),
  t("content.amnoutcome"),
  t("content.amninterest"),
]);

const barColors = reactive([
  "#809bce",
  "#b8e0d2",
  "#d6eadf",
  "#eac4d5",
  "#977ECD",
  "#D0AE95",
  "#B8CCE0",
]);
const bars = reactive({
  series: [
    {
      name: computed(() => t("content.actpawn1")),
      data: [0, 26, 0],
    },
    {
      name: computed(() => t("content.actpawn3")),
      data: [0, 16, 0],
    },
    {
      name: computed(() => t("content.actpawn4")),
      data: [12, 0, 0],
    },
    {
      name: computed(() => t("content.actpawn5")),
      data: [9, 0, 0],
    },
    {
      name: computed(() => t("content.amndeposit")),
      data: [25, 0, 0],
    },
    {
      name: computed(() => t("content.amnwithdraw")),
      data: [0, 10, 0],
    },
    {
      name: computed(() => t("content.amninterest")),
      data: [25, 0, 19],
    },
  ],
  chartOptions: {
    chart: {
      type: "bar",
      height: 200,
      stacked: true,
    },
    colors: barColors,
    plotOptions: {
      bar: {
        horizontal: true,
        dataLabels: {
          total: {
            enabled: true,
            offsetX: 0,
            style: {
              fontSize: "14px",
              fontWeight: 900,
              colors: barColors,
            },
          },
        },
      },
    },
    stroke: {
      width: 1,
      colors: ["#fff"],
    },
    xaxis: {
      categories: ylabels,
      labels: {
        formatter: function (val) {
          return val + "m";
        },
      },
    },
    yaxis: {
      type: "String",
      title: {
        text: undefined,
      },
    },
    tooltip: {
      y: {
        formatter: function (val) {
          return val + "m";
        },
      },
    },
    fill: {
      opacity: 1,
      colors: barColors,
    },
    legend: {
      position: "top",
      horizontalAlign: "center",
      offsetX: 40,
      fontSize: "14px",
    },
  },
});

const dueDay = reactive([
  {
    hd: computed(() => t("content.amnticketdueactived")),
    vs: 21,
    vm: 24,
    tx: "text-primary",
    bg: "bg-primary",
  },
  {
    hd: computed(() => t("content.amnticketdued")),
    vs: 3,
    vm: 24,
    tx: "text-warning",
    bg: "bg-warning",
  },
  {
    hd: computed(() => t("content.amnticketoveractived")),
    vs: 4,
    vm: 20,
    tx: "text-danger",
    bg: "bg-danger",
  },
  {
    hd: computed(() => t("content.amnticketoverd")),
    vs: 244,
    vm: 786,
    tx: "text-secondary",
    bg: "bg-secondary",
  },
]);

const dueWeek = reactive([
  {
    hd: computed(() => t("content.amnticketdueactivew")),
    vs: 144,
    vm: 167,
    tx: "text-primary",
    bg: "bg-primary",
  },
  {
    hd: computed(() => t("content.amnticketduew")),
    vs: 23,
    vm: 167,
    tx: "text-warning",
    bg: "bg-warning",
  },
  {
    hd: computed(() => t("content.amnticketoveractivew")),
    vs: 42,
    vm: 45,
    tx: "text-danger",
    bg: "bg-danger",
  },
  {
    hd: computed(() => t("content.amnticketoverw")),
    vs: 244,
    vm: 786,
    tx: "text-secondary",
    bg: "bg-secondary",
  },
]);

const dueMonth = reactive([
  {
    hd: computed(() => t("content.amnticketdueactivem")),
    vs: 807,
    vm: 851,
    tx: "text-primary",
    bg: "bg-primary",
  },
  {
    hd: computed(() => t("content.amnticketduem")),
    vs: 44,
    vm: 851,
    tx: "text-warning",
    bg: "bg-warning",
  },
  {
    hd: computed(() => t("content.amnticketoveractivem")),
    vs: 423,
    vm: 425,
    tx: "text-danger",
    bg: "bg-danger",
  },
  {
    hd: computed(() => t("content.amnticketoverm")),
    vs: 879,
    vm: 1456,
    tx: "text-secondary",
    bg: "bg-secondary",
  },
]);

const datatable = reactive([
  {
    no: "1",
    dat: "xx/xx/xxxx xx:xx",
    nam: "xxxxx xxxxxxxxx",
    old: "aaaaa",
    new: "bbbbb",
    biz: "จำนำ",
    ass: "ทองคำ",
    wei: "30.32",
    qty: "5",
    amo: "88,888,888.88",
    int: "8,888.88",
    by: "หลงจู๊ A",
    mob: "xxx-xxx-xxxx",
  },
  {
    no: "2",
    dat: "xx/xx/xxxx xx:xx",
    nam: "xxxxx xxxxxxxxx",
    old: "aaaaa",
    new: "bbbbb",
    biz: "ต่อดอก",
    ass: "เพชร",
    wei: "12.34",
    qty: "2",
    amo: "88,888,888.88",
    int: "8,888.88",
    by: "หลงจู๊ B",
    mob: "xxx-xxx-xxxx",
  },
  {
    no: "3",
    dat: "xx/xx/xxxx xx:xx",
    nam: "xxxxx xxxxxxxxx",
    old: "aaaaa",
    new: "bbbbb",
    biz: "จำนำ",
    ass: "อื่นๆ",
    wei: "30.32",
    qty: "5",
    amo: "88,888,888.88",
    int: "8,888.88",
    by: "หลงจู๊ B",
    mob: "xxx-xxx-xxxx",
  },
  {
    no: "4",
    dat: "xx/xx/xxxx xx:xx",
    nam: "xxxxx xxxxxxxxx",
    old: "aaaaa",
    new: "bbbbb",
    biz: "ไถ่ถอน",
    ass: "ทองคำ",
    wei: "15.63",
    qty: "1",
    amo: "88,888,888.88",
    int: "8,888.88",
    by: "หลงจู๊ A",
    mob: "xxx-xxx-xxxx",
  },
]);

const drawers = reactive([
  {
    hd: computed(() => t("content.amndrawer")),
    id: "sDrawer1",
    vl: "drawer1",
    sc: "text-danger",
    bf: 500000.0,
    vs: 500000.0,
    vm: 2000000.0,
    tx: "text-primary",
    bg: "bg-primary",
    in: 400000.0,
    ou: 1100000.0,
  },
  {
    hd: computed(() => t("content.amndrawer")),
    id: "sDrawer2",
    vl: "drawer2",
    sc: "text-danger",
    bf: 500000.0,
    vs: 700000.0,
    vm: 2000000.0,
    tx: "text-primary",
    bg: "bg-primary",
    in: 400000.0,
    ou: 1100000.0,
  },
  {
    hd: computed(() => t("content.amndrawer")),
    id: "sDrawer3",
    vl: "drawer3",
    sc: "text-danger",
    bf: 500000.0,
    vs: 1700000.0,
    vm: 2000000.0,
    tx: "text-primary",
    bg: "bg-primary",
    in: 400000.0,
    ou: 1100000.0,
  },
  {
    hd: computed(() => t("content.amnsafe")),
    id: "sSafe1",
    vl: "safe1",
    sc: "text-danger",
    bf: 500000.0,
    vs: 6000000.0,
    vm: 24000000.0,
    tx: "text-info",
    bg: "bg-info",
    in: 400000.0,
    ou: 1100000.0,
  },
]);

const banks = reactive([
  {
    hd: computed(() => t("content.amnbankacc")),
    sc: "text-danger",
    bf: 500000.0,
    vs: 1500000.0,
    vm: 2000000.0,
    tx: "text-warning",
    bg: "bg-warning",
    in: 400000.0,
    ou: 1100000.0,
  },
  {
    hd: computed(() => t("content.amnbankacc")),
    sc: "text-danger",
    bf: 500000.0,
    vs: 1700000.0,
    vm: 2000000.0,
    tx: "text-warning",
    bg: "bg-warning",
    in: 400000.0,
    ou: 1100000.0,
  },
  {
    hd: computed(() => t("content.amnbankacc")),
    sc: "text-danger",
    bf: 500000.0,
    vs: 900000.0,
    vm: 2000000.0,
    tx: "text-warning",
    bg: "bg-warning",
    in: 400000.0,
    ou: 1100000.0,
  },
]);

const detailDrawer = reactive([
  {
    hd: "1000",
    cr: computed(() => t("content.curency")),
    vs: 14,
    vm: 1000,
    tx: "text-primary",
  },
  {
    hd: "500",
    cr: computed(() => t("content.curency")),
    vs: 14,
    vm: 1000,
    tx: "text-primary",
  },
  {
    hd: "100",
    cr: computed(() => t("content.curency")),
    vs: 14,
    vm: 1000,
    tx: "text-primary",
  },
  {
    hd: "50",
    cr: computed(() => t("content.curency")),
    vs: 14,
    vm: 1000,
    tx: "text-primary",
  },
  {
    hd: "20",
    cr: computed(() => t("content.curency")),
    vs: 14,
    vm: 1000,
    tx: "text-primary",
  },
  {
    hd: "10",
    cr: computed(() => t("content.curency")),
    vs: 14,
    vm: 1000,
    tx: "text-primary",
  },
  {
    hd: "5",
    cr: computed(() => t("content.curency")),
    vs: 14,
    vm: 1000,
    tx: "text-primary",
  },
  {
    hd: "1",
    cr: computed(() => t("content.curency")),
    vs: 14,
    vm: 1000,
    tx: "text-primary",
  },
  {
    hd: computed(() => t("content.amntotaltransact")),
    cr: computed(() => t("content.amntotbaht")),
    vs: 1400000,
    vm: 2000000,
    tx: "text-primary",
  },
]);

const detailSafe = reactive([
  {
    hd: "1000",
    cr: computed(() => t("content.curency")),
    vs: 14,
    vm: 1000,
    tx: "text-info",
  },
  {
    hd: "500",
    cr: computed(() => t("content.curency")),
    vs: 14,
    vm: 1000,
    tx: "text-info",
  },
  {
    hd: "100",
    cr: computed(() => t("content.curency")),
    vs: 14,
    vm: 1000,
    tx: "text-info",
  },
  {
    hd: "50",
    cr: computed(() => t("content.curency")),
    vs: 14,
    vm: 1000,
    tx: "text-info",
  },
  {
    hd: "20",
    cr: computed(() => t("content.curency")),
    vs: 14,
    vm: 1000,
    tx: "text-info",
  },
  {
    hd: "10",
    cr: computed(() => t("content.curency")),
    vs: 14,
    vm: 1000,
    tx: "text-info",
  },
  {
    hd: "5",
    cr: computed(() => t("content.curency")),
    vs: 14,
    vm: 1000,
    tx: "text-info",
  },
  {
    hd: "1",
    cr: computed(() => t("content.curency")),
    vs: 14,
    vm: 1000,
    tx: "text-info",
  },
  {
    hd: computed(() => t("content.amntotaltransact")),
    cr: computed(() => t("content.amntotbaht")),
    vs: 1800000,
    vm: 2000000,
    tx: "text-info",
  },
]);

///// computed
const plegde = computed(() => t("content.actpawn1"));
const interest = computed(() => t("content.actpawn2"));
const addcap = computed(() => t("content.actpawn3"));
const reducecap = computed(() => t("content.actpawn4"));
const redeem = computed(() => t("content.actpawn5"));
const cancel = computed(() => t("buttons.canc"));
const search = computed(() => t("buttons.sear"));
const sinterest = computed(() => t("content.amninterest"));
const sincome = computed(() => t("content.amnincome"));
const soutcome = computed(() => t("content.amnoutcome"));

///// methods
const calPercent = (val, full) => {
  return (parseInt(val) * 100) / parseInt(full);
};

const fmNumber = (val) => {
  return Intl.NumberFormat("en-US").format(val);
};

const format = (date) => {
  return moment(String(date)).local().format('DD/MM/YYYY');
}

const textInputOptions = {
  format: 'dd/MM/yyyy'
};

const onClickHandler = (page) => {
  updatePagination()
};

const getshopInfo = async (val) => {
  shop_detail.value = {}
  const response = await storeca.cashaccountInfoFromSessionFetch();
  if (response) {
    if (response.status === 200) {
      if (response.data.rtnDesc === "success") {
        // console.log("shop_detail : " + JSON.stringify(response.data.data));
        shop_detail.value = response.data.data
        loadDataInfoApi()
      }
    }
  }
}

getshopInfo()

const loadDataInfoApi = async () => {
  sum_pvalue.value = 0;
  sum_mvalue.value = 0;
  sum_painvalue.value = 0;
  sum_inderevalue.value = 0;
  sum_intvalue.value = 0;
  sum_pawncase.value = 0;
  sum_pawnvalue.value = 0;
  sum_redeemcase.value = 0;
  sum_redeemvalue.value = 0;

  loading.value = false
  let data = "";
  data = {
    fromDate: moment(new Date(start_date.value)).format('YYYY-MM-DDT00:00:00'),
    toDate: moment(new Date(end_date.value)).format('YYYY-MM-DDT23:59:59'),
    shopUid: shop_detail.value.uid
  };
  const payload = data;
  const response = await storedash.dashboardInfoFetch(payload);
  // console.log('dataInfo : ' + JSON.stringify(response.data))
  if (response) {
    if (response.status === 200) {
      if (response.data.rtnDesc === "success") {
        dataInfo.value = response.data.data;
        await setnumOfTransaction()
        await setnumOfAmount()
        await setnumOfSum()
        await setnumOfMig()
        await loadDataDueApi()
        await loadDueListApi()
        await loadAllQueueApi()
        await loadPaymentAllApi()
        sum_pvalue.value = dataInfo.value.pawnValue + dataInfo.value.interestPawnValue + dataInfo.value.incPawnValue
          + dataInfo.value.decPawnValue + dataInfo.value.redeemPawnValue;
        sum_mvalue.value = dataInfo.value.interestPawnValueMigration + dataInfo.value.incPawnValueMigration
          + dataInfo.value.decPawnValueMigration;
        sum_painvalue.value = dataInfo.value.pawnValue + dataInfo.value.incPawnValue
        sum_inderevalue.value = dataInfo.value.interestPawnValue + dataInfo.value.decPawnValue + dataInfo.value.redeemPawnValue;
        sum_intvalue.value = dataInfo.value.interestAmount + dataInfo.value.incInterestAmount + dataInfo.value.decInterestAmount
          + dataInfo.value.redeemInterestAmount;
        sum_pawncase.value = Number(dataInfo.value.numOfPawn) + Number(dataInfo.value.numOfInterest) + Number(dataInfo.value.numOfInc) + Number(dataInfo.value.numOfDec)
          + Number(dataInfo.value.numOfInterestMigration) + Number(dataInfo.value.numOfIncMigration) + Number(dataInfo.value.numOfDecMigration);
        sum_pawnvalue.value = dataInfo.value.pawnValue + dataInfo.value.interestPawnValue + dataInfo.value.incPawnValue
          + dataInfo.value.decPawnValue + sum_mvalue.value;
        sum_redeemcase.value = Number(dataInfo.value.numOfInterest) + Number(dataInfo.value.numOfInc) + Number(dataInfo.value.numOfDec) + Number(dataInfo.value.numOfRedeem);
        sum_redeemvalue.value = dataInfo.value.interestPawnValue + (dataInfo.value.incPawnValue - dataInfo.value.incDiff)
          + (dataInfo.value.decPawnValue + dataInfo.value.decDiff) + dataInfo.value.redeemPawnValue;

        // loading.value = true
        // console.log('dataInfo : ' + JSON.stringify(dataInfo.value))
      }
    }
  }
}

const loadDataDueApi = async () => {
  let data = "";
  data = {
    lastOpenDate: moment(new Date(shop_detail.value.lastOpenDate)).format('YYYY-MM-DDT00:00:00'),
    dueDate: moment(new Date()).format('YYYY-MM-DDT00:00:00'),
    shopUid: shop_detail.value.uid
  };
  const payload = data;
  const response = await storedash.dashboardDueFetch(payload);
  // console.log('loadDataDueApi : ' + JSON.stringify(response.data))
  if (response) {
    if (response.status === 200) {
      if (response.data.rtnDesc === "success") {
        dataDue.value = response.data.data;
        await setseriesDue()

        // loading.value = true
        // console.log('dataInfo : ' + JSON.stringify(dataInfo.value))
      }
    }
  }

}

const loadDueListApi = async () => {
  let data = "";
  data = {
    index: currentPage.value - 1,
    size: perPage.value,
    lastOpenDate: moment(new Date(shop_detail.value.lastOpenDate)).format('YYYY-MM-DDT00:00:00'),
    shopUid: shop_detail.value.uid
  };
  const payload = data;
  const response = await storedash.overdueListFetch(payload);
  // console.log('loadDueListApi : ' + JSON.stringify(response.data))
  if (response) {
    if (response.status === 200) {
      if (response.data.rtnDesc === "success") {
        duelist.value = response.data.data.list;
        totalRows.value = response.data.data.ext.total
        totalRows_s.value = formatNumberString(totalRows.value)
        loading.value = true
        // console.log('dataInfo : ' + JSON.stringify(dataInfo.value))
      }
    }
  }
}

async function updatePagination() {
  duelist.value = []
  let data = "";
  data = {
    index: currentPage.value - 1,
    size: perPage.value,
    lastOpenDate: moment(new Date(shop_detail.value.lastOpenDate)).format('YYYY-MM-DDT00:00:00'),
    shopUid: shop_detail.value.uid
  };
  const payload = data;
  const response = await storedash.overdueListFetch(payload);
  // console.log('loadDueListApi : ' + JSON.stringify(response.data))
  if (response) {
    if (response.status === 200) {
      if (response.data.rtnDesc === "success") {
        duelist.value = response.data.data.list;
        totalRows.value = response.data.data.ext.total
        totalRows_s.value = formatNumberString(totalRows.value)
        // console.log('dataInfo : ' + JSON.stringify(dataInfo.value))
      }
    }
  }
}


const loadAllQueueApi = async () => {
  num_q01.value = 0
  num_q02.value = 0
  num_q03.value = 0
  num_q04.value = 0

  let data = "";
  data = {
    index: '0',
    size: '100',
  };
  const payload = data;
  const response = await storew.listAllQueueFetch(payload);
  // console.log('loadAllQueueApi : ' + JSON.stringify(response.data))
  if (response) {
    if (response.status === 200) {
      if (response.data.rtnDesc === "success") {
        allqueuelist.value = response.data.data.list;
        let q1 = []
        q1 = allqueuelist.value.filter((c) => c.queue.code === 'Q01');
        num_q01.value = q1.length
        let q2 = []
        q2 = allqueuelist.value.filter((c) => c.queue.code === 'Q02');
        num_q02.value = q2.length
        let q3 = []
        q3 = allqueuelist.value.filter((c) => c.queue.code === 'Q03');
        num_q03.value = q3.length
        let q4 = []
        q4 = allqueuelist.value.filter((c) => c.queue.code === 'Q04');
        num_q04.value = q4.length
      }
    }
  }

}


const loadPaymentAllApi = async () => {
  num_pay.value = 0
  let data = "";
  data = {
    index: '0',
    size: '100',
  };
  const payload = data;
  const response = await storew.listPaymentAllFetch(payload);
  // console.log('loadPaymentAllApi : ' + JSON.stringify(response.data))
  if (response) {
    if (response.status === 200) {
      if (response.data.rtnDesc === "success") {
        allpaymentlist.value = response.data.data.list;
        num_pay.value = response.data.data.ext.total
      }
    }
  }

}


function cancelBtn() {
  start_date.value = new Date();
  end_date.value = new Date();
  dataInfo.value = {}
}

function monthDiff() {
  let pro = false
  var months;
  months = (end_date.value.getFullYear() - start_date.value.getFullYear()) * 12;
  months += end_date.value.getMonth() - start_date.value.getMonth();

  if (months > 3) {
    pro = true
  }
  return pro
}

async function setnumOfTransaction() {
  legendTimes.value = [
    {
      bg: "bg-pledge",
      hd: computed(() => t("content.actpawn1")),
      dt: Number(dataInfo.value.numOfPawn),
    },
    {
      bg: "bg-interest",
      hd: computed(() => t("content.actpawn2")),
      dt: Number(dataInfo.value.numOfInterest),
    },
    {
      bg: "bg-add-cap",
      hd: computed(() => t("content.actpawn3")),
      dt: Number(dataInfo.value.numOfInc),
    },
    {
      bg: "bg-reduce-cap",
      hd: computed(() => t("content.actpawn4")),
      dt: Number(dataInfo.value.numOfDec),
    },
    {
      bg: "bg-redeem",
      hd: computed(() => t("content.actpawn5")),
      dt: Number(dataInfo.value.numOfRedeem),
    },
  ];


}

async function setnumOfAmount() {
  legendBaht.value = [
    {
      bg: "bg-pledge",
      hd: computed(() => t("content.actpawn1")),
      dt: Number(dataInfo.value.pawnAmount)
    },
    {
      bg: "bg-interest",
      hd: computed(() => t("content.actpawn2")),
      dt: Number(dataInfo.value.interestAmount),
    },
    {
      bg: "bg-add-cap",
      hd: computed(() => t("content.actpawn3")),
      dt: Number(dataInfo.value.incAmount),
    },
    {
      bg: "bg-reduce-cap",
      hd: computed(() => t("content.actpawn4")),
      dt: Number(dataInfo.value.decAmount),
    },
    {
      bg: "bg-redeem",
      hd: computed(() => t("content.actpawn5")),
      dt: Number(dataInfo.value.redeemAmount),
    },
  ];
}

async function setnumOfSum() {

  legendSum.value = [
    {
      bg: "bg-payment",
      hd: computed(() => t("content.amnoutcome")),
      dt: Number(dataInfo.value.pawnAmount) + Number(dataInfo.value.incAmount)
    },
    {
      bg: "bg-receive",
      hd: computed(() => t("content.amnincome")),
      dt: Number(dataInfo.value.decAmount) + Number(dataInfo.value.redeemAmount) + Number(dataInfo.value.interestAmount)
    },
    {
      bg: "bg-interest",
      hd: computed(() => t("content.amninterest")),
      dt: Number(dataInfo.value.sumInterest),
    },

  ];

}

async function setnumOfMig() {

  legendMig.value = [
    {
      bg: "bg-interest",
      hd: computed(() => t("content.actpawn2")),
      dt: Number(dataInfo.value.interestAmountMigration),
      dt2: Number(dataInfo.value.numOfInterestMigration),
    },
    {
      bg: "bg-add-cap",
      hd: computed(() => t("content.actpawn3")),
      dt: Number(dataInfo.value.incAmountMigration),
      dt2: Number(dataInfo.value.numOfIncMigration),
    },
    {
      bg: "bg-reduce-cap",
      hd: computed(() => t("content.actpawn4")),
      dt: Number(dataInfo.value.decAmountMigration),
      dt2: Number(dataInfo.value.numOfDecMigration),
    },

  ];

}

async function setseriesDue() {
  let totaldue = Number(dataDue.value.clearToday) + Number(dataDue.value.notClearToday)
  legendDue.value = [
    {
      hd: computed(() => t("content.amnticketdueactived")),
      vs: Number(dataDue.value.clearToday),
      vm: Number(totaldue),
      tx: "text-primary",
      bg: "bg-primary",
    },
    {
      hd: computed(() => t("content.amnticketdued")),
      vs: Number(dataDue.value.notClearToday),
      vm: Number(totaldue),
      tx: "text-warning",
      bg: "bg-warning",
    },
    {
      hd: computed(() => t("content.amnticketoveractived")),
      vs: Number(dataDue.value.overdue),
      vm: Number(dataDue.value.overdueTotal),
      tx: "text-danger",
      bg: "bg-danger",
    },
    {
      hd: computed(() => t("content.amnticketoverd")),
      vs: Number(dataDue.value.overdueClearToday),
      vm: Number(dataDue.value.overdueCount),
      tx: "text-secondary",
      bg: "bg-secondary",
    },
  ];

}


function formatNumberDecimalString(num) {
  if (num !== null && num !== undefined) {
    num = num.toFixed(2)
  } else {
    num = 0
    num = num.toFixed(2)
  }
  return String(num).replace(
    /(\d)(?=(\d{3})+(?!\d))/g,
    "$1,"
  );
}

function formatDecimalString(num) {
  num = Number(num)
  if (num !== null && num !== undefined) {
    num = num.toFixed(2)
  } else {
    num = 0
    num = num.toFixed(2)
  }
  return String(num).replace(
    /(\d)(?=(\d{3})+(?!\d))/g,
    "$1,"
  );
}

function formatNumberString(num) {
  num = Number(num)
  if (num === null || num === undefined) {
    num = 0
  }
  return String(num).replace(
    /(\d)(?=(\d{3})+(?!\d))/g,
    "$1,"
  );

}

function format_datetime(value) {
  if (value) {
    return moment(String(value)).local().format('DD/MM/YYYY HH:mm')
  }
}



function checkTransaction(str) {
  // PAWN, INTEREST, REDEEM, INC_PRINCIPLE, DEC_PRINCIPLE
  let side = ''
  if (str === 'PAWN') {
    side = 'จำนำ'
  } else if (str === 'INTEREST') {
    side = 'ต่อดอกเบี้ย'
  } else if (str === 'REDEEM') {
    side = 'ไถ่ถอน'
  } else if (str === 'INC_PRINCIPLE') {
    side = 'เพิ่มต้น'
  } else if (str === 'DEC_PRINCIPLE') {
    side = 'ลดต้น'
  }
  return side
}

async function onLookupDetail() {
  swal({
    html:
      '<div class="preview-attached">' +
      '<section class="panel">' +
      '<img src="https://unsplash.it/400/200" alt="1" height="400" width="200">' +
      '<img src="https://unsplash.it/400/200" alt="2" height="400" width="200">' +
      '<img src="https://unsplash.it/400/200" alt="3" height="400" width="200">' +
      '<img src="https://unsplash.it/400/200" alt="4" height="400" width="200">' +
      "</section>" +
      "</div>" +
      '<div class="preview-detail">' +
      '<h6 class="mb">Notice:</h6>' +
      "<p>It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using Content here, content here, making it look like readable English. Many desktop publishing packages and web page editors now use Lorem Ipsum as their default model text, and a search for lorem ipsum will uncover many web sites still in their infancy. Various versions have evolved over the years, sometimes by accident, sometimes on purpose (injected humour and the like).</p>" +
      "</div>",
    allowOutsideClick: true,
    showCloseButton: true,
    showCancelButton: false,
    showConfirmButton: false,
    closeOnConfirm: false,
    focusConfirm: false,
    animation: "slide-from-top",
  });
}
</script>

<style lang="scss" scoped>
.grid-container {
  max-width: 990px;
  display: grid;
  grid-gap: 10px;
  grid-template-columns: 1fr 1fr 1fr 1fr;
}
</style>
