<template>
  <div class="container-fluid">
    <div class="loading-state" v-if="loading_d">
      <ProgressSpinner fill="transparent" aria-label="Loading" />
    </div>
    <div class="row mb-5">
      <div class="col-12">
        <ul id="homeTab" class="nav nav-tabs" role="tablist">
          <li class="nav-item" role="presentation">
            <button id="first-home-tab" class="nav-link" data-bs-toggle="tab" data-bs-target="#firstHome" type="button"
              role="tab" aria-controls="firstHome" aria-selected="true" @click="onClickTab('1')">
              {{ $t("content.amngroupticket") }}
            </button>
          </li>

          <li class="nav-item" role="presentation">
            <button id="second-home-tab" class="nav-link" data-bs-toggle="tab" data-bs-target="#secondHome"
              type="button" role="tab" aria-controls="secondHome" aria-selected="false" @click="onClickTab('2')">
              {{ $t("content.amnalljobs") }}
            </button>
          </li>

          <li id="contact" class="nav-item" role="presentation">
            <button id="third-home-tab" class="nav-link" data-bs-toggle="tab" data-bs-target="#thirdHome" type="button"
              role="tab" aria-controls="thirdHome" aria-selected="false" :disabled="workcaseDetail === null"
              @click="onClickTab('3')">
              {{ $t("content.amnticketmanage") }}
            </button>
          </li>
        </ul>


        <div id="homeTabContent" class="tab-content">
          <div id="firstHome" class="tab-pane fade" role="tabpanel" aria-labelledby="first-home-tab">
            <div class="card mt-2">
              <div class="card-header mb-0">
                <h5>{{ $t("content.amngroupticketsearch") }}</h5>
              </div>

              <div class="card-body pt-0">
                <div class="row mt-2">
                  <div class="col-12 d-flex">
                    <div class="me-3">
                      <material-button color="success" class="float-lg-start float-sm-end mb-3" @click="onOpenQRModal">
                        <i class="material-icons material-symbols-outlined">qr_code_scanners</i>
                        {{ $t("buttons.sscan") }}
                      </material-button>
                      <material-button color="success" class="float-lg-start float-sm-end mb-3" data-bs-toggle="modal"
                        data-bs-target="#qrcodeModal" id="qrModalShow" v-show="false">
                      </material-button>
                    </div>
                    <div class="col-8 me-3">
                      <material-input type="text" v-model="qr_serach" :placeholder="t(`content.amnjobcode`)"
                        @keyup.enter="setResultQRTab1" />
                    </div>
                  </div>
                </div>
                <hr />
                <div class="row">
                  <div class="col-12">
                    <span class="form-label m-0">{{ $t("content.amnselectauto") }}</span>
                    <div class="d-inline-block mx-4">
                      <material-checkbox id="asGroup" v-model="selectGroup" :checked="selectGroup" name="autoSelect"
                        :disabled="selectType" @change="onSelectGroup">
                        {{ $t("content.amnasgroup") }}
                      </material-checkbox>
                    </div>

                    <div class="d-inline-block me-4">
                      <material-checkbox id="asType" v-model="selectType" :checked="selectType" name="autoSelect"
                        :disabled="selectGroup" @change="onSelectType">
                        {{ $t("content.amnastype") }}
                      </material-checkbox>
                    </div>

                  </div>
                </div>
                <hr />
                <div class="row">
                  <div class="table-responsive">
                    <table class="table table-flush" :data-row-style="rowStyle">
                      <thead class="thead-light">
                        <tr>
                          <th></th>
                          <th>#</th>
                          <th>{{ $t("content.jobId") }}</th>
                          <th>{{ $t("content.trayId") }}</th>
                          <th>{{ $t("content.amndatetime") }}</th>
                          <th>{{ $t("content.tkOwner") }}</th>
                          <th>{{ $t("content.oldname") }}</th>
                          <th>{{ $t("content.refnumber") }}</th>
                          <th>{{ $t("content.payside") }}</th>
                          <th>{{ $t("content.amnbiz") }}</th>
                          <th>{{ $t("content.amnweightvalall") }}</th>
                          <th>{{ $t("content.amnqtyunitall") }}</th>
                          <th>{{ $t("content.amnanalysval") }}</th>
                          <th>{{ $t("content.amnmaxvalall") }}</th>
                          <th>{{ $t("content.amnreceiveprofit") }}</th>
                          <th>{{ $t("content.gpay") }}</th>
                          <th>{{ $t("content.amnlastmaker") }}</th>

                        </tr>
                      </thead>

                      <tbody data-group-index="0">

                        <tr v-for="(row, index) in listQ02" :key="index" :class="row.paySide == 'PAY' && row.migration ? 'bg-redeem'
                          : row.paySide == 'PAY' && !row.migration ? 'bg-payment'
                            : row.paySide === 'RECEIVE' && row.migration ? 'bg-reduce-cap'
                              : row.paySide === 'RECEIVE' && !row.migration ? 'bg-receive'
                                : row.paySide === 'NONE' && row.migration ? 'bg-nonemi' : 'bg-none'">
                          <td class="text-sm font-weight-normal">
                            <material-checkbox v-model="row.chk" :checked="row.chk" :disabled="(selectGroup && row.paymentGroupUid !== groupuid && groupuid !== '')
                              || (selectType && row.type !== typeTrans && typeTrans !== '')"
                              @change="onSelectListQ02(row)">
                            </material-checkbox>
                          </td>
                          <td class="text-sm font-weight-normal">{{ formatNumberString(index + 1) }}</td>
                          <td class="text-sm font-weight-normal">{{ row.jobId }}</td>
                          <td class="text-sm font-weight-normal">{{ row.trayId }}</td>
                          <td class="text-sm font-weight-normal">{{ row.start }}</td>
                          <td class="text-sm font-weight-normal">
                            <span v-if="row.changeName">
                              {{ row.customerName }}
                            </span>
                            <span v-else>
                              {{ row.tkCustomerName }}
                            </span>
                          </td>
                          <td class="text-sm font-weight-normal">
                            <span v-if="row.changeName">
                              {{ row.tkCustomerName }}
                            </span>
                          </td>
                          <td class="text-sm font-weight-normal">
                            {{ row.prevRefNumber }}
                          </td>
                          <td class="text-sm font-weight-normal">{{ row.payside }}</td>
                          <td class="text-sm font-weight-normal">{{ row.trans }}</td>
                          <td class="text-sm font-weight-normal">{{ row.weight_s }}</td>
                          <td class="text-sm font-weight-normal">{{ row.quantity_s }}</td>
                          <td class="text-sm font-weight-normal">{{ row.newPawnValue_s }}</td>
                          <td class="text-sm font-weight-normal">{{ row.fullValue_s }}</td>
                          <td class="text-sm font-weight-normal">{{ row.interest_s }}</td>
                          <td class="text-sm font-weight-normal">
                            <span v-if="row.migration">
                              <span
                                v-if="row.migrationType === 'PAWN' || row.migrationType === 'INC_PRINCIPLE' && row.totalPay > 0">
                                {{ formatNumberDecimalString(row.totalPay) }}
                              </span>
                              <span v-else>
                                {{ formatNumberDecimalString(row.totalReceive) }}
                              </span>
                            </span>
                            <span v-else>
                              <span v-if="row.type === 'PAWN' || row.type === 'INC_PRINCIPLE' && row.totalPay > 0">
                                {{ formatNumberDecimalString(row.totalPay) }}
                              </span>
                              <span v-else>
                                {{ formatNumberDecimalString(row.totalReceive) }}
                              </span>
                            </span>

                          </td>
                          <td class="text-sm font-weight-normal">{{ row.latestUserName }}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>

                <div class="row mt-2">
                  <div class="col-12 d-flex justify-content-end">
                    <div class="me-3">
                      <material-button id="saveTicket" color="primary" class="float-lg-start float-sm-end mb-3"
                        @click="chkSelect">
                        <i class="material-icons material-symbols-outlined">lock</i>
                        {{ selects }}
                      </material-button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div id="secondHome" class="tab-pane fade" role="tabpanel" aria-labelledby="second-home-tab">
            <div class="card mt-2">
              <div class="card-header mb-0">
                <h5>{{ $t("content.amnalljobs") }}</h5>
              </div>

              <div class="card-body pt-0">
                <div class="row mt-2">
                  <div class="col-12 d-flex">
                    <div class="me-3">
                      <material-button color="success" class="float-lg-start float-sm-end mb-3" @click="onOpenQRModal">
                        <i class="material-icons material-symbols-outlined">qr_code_scanners</i>
                        {{ $t("buttons.sscan") }}
                      </material-button>
                      <material-button color="success" class="float-lg-start float-sm-end mb-3" data-bs-toggle="modal"
                        data-bs-target="#qrcodeModal" id="qrModalShow" v-show="false">
                      </material-button>
                    </div>
                    <div class="col-8 me-3">
                      <material-input type="text" v-model="qr_serach2" :placeholder="t(`content.amnjobcode`)"
                        @keyup.enter="setResultQRTab2" />
                    </div>
                  </div>
                </div>
                <hr />
                <div class="row">
                  <div class="table-responsive">
                    <table class="table table-flush">
                      <thead class="thead-light">
                        <tr>
                          <th>#</th>
                          <th>{{ $t("content.jobId") }}</th>
                          <th>{{ $t("content.trayId") }}</th>
                          <th>{{ $t("content.amndatetime") }}</th>
                          <th>{{ $t("content.tkOwner") }}</th>
                          <th>{{ $t("content.oldname") }}</th>
                          <th>{{ $t("content.refnumber") }}</th>
                          <th>{{ $t("content.payside") }}</th>
                          <th>{{ $t("content.amnbiz") }}</th>
                          <th>{{ $t("content.amnweightvalall") }}</th>
                          <th>{{ $t("content.amnqtyunitall") }}</th>
                          <th>{{ $t("content.amnanalysval") }}</th>
                          <th>{{ $t("content.amnmaxvalall") }}</th>
                          <th>{{ $t("content.amnreceiveprofit") }}</th>
                          <th>{{ $t("content.gpay") }}</th>
                          <th>{{ $t("content.amnlastmaker") }}</th>
                          <th>{{ $t("content.act") }}</th>
                        </tr>
                      </thead>

                      <tbody data-group-index="0">
                        <tr v-for="(row, index) in listQ02O" :key="index" :class="row.paySide == 'PAY' && row.migration ? 'bg-redeem'
                          : row.paySide == 'PAY' && !row.migration ? 'bg-payment'
                            : row.paySide === 'RECEIVE' && row.migration ? 'bg-reduce-cap'
                              : row.paySide === 'RECEIVE' && !row.migration ? 'bg-receive'
                                : row.paySide === 'NONE' && row.migration ? 'bg-nonemi' : 'bg-none'">
                          <td class="text-sm font-weight-normal">
                            {{ formatNumberString(index + 1) }}</td>
                          <td class="text-sm font-weight-normal">{{ row.jobId }}</td>
                          <td class="text-sm font-weight-normal">{{ row.trayId }}</td>
                          <td class="text-sm font-weight-normal">{{ row.start }}</td>
                          <td class="text-sm font-weight-normal">
                            <span v-if="row.changeName">
                              {{ row.customerName }}
                            </span>
                            <span v-else>
                              {{ row.tkCustomerName }}
                            </span>
                          </td>
                          <td class="text-sm font-weight-normal">
                            <span v-if="row.changeName">
                              {{ row.tkCustomerName }}
                            </span>
                          </td>
                          <td class="text-sm font-weight-normal">{{ row.prevRefNumber }}</td>
                          <td class="text-sm font-weight-normal">{{ row.payside }}</td>
                          <td class="text-sm font-weight-normal">{{ row.trans }}</td>
                          <td class="text-sm font-weight-normal">{{ row.weight_s }}</td>
                          <td class="text-sm font-weight-normal">{{ row.quantity_s }}</td>
                          <td class="text-sm font-weight-normal">{{ row.newPawnValue_s }}</td>
                          <td class="text-sm font-weight-normal">{{ row.fullValue_s }}</td>
                          <td class="text-sm font-weight-normal">{{ row.interest_s }}</td>
                          <td class="text-sm font-weight-normal">
                            <span v-if="row.migration">
                              <span
                                v-if="row.migrationType === 'PAWN' || row.migrationType === 'INC_PRINCIPLE' && row.totalPay > 0">
                                {{ formatNumberDecimalString(row.totalPay) }}
                              </span>
                              <span v-else>
                                {{ formatNumberDecimalString(row.totalReceive) }}
                              </span>
                            </span>
                            <span v-else>
                              <span v-if="row.type === 'PAWN' || row.type === 'INC_PRINCIPLE' && row.totalPay > 0">
                                {{ formatNumberDecimalString(row.totalPay) }}
                              </span>
                              <span v-else>
                                {{ formatNumberDecimalString(row.totalReceive) }}
                              </span>
                            </span>
                          </td>
                          <td class=" text-sm font-weight-normal">{{ row.latestUserName }}
                          </td>


                          <td class="text-sm font-weight-normal">
                            <material-button id="a" color="info" variant="outline" class="rounded-circle"
                              data-bs-toggle="tooltip" data-bs-placement="top" :title="t('content.amnticketmanage')"
                              @click="openTab(row)" :disabled="row.step.code !== 'S020' && row.step.code !== 'S021'
                                && row.step.code !== 'S040' && row.step.code !== 'S041'">
                              <i class="material-icons material-symbols-outlined">edit</i>
                            </material-button>
                            <material-button color="warning" variant="outline" class="rounded-circle"
                              data-bs-toggle="tooltip" data-bs-placement="top" :title="t('content.amnunlock')"
                              @click="onUnLockDialog(row)" :disabled="row.step.code !== 'S020' && row.step.code !== 'S021'
                                && row.step.code !== 'S040' && row.step.code !== 'S041'">
                              <i class="material-icons material-symbols-outlined">lock_open</i>
                            </material-button>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div id="thirdHome" class="tab-pane fade" role="tabpanel" aria-labelledby="third-home-tab">
            <div class="card mt-4">
              <div class="card-header">
                <h5>{{ $t("content.persontinfo") }} ({{ $t("content.tkOwner") }} :
                  <span v-if="listQ02E.changeName">
                    {{ listQ02E.customerName }}
                  </span>
                  <span v-else>
                    {{ listQ02E.tkCustomerName }}
                  </span>)
                </h5>
              </div>

              <div class="card-body pt-0">
                <div class="row">

                  <div class="col-lg-6">
                    <label class="form-label ms-0">{{ $t('content.cnam') }} - {{ $t('content.clsnam')
                      + " (" + $t("content.th") + ")" }}</label>
                    <material-input v-model="cus_detail.nameTh" type="text" disabled />
                  </div>

                  <div class="col-lg-6">
                    <label class="form-label ms-0">{{ $t('content.cnam') }} - {{ $t('content.clsnam')
                      + " (" + $t("content.en") + ")" }}</label>
                    <material-input v-model="cus_detail.nameEn" type="text" disabled />
                  </div>

                  <div class="col-lg-3">
                    <label class="form-label ms-0">{{ $t("content.cmobile") }}1*</label>
                    <span v-if="cus_detail.cusShop !== null">
                      <InputMask v-model="cus_detail.cusShop.phoneNo1" mask="(999) 999-9999" fluid />
                    </span>
                    <span v-else>
                      <material-input type="text" disabled />
                    </span>
                  </div>
                  <div class="col-lg-3">
                    <label class="form-label ms-0">{{ $t("content.cmobile") }}2</label>
                    <span v-if="cus_detail.cusShop !== null">
                      <InputMask v-model="cus_detail.cusShop.phoneNo2" mask="(999) 999-9999" fluid />
                    </span>
                    <span v-else>
                      <material-input type="text" disabled />
                    </span>
                  </div>
                  <div class="col-lg-3">
                    <label class="form-label ms-0">{{ $t("content.cmobile") }}3</label>
                    <span v-if="cus_detail.cusShop !== null">
                      <InputMask v-model="cus_detail.cusShop.phoneNo3" mask="(999) 999-9999" fluid />
                    </span>
                    <span v-else>
                      <material-input type="text" disabled />
                    </span>
                  </div>
                  <div class="col-lg-3">
                    <label class="form-label ms-0"></label>
                    <div class="flex flex-wrap justify-center gap-4">
                      <div class="flex items-center d-inline-block">
                        <material-checkbox id="asGroup" v-model="cus_detail.cusShop.notifyWarning"
                          :checked="cus_detail.cusShop.notifyWarning">
                          {{ $t("content.cenanoti") }}
                        </material-checkbox>
                      </div>
                    </div>
                  </div>
                  <pre></pre>
                  <div class="col-lg-10">
                    <label class="form-label ms-0">
                      <span v-if="cus_detail.cusShop.warningMsg !== null">
                        {{ $t('content.cexintra') }} ( {{ $t('content.cchacount') }} {{
                          cus_detail.cusShop.warningMsg.length }} / 100)
                      </span>
                      <span v-else>
                        {{ $t('content.cexintra') }} ( {{ $t('content.cchacount') }} 0 / 100)
                      </span>
                    </label>
                    <input v-model="cus_detail.cusShop.warningMsg" type="text" class="form-control" autocomplete="off"
                      style="margin-bottom:0 !important" aria-describedby="searchRelation" maxlength="100">
                  </div>

                  <div class="col-lg-1 col-sm-3">
                    <label class="form-label ms-0 png"></label>
                    <material-button id="cardSearch" color="primary" class="float-lg-start float-sm-end mb-3"
                      @click="toggleInfo">
                      <i v-if="datas.hide" class="material-icons material-symbols-outlined">expand_less</i>
                      <i v-else class="material-icons material-symbols-outlined">expand_more</i>
                    </material-button>
                  </div>
                  <div class="col-lg-1 col-sm-3">
                    <label class="form-label ms-0 png"></label>
                    <material-button color="primary" class="float-lg-start float-sm-end mb-3" :disabled="cus_detail.cusShop.phoneNo1 === null
                      || cus_detail.uid === '' || workcaseDetail === null || cus_detail.cusShop === null"
                      @click="updateCustomerShop('data')" :title="t('buttons.save') + $t('content.gcustomer')">
                      <i class="material-icons material-symbols-outlined">save</i>
                    </material-button>
                  </div>

                </div>
                <div v-if="datas.hide" class="row">
                  <div class="col-lg-3 col-sm-4">
                    <label class="form-label ms-0">{{ $t('content.cardtype') }}</label>
                    <select class="form-select" v-model="cus_detail.docTypeUid" disabled>
                      <option value="0" disabled selected>{{ $t('content.select') }}</option>
                      <option v-for="(item, index) in doctypeAccList" :value="item.uid" :key="index">
                        {{ item.name }}
                      </option>
                    </select>
                  </div>
                  <div class="col-lg-5 col-sm-5">
                    <label class="form-label ms-0">{{ $t('content.cardno') }}</label>
                    <material-input type="text" v-model="cus_detail.docNumber" disabled />
                  </div>

                  <div class="col-lg-3 col-sm-5">
                    <label class="form-label ms-0"> {{ $t("content.noexp") }}</label>
                    <div class="flex flex-wrap justify-center gap-4">
                      <div class="flex items-center d-inline-block">
                        <material-checkbox v-model="cus_detail.lifetime" :checked="cus_detail.lifetime" disabled>
                        </material-checkbox>
                      </div>
                    </div>
                  </div>

                  <div class="col-lg-4">
                    <label class="form-label ms-0">{{ $t('content.pcissue') }}</label>
                    <material-input v-model="cus_detail.issuedBy" type="text" disabled />
                  </div>
                  <div class="col-lg-3">
                    <label class="form-label ms-0">{{ $t('content.cdissue') }}</label>
                    <div class="flex justify-center">
                      <VueDatePicker v-model="cus_detail.issuedDate" :format="format" :max-date="new Date()" auto-apply
                        :enable-time-picker="false" month-name-format="long" :clearable="false" fluid disabled />
                    </div>
                  </div>
                  <div class="col-lg-3">
                    <label class="form-label ms-0">{{ $t('content.cdexpire') }}</label>
                    <VueDatePicker v-model="cus_detail.expiredDate" :format="format" :min-date="new Date()" auto-apply
                      :enable-time-picker="false" month-name-format="long" :clearable="false" fluid disabled />
                  </div>
                </div>


                <div v-if="datas.hide" class="row">
                  <div class="col-lg-3">
                    <label class="form-label ms-0">{{ $t('content.cbirth') }}</label>
                    <VueDatePicker v-model="cus_detail.birthOrRegDate" :format="format" :max-date="new Date()"
                      auto-apply :enable-time-picker="false" month-name-format="long" :clearable="false" fluid disabled
                      @update:model-value="inputBirthday" />
                  </div>
                  <div class="col-lg-2">
                    <label class="form-label ms-0">{{ $t('content.cage') }}</label>
                    <material-input v-model="cus_detail.age" type="text" disabled />
                  </div>
                  <div class="col-lg-3">
                    <label class="form-label ms-0">{{
                      $t("content.amntypecustomer")
                    }}</label>
                    <span v-if="cus_detail.cusShop !== null">
                      <select class="form-select" v-model="cus_detail.cusShop.cusTypeUid" disabled>
                        <option value="0" disabled selected>{{ $t('content.select') }}</option>
                        <option v-for="(item, index) in custypeList" :value="item.uid" :key="index">
                          {{ item.name }}
                        </option>
                      </select>
                    </span>
                    <span v-else>
                      <select class="form-select" disabled>
                        <option value="0" disabled selected>{{ $t('content.select') }}</option>
                        <option v-for="(item, index) in custypeList" :value="item.uid" :key="index">
                          {{ item.name }}
                        </option>
                      </select>
                    </span>

                  </div>
                  <div class="col-lg-3">
                    <label class="form-label ms-0">{{ $t("content.amncredit") }}</label>
                    <span v-if="cus_detail.cusShop !== null">
                      <InputNumber v-model="cus_detail.cusShop.creditAmount" autocomplete="off" disabled fluid />
                    </span>
                    <span v-else>
                      <material-input type="text" disabled />
                    </span>
                  </div>
                </div>

                <div v-if="datas.hide" class="row">
                  <div class="col-8 mt-2">
                    <label class="form-label ms-0">{{ $t('content.caddress') }} ({{ $t('content.docaddress') }})</label>
                    <material-input v-model="cus_detail.address.address1" type="text" disabled />
                  </div>
                  <div class="col-lg-4 mt-2">
                    <label class="form-label ms-0">{{ $t('content.add1') }}</label>
                    <div class="input-group">
                      <Select v-model="cus_detail.address.country" :options="listCountry" filter optionLabel="nameTh"
                        placeholder="กรุณาเลือกประเทศ" class="w-full md:w-56" style="width:650px;" resetFilterOnHide
                        disabled>
                        <template #value="slotProps">
                          <div v-if="slotProps.value" class="flex items-center">
                            <div>{{ slotProps.value.nameTh }}</div>
                          </div>
                          <span v-else>
                            {{ slotProps.placeholder }}
                          </span>
                        </template>
                        <template #option="slotProps">
                          <div class="flex items-center">
                            <div>{{ slotProps.option.nameTh }}</div>
                          </div>
                        </template>
                      </Select>
                    </div>
                  </div>


                  <div class="col-lg-4 mt-2">
                    <label class="form-label ms-0">{{ $t('content.cstate') }}</label>
                    <div class="input-group">
                      <Select v-model="cus_detail.address.province" :options="listProvince" filter optionLabel="nameTh"
                        placeholder="กรุณาเลือกจังหวัด" class="w-full md:w-56" style="width:450px;" resetFilterOnHide
                        disabled>
                        <template #value="slotProps">
                          <div v-if="slotProps.value" class="flex items-center">
                            <div>{{ slotProps.value.nameTh }}</div>
                          </div>
                          <span v-else>
                            {{ slotProps.placeholder }}
                          </span>
                        </template>
                        <template #option="slotProps">
                          <div class="flex items-center">
                            <div>{{ slotProps.option.nameTh }}</div>
                          </div>
                        </template>
                      </Select>
                    </div>
                  </div>
                  <div class="col-lg-4 mt-2">
                    <label class="form-label ms-0">{{ $t('content.csprov') }}</label>
                    <div class="input-group">
                      <Select v-model="cus_detail.address.district" :options="listDistrict" filter optionLabel="nameTh"
                        placeholder="กรุณาเลือกเขต/อำเภอ" class="w-full md:w-56" style="width:450px;" resetFilterOnHide
                        disabled>
                        <template #value="slotProps">
                          <div v-if="slotProps.value" class="flex items-center">
                            <div>{{ slotProps.value.nameTh }}</div>
                          </div>
                          <span v-else>
                            {{ slotProps.placeholder }}
                          </span>
                        </template>
                        <template #option="slotProps">
                          <div class="flex items-center">
                            <div>{{ slotProps.option.nameTh }}</div>
                          </div>
                        </template>
                      </Select>
                    </div>
                  </div>
                  <div class="col-lg-4 mt-2">
                    <label class="form-label ms-0">{{ $t('content.cscity') }}</label>
                    <div class="input-group">
                      <Select v-model="cus_detail.address.subDistrict" :options="listSubDistrict" filter
                        optionLabel="nameTh" placeholder="กรุณาเลือกแขวง/ตำบล" class="w-full md:w-56"
                        style="width:450px;" resetFilterOnHide disabled>
                        <template #value="slotProps">
                          <div v-if="slotProps.value" class="flex items-center">
                            <div>{{ slotProps.value.nameTh }}</div>
                          </div>
                          <span v-else>
                            {{ slotProps.placeholder }}
                          </span>
                        </template>
                        <template #option="slotProps">
                          <div class="flex items-center">
                            <div>{{ slotProps.option.nameTh }}</div>
                          </div>
                        </template>
                      </Select>
                    </div>
                  </div>
                  <div class="col-lg-4 mt-2">
                    <label class="form-label ms-0">{{ $t('content.add5') }}</label>
                    <div class="input-group">
                      <Select v-model="cus_detail.address.postcode" :options="listPostcode" filter
                        optionLabel="postcode" placeholder="กรุณาเลือกรหัสไปรษณีย์" class="w-full md:w-56"
                        style="width:650px;" resetFilterOnHide disabled>
                        <template #value="slotProps">
                          <div v-if="slotProps.value" class="flex items-center">
                            <div>{{ slotProps.value.postcode }}</div>
                          </div>
                          <span v-else>
                            {{ slotProps.placeholder }}
                          </span>
                        </template>
                        <template #option="slotProps">
                          <div class="flex items-center">
                            <div>{{ slotProps.option.postcode }}</div>
                          </div>
                        </template>
                      </Select>
                    </div>
                  </div>
                  <div class="col-8 mt-2">
                    <label class="form-label ms-0">{{ $t('content.cemail') }}</label>
                    <span v-if="cus_detail.cusShop !== null">
                      <material-input v-model="cus_detail.cusShop.email" type="email" />
                    </span>
                    <span v-else>
                      <material-input disabled />
                    </span>
                  </div>
                </div>
              </div>



              <div class="card mt-4">
                <div class="card-header">
                  <div class="d-flex justify-content-between align-items-center">
                    <h6 class="mb-0 me-4">
                      <b> {{ $t('content.amnassetowner') }}/{{ $t('content.cabove') }}
                        <span v-if="listQ02E.prevRefNumber !== '' && listQ02E.prevRefNumber !== null">
                          ({{ $t("content.refnumber") }} : {{ listQ02E.prevRefNumber }})
                        </span></b>
                    </h6>

                    <div class="col">
                      <div class="input-group">
                        <input v-model="cusName_search" type="text" class="form-control"
                          style="margin-bottom: 0 !important" aria-describedby="searchNameOwner" :placeholder="$t('buttons.sear') +
                            ' ' +
                            $t('content.amnassetowner') +
                            '/' +
                            $t('content.cabove')
                            " readonly />
                        <button id="searchNameOwner" class="btn btn-primary mb-0" type="button" @click="openDialogCus"
                          :disabled="cus_detail.uid === '' || workcaseDetail === null || cus_detail.cusShop === null || listQ02E.type !== 'PAWN'">
                          <i class="material-icons material-symbols-outlined">search</i>
                        </button>
                        <button id="searchNameCus" class="btn btn-primary" type="button" data-bs-toggle="modal"
                          data-bs-target="#searchNameModal" v-show="false">
                        </button>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="card-body pt-0">
                  <div class="mb-3 pt-2" v-if="workcaseDetail !== null">
                    <div class="d-flex justify-content-between align-items-center">
                      <h6 class="mb-0 me-4">
                        <span v-if="workcaseDetail.migration" style="color:red;">
                          <b>{{ $t("content.migraref") }}</b>
                        </span>
                        <span v-else>
                          <b>{{ $t("content.migraref") }}</b>
                        </span>
                      </h6>
                      <div class="col">
                        <div class="input-group">
                          <material-input v-model="migrateRefNumber" class="hl-label"
                            :disabled="!workcaseDetail.migration" />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="row review mb-3 pt-2">
                    <div class="col-lg-3">
                      <label class="form-label hl-label ms-0">{{
                        $t("content.amnhdweightalltick")
                      }}</label>
                      <material-input v-model="listQ02E.weight_s" class="hl-label" disabled="true" />
                    </div>

                    <div class="col-lg-3">
                      <label class="form-label hl-label ms-0">{{
                        $t("content.amnhdanalysalltick")
                      }}</label>
                      <material-input v-model="listQ02E.newPawnValue_s" class="hl-label" disabled="true" />
                    </div>

                    <div class="col-lg-2">
                      <label class="form-label hl-label ms-0">{{
                        $t("content.amnhdmaxalltick")
                      }}</label>
                      <material-input v-model="listQ02E.fullValue_s" class="hl-label" disabled="true" />
                    </div>

                    <div class="col-lg-2">
                      <label class="form-label hl-label ms-0">{{ $t("content.cintrate") }}</label>
                      <material-input v-model="interestRate_s" class="hl-label" disabled="true" />
                    </div>

                    <div class="col-lg-2">
                      <label class="form-label hl-label ms-0">{{ $t("content.prointerest") }}</label>
                      <material-input v-model="promoint_val" class="hl-label" disabled="true" />
                    </div>

                    <!-- <div class="col-lg-2">
                      <label class="form-label hl-label ms-0">{{
                        $t("content.amnhdanalysold")
                      }}</label>
                      <material-input v-model="listQ02E.pawnValue_s" class="hl-label" disabled="true" />
                    </div> -->

                    <!-- <div class="col-lg-2">
                      <label class="form-label hl-label ms-0">{{
                        $t("content.amndifference")
                      }}</label>
                      <material-input v-model="diff_s" class="hl-label" disabled="true" value="88,888,888.88" />
                    </div> -->

                    <div class="col-lg-3">
                      <label class="form-label hl-label ms-0">{{
                        $t("content.amnhdweightrest")
                      }}</label>
                      <material-input v-model="balweight_s" class="hl-label" disabled="true" />
                    </div>

                    <div class="col-lg-3">
                      <label class="form-label hl-label ms-0">{{
                        $t("content.amnhdanalysrest")
                      }}</label>
                      <material-input v-model="balprice_s" class="hl-label" disabled="true" />
                    </div>

                    <div class="col-lg-2">
                      <label class="form-label hl-label ms-0">{{
                        $t("content.amnhdmaxrest")
                      }}</label>
                      <material-input v-model="balfull_s" class="hl-label" disabled="true" />
                    </div>

                    <div class="col-lg-2">
                      <label class="form-label hl-label ms-0">{{
                        $t("content.amnreceiveprofit")
                      }}</label>
                      <material-input v-model="listQ02E.interest_s" class="hl-label" disabled="true" />
                    </div>

                    <div class="col-lg-2">
                      <label class="form-label hl-label ms-0">{{
                        $t("content.periodpromotion")
                      }}</label>
                      <material-input v-model="promoint_duration" class="hl-label" disabled="true" />
                    </div>

                    <!-- <div class="col-lg-2">
                      <label class="form-label hl-label ms-0">{{
                        $t("content.amnhdanalysnew")
                      }}</label>
                      <material-input v-model="listQ02E.newPawnValue_s" class="hl-label" disabled="true"
                        value="88,888,888.88" />
                    </div> -->

                    <!-- <div class="col-lg-2">
                      <label class="form-label hl-label ms-0">{{
                        $t("content.amnhdvalticket")
                      }}</label>
                      <material-input v-model="listQ02E.pawnValue_s" class="hl-label" disabled="true"
                        value="88,888,888.88" />
                    </div> -->
                  </div>

                  <!-- <div class="row review mb-3 pt-2">
                    <div class="grid-containerB">

                      <label class="form-label hl-label ms-0">{{
                        $t("content.amnhdweightalltick")
                      }}</label>
                      <material-input v-model="listQ02E.weight_s" class="hl-label" disabled="true" />



                      <label class="form-label hl-label ms-0">{{
                        $t("content.amnhdanalysalltick")
                      }}</label>
                      <material-input v-model="listQ02E.newPawnValue_s" class="hl-label" disabled="true" />



                      <label class="form-label hl-label ms-0">{{
                        $t("content.amnhdmaxalltick")
                      }}</label>
                      <material-input v-model="listQ02E.fullValue_s" class="hl-label" disabled="true" />



                      <label class="form-label hl-label ms-0">{{ $t("content.cintrate") }}</label>
                      <material-input v-model="interestRate_s" class="hl-label" disabled="true" />

                      <label class="form-label hl-label ms-0">{{
                        $t("content.amnhdweightrest")
                      }}</label>
                      <material-input v-model="balweight_s" class="hl-label" disabled="true" />



                      <label class="form-label hl-label ms-0">{{
                        $t("content.amnhdanalysrest")
                      }}</label>
                      <material-input v-model="balprice_s" class="hl-label" disabled="true" />



                      <label class="form-label hl-label ms-0">{{
                        $t("content.amnhdmaxrest")
                      }}</label>
                      <material-input v-model="balfull_s" class="hl-label" disabled="true" />



                      <label class="form-label hl-label ms-0">{{
                        $t("content.amnreceiveprofit")
                      }}</label>
                      <material-input v-model="listQ02E.interest_s" class="hl-label" disabled="true" />
                    </div>
                  </div> -->


                  <div class="row mt-4 no-gutters">
                    <div class="col-6 d-flex justify-content-end align-items-center">
                      <div class="left">
                        <div class="me-1">
                          <h6>
                            <b>{{ $t("content.amnassetdatasinfo") }}</b>
                          </h6>
                        </div>&nbsp;
                        <div class="me-4">
                          <h6 style="color:red" v-if="uid_edit !== '' && workcaseDetail !== null">
                            <b>( {{ listQ02E.trans }}
                              <span v-if="listQ02E.type === 'PAWN' || listQ02E.type === 'REDEEM'">
                                {{ formatNumberDecimalString(workcaseDetail.pawnValue) }}
                              </span>
                              <span v-if="listQ02E.type === 'INTEREST'">
                                {{ formatNumberDecimalString(workcaseDetail.interest) }}
                              </span>
                              <span v-if="listQ02E.type === 'INC_PRINCIPLE' || listQ02E.type === 'DEC_PRINCIPLE'">
                                {{ formatNumberDecimalString(workcaseDetail.diffValue) }}
                              </span>
                              <i i class="inline-icon material-icons">keyboard_double_arrow_right</i> {{
                                $t("content.gpay") }} : </b>


                            <span v-if="listQ02E.migration" style="text-decoration:underline">
                              <span
                                v-if="listQ02E.migrationType === 'PAWN' || listQ02E.migrationType === 'INC_PRINCIPLE' && listQ02E.totalPay > 0">
                                <b>{{ formatNumberDecimalString(listQ02E.totalPay) }}</b>
                              </span>
                              <span v-else>
                                <b>{{ formatNumberDecimalString(listQ02E.totalReceive) }}</b>
                              </span>
                            </span>
                            <span v-else style="text-decoration:underline">
                              <span
                                v-if="listQ02E.type === 'PAWN' || listQ02E.type === 'INC_PRINCIPLE' && listQ02E.totalPay > 0">
                                <b>{{ formatNumberDecimalString(listQ02E.totalPay) }}</b>
                              </span>
                              <span v-else>
                                <b>{{ formatNumberDecimalString(listQ02E.totalReceive) }}</b>
                              </span>
                            </span>
                            <b> )</b>
                          </h6>
                        </div>
                      </div>
                    </div>






                    <div class="col-6 d-flex justify-content-end align-items-center">

                      <div class="me-3">
                        <material-button color="primary" class="float-lg-start float-sm-end mb-3"
                          :disabled="images_l.length === 0" @click="openGallery">
                          <i class="material-icons material-symbols-outlined">photo_library</i>
                          {{ $t("content.viewimg") }}
                        </material-button>
                        <material-button color="warning" class="float-lg-start float-sm-end mb-3" data-bs-toggle="modal"
                          data-bs-target="#lookupModal" id="myModalClose" v-show="false">
                          <i class="material-icons material-symbols-outlined">photo_library</i>
                          {{ $t("content.viewimg") }}
                        </material-button>
                      </div>
                    </div>
                  </div>

                  <div class="row mt-4">
                    <div class="table-responsive">
                      <table class="table table-flush">
                        <thead class="thead-light">
                          <tr>

                            <th>#</th>
                            <th>{{ $t("content.amndatetime") }}</th>
                            <th>{{ $t("content.amnbiz") }}</th>
                            <th>{{ $t("content.amnassetgroup") }}</th>
                            <th>{{ $t("content.amnweightval") }}</th>
                            <th>{{ $t("content.amnqtyunit") }}</th>
                            <th>{{ $t("content.amnanalysval") }}</th>
                            <th>{{ $t("content.amnmaxval") }}</th>

                          </tr>
                        </thead>
                        <tbody>
                          <!-- <tr style="background-color: transparent;"> -->
                          <tr v-for="(row, index) in listinfo" :key="index">

                            <td class="text-sm font-weight-normal">{{ formatNumberString(index + 1) }}</td>
                            <td class="text-sm font-weight-normal">
                              {{ listQ02E.start }}
                            </td>
                            <td class="text-sm font-weight-normal">
                              {{ listQ02E.trans }} </td>
                            <td class="text-sm font-weight-normal">{{ filterCat(row.mainCatUid) }}</td>
                            <td class="text-sm font-weight-normal">{{ formatNumberDecimalString(row.weight) }}
                            </td>
                            <td class="text-sm font-weight-normal">{{ formatNumberString(row.quantity) }}</td>
                            <td class="text-sm font-weight-normal">{{
                              formatNumberDecimalString(row.pawnValue) }}
                            </td>
                            <td class="text-sm font-weight-normal">{{
                              formatNumberDecimalString(row.fullValue) }}
                            </td>

                          </tr>

                        </tbody>
                      </table>
                    </div>
                  </div>


                  <hr />


                  <div class="row mt-4 no-gutters">
                    <div class="col-8 d-flex justify-content-end align-items-center">
                      <div class="left">
                        <div class="me-2">
                          <h6>
                            <b>{{ $t("content.amnassetdatas") }}</b>
                          </h6>
                        </div>&nbsp;
                        <div class="me-2" v-if="uid_edit !== '' && workcaseDetail !== null">
                          <h6 style="color:red" v-if="workcaseDetail.represent || workcaseDetail.shopRepresent">
                            ({{ $t("content.represent") }}
                            <i class="inline-icon material-icons">keyboard_double_arrow_right</i>
                            {{ workcaseDetail.workItems[0].customerName }})
                          </h6>
                          <h6 style="color:red" v-if="workcaseDetail.changeName">
                            ({{ $t("content.changeName") }}
                            <i class="inline-icon material-icons">keyboard_double_arrow_right</i>
                            {{ workcaseDetail.workItems[0].tkCustomerName }})
                          </h6>
                        </div>
                      </div>
                    </div>

                    <div class="col-4 d-flex justify-content-end align-items-center">
                      <div class="me-2">
                        <material-button id="capImage" color="primary" class="float-lg-start float-sm-end mb-3"
                          :disabled="true">
                          <i class="material-icons material-symbols-outlined"><span class="material-symbols-outlined">
                              currency_exchange
                            </span></i>
                          {{ $t("content.gprice") }}
                        </material-button>
                      </div>
                      <div class="me-2">
                        <material-button id="saveTicket" color="primary" class="float-lg-start float-sm-end mb-3"
                          :disabled="listQ02D.length >= 8 || parseFloat(balprice) === 0 || parseFloat(balquanity) === 0 || uid_edit === ''"
                          @click="showModal">
                          <i class="material-icons material-symbols-outlined">add</i>
                          {{ btnAddNew }}
                        </material-button>
                      </div>
                      <material-button color="primary" class="float-lg-start float-sm-end mb-0" data-bs-toggle="modal"
                        data-bs-target="#newPawnListModal" id="newPawnModal" v-show="false" />
                      <material-button color="primary" class="float-lg-start float-sm-end mb-0" data-bs-toggle="modal"
                        data-bs-target="#viewPawnListModal" id="viewPawnModal" v-show="false" />
                    </div>
                  </div>

                  <div class="row mt-4">
                    <div class="table-responsive">
                      <table class="table table-flush">
                        <thead class="thead-light">
                          <tr>
                            <th></th>
                            <th>#</th>
                            <th>{{ $t("content.amndatetime") }}</th>
                            <th>{{ $t("content.amnbiz") }}</th>
                            <th>{{ $t("content.amnassetgroup") }}</th>
                            <th>{{ $t("content.amnweightval") }}</th>
                            <th>{{ $t("content.amnqtyunit") }}</th>
                            <th>{{ $t("content.amnanalysval") }}</th>
                            <th>{{ $t("content.amnmaxval") }}</th>

                            <th>{{ $t("content.act") }}</th>
                          </tr>
                        </thead>
                        <!-- <tr style="background-color: transparent;"> -->

                        <tbody v-for="(row, index) in listQ02D" :key="index" :data-group-index="index">
                          <tr class="group-id-ticket" :class="row.st == 'finished' ? '' : 'bg-working'">
                            <td class="text-sm font-weight-normal">
                              <div class="group-tickect" style="padding: 0.5rem !important">
                                <material-checkbox class="m-0" style="padding: 0 !important" :checked="row.chk"
                                  disabled />
                              </div>
                            </td>
                            <td class="text-sm font-weight-normal">{{ formatNumberString(index + 1) }}</td>
                            <td class="text-sm font-weight-normal">{{ listQ02E.start }}</td>
                            <td class="text-sm font-weight-normal">{{ listQ02E.trans }}</td>
                            <td class="text-sm font-weight-normal">{{ filterCat(row.mainCatUid) }}</td>
                            <td class="text-sm font-weight-normal">{{ formatNumberDecimalString(row.weight)
                              }}
                            </td>
                            <td class="text-sm font-weight-normal">{{ formatNumberString(row.quantity) }}</td>
                            <td class="text-sm font-weight-normal">
                              <span v-if="listQ02E.type !== 'INC_PRINCIPLE' && listQ02E.type !== 'DEC_PRINCIPLE'">
                                {{ formatNumberDecimalString(row.newPawnValue) }}
                              </span>
                              <span v-else>
                                {{ formatNumberDecimalString(row.tmpNewPawnValue) }}
                              </span>
                            </td>
                            <td class="text-sm font-weight-normal">{{
                              formatNumberDecimalString(row.fullValue) }}
                            </td>
                            <td class="text-sm font-weight-normal">
                              <material-button v-if="listQ02E.type === 'PAWN'" color="info" variant="outline"
                                class="rounded-circle" data-bs-toggle="tooltip" data-bs-placement="top" :title="edit"
                                @click="onSelectCollect(row)">
                                <i class="material-icons material-symbols-outlined">edit</i>
                              </material-button>
                              <material-button v-if="listQ02E.type !== 'PAWN'" color="info" variant="outline"
                                class="rounded-circle" data-bs-toggle="tooltip" data-bs-placement="top" :title="show"
                                @click="onSelectCollect(row)">
                                <i class="material-icons material-symbols-outlined">search</i>
                              </material-button>
                              <material-button v-if="listQ02E.type === 'PAWN'" color="danger" variant="outline"
                                class="rounded-circle" data-bs-toggle="tooltip" data-bs-placement="top" :title="cancel"
                                @click="onDelete(row)">
                                <i class="material-icons material-symbols-outlined">delete</i>
                              </material-button>
                            </td>
                          </tr>
                          <tr v-for="(j, set1) in row.remarkL" :key="set1">
                            <td class="text-sm font-weight-normal text-end" colspan="3">
                              <span style="text-decoration:underline">
                                {{ $t("content.amnassetdetail") }}
                              </span>
                            </td>
                            <td class="text-sm font-weight-normal" colspan="7">
                              <span style="color:red">
                                <b>{{ j.val }}</b>
                              </span>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>

                  <div class="row mt-4">
                    <div class="col-3 d-flex justify-content-between align-items-center">
                      <material-button color="default" class="float-lg-start float-sm-end mb-3"
                        @click="backToSecondTab">
                        <i class="material-icons material-symbols-outlined">close</i>
                        {{ cancel }}
                      </material-button>
                    </div>

                    <div class="col-9 d-flex justify-content-end align-items-center">
                      <div class="me-3">
                        <material-button color="danger" class="float-lg-start float-sm-end mb-3" @click="onReturnDialog"
                          :disabled="uid_edit === ''">
                          <i class="material-icons material-symbols-outlined">reply</i>
                          {{ $t("buttons.rejec") }}
                        </material-button>
                      </div>
                      <div class="me-3">
                        <material-button id="capImage" color="primary" class="float-lg-start float-sm-end mb-3"
                          :disabled="parseFloat(balweight) !== 0 || parseFloat(balprice) !== 0 || parseFloat(balfull) !== 0 || parseFloat(balquanity) !== 0 || uid_edit === ''"
                          @click="onImgDialog">
                          <i class="material-icons material-symbols-outlined">add_a_photo</i>
                          {{ $t("content.tupload") }}
                        </material-button>
                        <material-button color="primary" variant="outline" class="rounded-circle" data-bs-toggle="modal"
                          data-bs-target="#attachmentsModal" v-show="false" id="attachmenDialog">
                        </material-button>


                      </div>

                      <div class="me-3">
                        <material-button color="primary" class="float-lg-start float-sm-end mb-3"
                          :disabled="parseFloat(balweight) !== 0 || parseFloat(balprice) !== 0 || parseFloat(balfull) !== 0
                            || parseFloat(balquanity) !== 0 || uid_edit === '' || finger || (workcaseDetail !== null && workcaseDetail.shopRepresent)" @click="checkbeforeScan">
                          <i class="material-icons material-symbols-outlined">fingerprint</i>
                          {{ $t("content.amnfingerprint") }}
                        </material-button>
                        <material-button id="fingerModal" color="primary" class="float-lg-start float-sm-end mb-3"
                          data-bs-toggle="modal" data-bs-target="#fingerModalNew" v-show="false">
                        </material-button>
                      </div>

                      <div class="me-3">
                        <material-button id="saveTicket" color="primary" class="float-lg-start float-sm-end mb-3"
                          :disabled="parseFloat(balweight) !== 0 || parseFloat(balprice) !== 0 || parseFloat(balfull) !== 0 || parseFloat(balquanity) !== 0
                            || (finger === false && (workcaseDetail !== null && workcaseDetail.shopRepresent === false)) || uid_edit === ''
                            || (workcaseDetail.migration && migrateRefNumber === null) || (workcaseDetail.migration && migrateRefNumber.trim() === '')"
                          @click="onCompleteDialog">
                          <i class="material-icons material-symbols-outlined">save</i>
                          {{ save }}
                        </material-button>

                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <NewCamera v-if="hideCam" v-model="hideCam" @close="closeCamera" @save="savephotoTaken" />

        <!-- Search Customer Dialog -->
        <div id="searchNameModal" class="modal fade" tabindex="-1" aria-labelledby="searchNameModalLabel"
          aria-hidden="true">
          <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
              <div class="modal-header">
                <h4 class="modal-title">{{ $t("content.cnamelist") }}</h4>
                <button type="button" class="close" data-bs-dismiss="modal" aria-label="Close" id="closeModal">
                  <i class="material-icons material-symbols-outlined">close</i>
                </button>
              </div>

              <div class="modal-body">
                <div class="preview-table">
                  <section class="panel">
                    <table class="table table-striped table-responsive">
                      <thead>
                        <tr>
                          <!-- <th style="width: 100px">{{ $t("content.ccode") }}</th> -->
                          <th>#</th>
                          <th>{{ $t("content.cnameth") }}</th>
                          <th>{{ $t("content.cnameen") }}</th>
                          <th>{{ $t("content.docnum") }}</th>
                          <th></th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="(row, index) in customerlist" :key="index" @click="selectCusAf(row)">
                          <td>{{ formatNumberString((currentPageC - 1) * perPageC + index + 1) }}</td>
                          <td>{{ row.nameTh }}</td>
                          <td>{{ row.nameEn }}</td>
                          <td colspan="2">{{ row.docNumber }}</td>
                        </tr>
                        <tr v-if="customerlist.length === 0">
                          <td colspan="5" class="text-sm font-weight-normal">
                            <p style="text-align: center">No Record Found</p>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </section>
                </div>
              </div>

              <div class="modal-footer">
                <pre></pre>
                <div style="display: flex; justify-content: flex-end">
                  <ul v-show="customerlist.length > 0" class="pagination justify-content-end">
                    <div class="project-container">
                      <div class="example-one">
                        <vue-awesome-paginate v-model="currentPageC" :total-items="totalRowsC"
                          :items-per-page="perPageC" @click="onClickHandlerC" />
                      </div>
                    </div>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- Gallery Modal -->
        <div id="lookupModal" class="modal fade" tabindex="-1" aria-labelledby="lookupModalLabel" aria-hidden="true">
          <div class="modal-dialog modal-dialog-centered" style="max-width: 520px">
            <div class="modal-content">
              <div class="modal-header">
                <h4 class="modal-title">{{ $t("content.detail") }}</h4>
                <button type="button" class="close" data-bs-dismiss="modal" aria-label="Close">
                  <i class="material-icons material-symbols-outlined">close</i>
                </button>
              </div>

              <div class="modal-body">
                <div id="lookupImages" class="carousel slide" data-bs-ride="carousel">
                  <div class="carousel-indicators">
                    <div class="carousel-indicators" id="demo1">
                    </div>
                  </div>
                  <div class="carousel-inner">
                    <ul className="list-unstyled">
                      <li v-for="(item, index) in images.length" :key="index">
                        <span v-if="index === 0">
                          <div class="carousel-item active">
                            <vue-image-zoomer :regular="images[index].src" img-class="img-fluid" class="d-block w-100"
                              :zoom-amount="3" :click-zoom="true" close-pos="top-right" message-pos="top" />
                          </div>
                        </span>
                        <span v-else>
                          <div class="carousel-item">
                            <vue-image-zoomer :regular="images[index].src" img-class="img-fluid" class="d-block w-100"
                              :zoom-amount="3" :click-zoom="true" close-pos="top-right" message-pos="top" />
                          </div>
                        </span>
                      </li>
                    </ul>
                  </div>
                  <button class="carousel-control-prev" type="button" data-bs-target="#lookupImages"
                    data-bs-slide="prev">
                    <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                    <span class="visually-hidden">Previous</span>
                  </button>
                  <button class="carousel-control-next" type="button" data-bs-target="#lookupImages"
                    data-bs-slide="next">
                    <span class="carousel-control-next-icon" aria-hidden="true"></span>
                    <span class="visually-hidden">Next</span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>


        <!-- Add New List in Pawn Ticket Dialog -->
        <div id="newPawnListModal" class="modal fade" aria-labelledby="newPawnListModalLabel" aria-hidden="true">
          <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
              <div class="modal-header">
                <h4 class="modal-title">{{ $t("content.amnsplitlist") }} </h4>
                <button type="button" class="close" data-bs-dismiss="modal" aria-label="Close">
                  <i class="material-icons material-symbols-outlined">close</i>
                </button>
              </div>

              <div class="modal-body">
                <fieldset>
                  <div class="row">
                    <div class="col-lg-4">
                      <label class="form-label ms-0">{{
                        $t("content.amnassetgroup")
                      }}</label>
                      <select v-model="collect_detail.mainCatUid" class="form-select" disabled>
                        <option value="0" selected disabled>{{ $t("content.select") }}</option>
                        <option v-for="(item, index) in listprint" :value="item.uid" :key="index">
                          {{ item.name }}
                        </option>
                      </select>
                    </div>

                    <div class="col-lg-8">
                      <label class="form-label ms-0">
                        {{ $t("content.amnassetgroup") }}
                        <span v-if="typesNew.length > 0">
                          ({{ textt }})
                        </span>
                      </label>
                      <div ref="parentRef" class="cs-auto-input">
                        <input id="inp" v-model="filterText" ref="input" placeholder="ค้นหาประเภททรัพย์..."
                          class="form-control auto-input-search" list="assetOptions" @input="newformatinput"
                          autocomplete="off" type="search">
                        <ul id="myList" v-if="dataList.length > 0 && filterText.length > 0" ref="list" class="data-list"
                          :style="`width: ${childWidth}px !important;`" @scroll="handleScroll">
                          <li v-for="(item, index) in dataList" :key="index" @click="selection(item)">
                            <a>{{ item.datailt }}</a>
                          </li>
                          <li v-if="loading">Loading...</li>
                          <p v-if="dataList.length" id="load-more" :class="loadMoreStatus"></p>
                        </ul>
                      </div>
                    </div>

                    <div class="row flex flex-wrap p-fluid">

                      <div class="flex-auto col-lg-4">
                        <label class="form-label ms-0">{{ $t("content.amnqtyunittick") }} </label>
                        <small class="form-label ms-0" style="color:red;"> {{ $t("content.baluseable") }} :
                          <span @click="copyQty()" style="text-decoration: underline;color:red; cursor: pointer">
                            {{ balquanity_s }}
                          </span>
                        </small>
                        <InputNumber v-model="collect_detail.quantity" autocomplete="off"
                          @focus="$event.target.select()" fluid @update:modelValue="setRemark()" />

                      </div>


                      <div class="flex-auto col-lg-2">
                        <label class="form-label ms-0">{{ $t("content.munit") }}</label>
                        <select v-model="collect_detail.unitUid" class="form-select" @change="setRemark">
                          <option value="0" selected disabled>{{ $t("content.select") }}</option>
                          <option v-for="(item, index) in listunit" :value="item.uid" :key="index">
                            {{ item.name }}
                          </option>
                        </select>
                      </div>

                      <div class="flex-auto col-lg-6">
                        <label class="form-label ms-0">Serial Number</label>
                        <material-input id="serialPawn" v-model="collect_detail.serialNumber" autocomplete="off"
                          @update:modelValue="setRemark()" />
                      </div>

                    </div>

                    <div class="row flex flex-wrap p-fluid">
                      <div class="flex-auto col-lg-4">
                        <label class="form-label ms-0">{{ $t("content.amnhdweightall") }}</label>
                        <small class="form-label ms-0" style="color:red;"> {{ $t("content.baluseable") }} :
                          <span @click="copyWeight()" style="text-decoration: underline;color:red; cursor: pointer">
                            {{ balweight_s }}
                          </span>
                        </small>
                        <InputNumber v-model="collect_detail.weight" :minFractionDigits="0" :maxFractionDigits="2"
                          autocomplete="off" @focus="$event.target.select()" fluid @update:modelValue="setRemark()" />
                      </div>


                      <div class="col-lg-4">
                        <label class="flex-auto form-label ms-0">{{ $t("content.amnanalysval") }}</label>
                        <small class="form-label ms-0" style="color:red;"> {{ $t("content.baluseable") }} :
                          <span @click="copyPrice()" style="text-decoration: underline;color:red; cursor: pointer">
                            {{ balprice_s }}
                          </span>
                        </small>
                        <InputNumber v-model="collect_detail.newPawnValue" :minFractionDigits="0" :maxFractionDigits="2"
                          autocomplete="off" @focus="$event.target.select()" fluid />

                      </div>

                      <div class="flex-auto col-lg-4">
                        <label class="form-label ms-0">{{ $t("content.amnmaxval") }}</label>
                        <small class="form-label ms-0" style="color:red;"> {{ $t("content.baluseable") }} :
                          <span @click="copyFull()" style="text-decoration: underline;color:red; cursor: pointer">
                            {{ balfull_s }}
                          </span>
                        </small>
                        <InputNumber v-model="collect_detail.fullValue" :minFractionDigits="0" :maxFractionDigits="2"
                          autocomplete="off" @focus="$event.target.select()" fluid />
                      </div>
                    </div>

                    <div class="col-12 mt-2">
                      <div class="d-flex align-items-center mb-1">
                        <label class="form-label ms-0 mb-0 me-3">
                          {{ $t("content.amnoldmessage") }}
                        </label>
                      </div>
                      <Textarea rows="2" v-model="oldremark" class="w-100" disabled />
                    </div>

                    <div class="col-12 mt-3">
                      <div class="d-flex align-items-center mb-1">
                        <label class="form-label ms-0 mb-0 me-3">
                          {{ $t("content.orderinfo") }}
                        </label>
                      </div>
                      <Panel>
                        <div class="table-responsive">
                          <table class="table table-sm table-hover">
                            <thead>
                              <tr>
                                <th>#</th>
                                <th>{{ $t("content.amnassetgroup") }}</th>
                                <th>{{ $t("content.amnweightval") }}</th>
                                <th>{{ $t("content.amnqtyunit") }}</th>
                                <th>{{ $t("content.amnanalysval") }}</th>
                                <th>{{ $t("content.amnmaxval") }}</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr v-for="(row, index) in listinfo" :key="index" @click="copyListInfoDetail(row)">
                                <td class="text-sm font-weight-normal">{{ formatNumberString(index + 1) }}</td>
                                <td class="text-sm font-weight-normal">{{ filterCat(row.mainCatUid) }}</td>
                                <td class="text-sm font-weight-normal">
                                  <span style="text-decoration:underline;color:red;">
                                    {{ formatNumberDecimalString(row.weight) }}
                                  </span>
                                </td>
                                <td class="text-sm font-weight-normal">
                                  <span style="text-decoration:underline;color:red;">
                                    {{ formatNumberString(row.quantity) }}
                                  </span>
                                </td>
                                <td class="text-sm font-weight-normal">
                                  <span style="text-decoration:underline;color:red;">
                                    {{ formatNumberDecimalString(row.pawnValue) }}
                                  </span>
                                </td>
                                <td class="text-sm font-weight-normal">
                                  <span style="text-decoration:underline;color:red;">
                                    {{ formatNumberDecimalString(row.fullValue) }}
                                  </span>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </Panel>
                    </div>

                    <div class="flex flex-wrap p-fluid mt-2">
                      <div class="flex-auto col-lg-4">
                        <label class="flex-auto form-label ms-0">
                          {{ $t("content.ticketdetail") }}
                        </label>

                        <small class="form-label ms-2" style="color:red;">
                          <span @click="selectFlaw()" style="text-decoration: underline;color:red; cursor: pointer">
                            {{ $t("content.defect") }}
                          </span>
                        </small>
                      </div>
                      <div class="card mb-3" v-if="openFlaw">
                        <Panel>
                          <template #header>
                            <span> <label class="flex-auto form-label ms-0">{{ $t("content.select") }} {{
                              $t("content.defect") }}</label></span>
                          </template>
                          <template #icons>
                            <Button severity="secondary" rounded text class="btnClose" @click="selectFlaw()">
                              <i class="material-icons material-symbols-outlined">close</i>
                            </Button>
                          </template>
                          <template #footer>
                            <div class="d-grid gap-2 d-md-flex justify-content-md-end">
                              <button class="btn btn-primary btn-sm" type="button" @click="flawSelection()"> {{ save
                                }}</button>
                            </div>
                          </template>
                          <div class="grid-container">
                            <div v-for="option in listflaw" :key="option" class="d-inline-block mx-4">
                              <input type="checkbox" v-model="option.chk" :check="option.chk" /><label
                                class="flex-auto form-label ms-2">
                                {{ option.name }}</label>
                            </div>
                          </div>

                        </Panel>
                      </div>
                      <material-input v-model="collect_detail.remark" autocomplete="off" class="w-100" />
                    </div>
                  </div>
                </fieldset>
              </div>

              <div class="modal-footer justify-content-between">
                <div>
                  <button type="button" class="btn btn-danger" data-bs-dismiss="modal" id="newPawnClose">
                    {{ cancel }}
                  </button>
                </div>


                <button type="button" class="btn btn-primary" :disabled="(collect_detail.mainCatUid === '' || collect_detail.mainCatUid === '0') || (collect_detail.quantity === '' || collect_detail.quantity === '0') || (collect_detail.newPawnValue === '' || collect_detail.newPawnValue === '0')
                  || filterText === '' || collect_detail.unitUid === '0'
                  || collect_detail.remark.trim() === '' || (parseFloat(balprice) === 0 && process_c === 'add') || (parseFloat(balquanity) === 0 && process_c === 'add')
                  || (collect_detail.weight !== '' && collect_detail.weight !== '0' && parseFloat(collect_detail.weight) > balweight && process_c === 'add')
                  || (collect_detail.newPawnValue !== '' && parseFloat(collect_detail.newPawnValue) > balprice && process_c === 'add')
                  || (collect_detail.quantity !== '' && parseFloat(collect_detail.quantity) > balquanity && process_c === 'add')
                  || (collect_detail.fullValue !== '' && parseFloat(collect_detail.fullValue) > balfull && process_c === 'add')
                  // || (collect_detail.diamondQuantity !== '' && collect_detail.diamondQuantity !== '0' && parseFloat(collect_detail.diamondQuantity) > baldiamond && process_c === 'add')
                  || (process_c === 'edit' && collect_detail.weight !== '' && collect_detail.weight !== '0' && (parseFloat(collect_detail.weight) > parseFloat(collec_e.weight) > balweight))
                  || (process_c === 'edit' && collect_detail.newPawnValue !== '' && collect_detail.newPawnValue !== '0' && (parseFloat(collect_detail.newPawnValue) > parseFloat(collec_e.pawnValue) > balprice))
                  || (process_c === 'edit' && collect_detail.quantity !== '' && collect_detail.quantity !== '0' && (parseFloat(collect_detail.quantity) > parseFloat(collec_e.quantity) > balquanity))
                  || (process_c === 'edit' && collect_detail.fullValue !== '' && collect_detail.fullValue !== '0' && (parseFloat(collect_detail.fullValue) > parseFloat(collec_e.fullValue) > balfull))
                  // || (process_c === 'edit' && collect_detail.diamondQuantity !== '' && collect_detail.diamondQuantity !== '0' && (parseFloat(collect_detail.diamondQuantity) > parseFloat(collec_e.diamondQuantity) > baldiamond))
                  " @click="chkProess">
                  {{ save }}
                </button>
              </div>
            </div>
          </div>
        </div>


        <!-- View List in Pawn Ticket Dialog -->
        <div id="viewPawnListModal" class="modal fade" tabindex="-1" aria-labelledby="viewPawnListModalLabel"
          aria-hidden="true">
          <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
              <div class="modal-header">
                <h4 class="modal-title">{{ $t("content.amnsplitlist") }}</h4>
                <button type="button" class="close" data-bs-dismiss="modal" aria-label="Close">
                  <i class="material-icons material-symbols-outlined">close</i>
                </button>
              </div>

              <div class="modal-body">

                <div class="row">
                  <div class="col-lg-4">
                    <label class="form-label ms-0">{{
                      $t("content.amnassetgroup")
                    }}</label>
                    <select v-model="collect_detail.mainCatUid" class="form-select" disabled>
                      <option value="0" selected disabled>{{ $t("content.select") }}</option>
                      <option v-for="(item, index) in listprint" :value="item.uid" :key="index">
                        {{ item.name }}
                      </option>
                    </select>
                  </div>

                  <div class="col-lg-8">
                    <label class="form-label ms-0">{{ $t("content.amnassetgroup") }}</label>
                    <materialInput v-model="collect_detail.categoryName" disabled />
                  </div>
                </div>

                <div class="row flex flex-wrap p-fluid">
                  <div class="col-4 mt-2">
                    <div class="d-flex align-items-center mb-1">
                      <label class="form-label ms-0 mb-0 me-3">
                        {{ $t("content.amnqtyunittick") }}
                      </label>
                    </div>
                    <InputNumber v-model="collect_detail.quantity" disabled fluid />
                  </div>




                  <div class="col-lg-2 mt-2">
                    <div class="d-flex align-items-center mb-1">
                      <label class="form-label ms-0 mb-0 me-3">
                        {{ $t("content.munit") }}
                      </label>
                    </div>

                    <select v-model="collect_detail.unitUid" class="form-select" disabled>
                      <option value="0" selected disabled>{{ $t("content.select") }}</option>
                      <option v-for="(item, index) in listunit" :value="item.uid" :key="index">
                        {{ item.name }}
                      </option>
                    </select>
                  </div>

                  <!-- <div class="col-lg-3">
                    <label class="form-label ms-0">{{ $t("content.amnqtydiamond") }}</label>
                    <InputNumber v-model="collect_detail.diamondQuantity" disabled />
                  </div> -->

                  <div class="col-lg-6 mt-2">
                    <div class="d-flex align-items-center mb-1">
                      <label class="form-label ms-0 mb-0 me-3">
                        Serial Number
                      </label>
                    </div>
                    <material-input id="serialPawn" v-model="collect_detail.serialNumber" autocomplete="off" disabled />
                  </div>

                </div>

                <div class="row">
                  <div class="col-4 mt-2">
                    <div class="d-flex align-items-center mb-1">
                      <label class="form-label ms-0 mb-0 me-3">
                        {{ $t("content.amnhdweightall") }}
                      </label>
                    </div>
                    <InputNumber v-model="collect_detail.weight" :minFractionDigits="0" :maxFractionDigits="2" disabled
                      fluid />
                  </div>

                  <div class="col-4 mt-2">
                    <div class="d-flex align-items-center mb-1">
                      <label class="form-label ms-0 mb-0 me-3">
                        {{ $t("content.amnanalysval") }}
                      </label>
                    </div>
                    <InputNumber v-model="collect_detail.newPawnValue" :minFractionDigits="0" :maxFractionDigits="2"
                      disabled fluid />
                  </div>


                  <div class="col-4 mt-2">
                    <div class="d-flex align-items-center mb-1">
                      <label class="form-label ms-0 mb-0 me-3">
                        {{ $t("content.amnmaxval") }}
                      </label>
                    </div>
                    <InputNumber v-model="collect_detail.fullValue" :minFractionDigits="0" :maxFractionDigits="2"
                      disabled fluid />
                  </div>

                </div>

                <div class="col-12 mt-2">
                  <div class="d-flex align-items-center mb-1">
                    <label class="form-label ms-0 mb-0 me-3">
                      {{ $t("content.amnoldmessage") }}
                    </label>
                  </div>
                  <Textarea rows="3" v-model="oldremark" class="w-100" disabled />
                </div>

                <div class="col-12 mt-2">
                  <div class="d-flex align-items-center mb-1">
                    <label class="form-label ms-0 mb-0 me-3">
                      {{ $t("content.ticketdetail") }}
                    </label>
                  </div>
                  <material-input v-model="collect_detail.remark" autocomplete="off" class="w-100" disabled />
                </div>
              </div>
            </div>
          </div>
        </div>


        <!-- QR Code Modal -->
        <div id="qrcodeModal" class="modal fade" tabindex="-1" aria-labelledby="viewQRcodeModalLabel"
          data-bs-keyboard="false" data-bs-backdrop="static" aria-hidden="true">
          <div class="modal-dialog modal-dialog-centered modal-lg " style="max-width: 600px">
            <div class="modal-content">
              <div class="modal-header">
                <h4 class="modal-title">{{ $t("content.qrscan") }}</h4>
                <button type="button" class="close" data-bs-dismiss="modal" aria-label="Close" id="closeqr"
                  @click="paused = true">
                  <i class="material-icons material-symbols-outlined">close</i>
                </button>
              </div>
              <div class="modal-body">

                <div class="row">
                  <div class="col-lg-12">
                    <p class="decode-result">
                      {{ $t("content.orderresult") }} : <b>{{ result }}</b>
                    </p>
                    <p class="error">{{ error }}</p>
                  </div>

                  <div class="col-lg-12">

                    <qrcode-stream :paused="paused" @detect="onDetect" @error="onError"
                      @camera-on="resetValidationState" :constraints="selectedConstraints"
                      :track="trackFunctionSelected.value">
                    </qrcode-stream>
                  </div>

                </div>
              </div>
            </div>
          </div>
        </div>


        <!-- Add File Dialog -->
        <div id="attachmentsModal" class="modal fade" tabindex="-1" aria-labelledby="unseizeModalLabel"
          aria-hidden="true">
          <div class="modal-dialog modal-dialog-centered modal-lg" style="max-width: 600px">
            <div class="modal-content">
              <div class="modal-header">
                <h4 class="modal-title">{{ $t("content.file") }}</h4>
                <button type="button" class="close" data-bs-dismiss="modal" aria-label="Close">
                  <i class="material-icons material-symbols-outlined">close</i>
                </button>
              </div>

              <div class="modal-body">
                <fieldset>
                  <div class="row">
                    <div class="col-lg-12">
                      <div class="d-flex align-items-center mb-1">
                        <!-- <label class="form-label ms-0 mb-0 me-3">
                          {{ $t("content.file") }}
                        </label> -->
                      </div>
                      <input id="upload" type="file" accept="image/*" @change="addFile" />
                    </div>
                  </div>
                  <pre></pre>
                  <div class="row">
                    <div class="col-lg-12">
                      <div class="bank-logo d-flex align-items-center mb-1" v-if="preview !== ''">
                        <img :src="preview" alt="" :width="picWidth" :height="picHeight" aspect-ratio="1" />
                      </div>
                    </div>
                  </div>
                </fieldset>
              </div>

              <div class="modal-footer justify-content-between">
                <button type="button" class="btn btn-danger" data-bs-dismiss="modal" @click="thumb = null">
                  {{ cancel }}
                </button>
                <button type="button" class="btn btn-primary" data-bs-dismiss="modal">
                  {{ save }}
                </button>
              </div>
            </div>
          </div>
        </div>



        <!-- New Finger Scan Dialog  -->
        <div id="fingerModalNew" class="modal fade" tabindex="-1" aria-labelledby="fingerModalLabel" aria-hidden="true">
          <div class="modal-dialog modal-dialog-centered modal-sm" style="max-width: 500px;height:auto">
            <div class="modal-content">
              <div class="modal-header justify-content-center">
                <h5 class="modal-title text-center">{{ $t("content.amnfingerscan") }} : {{ cus_detail.nameTh }}</h5>
              </div>

              <div class="modal-body">
                <h5 v-if="template_1 !== '' && template_2 !== ''" class="text-center mt-2"
                  :class="matchResult > idQuality ? `text-success` : `text-danger`">
                  MATCH SCORE : {{ matchResult }}
                </h5>
                <section class="panel">
                  <div class="row" v-if="bmpBase64f_1 !== '' || bmpBase64f_2 !== ''">
                    <div class="col-12">

                    </div>
                    <div class="col-6">
                      <figure class="figure w-100">
                        <figcaption class="figure-caption text-center">
                          <b>Before</b>
                        </figcaption>
                        <img id="FPImage1" :src="Finger1" class="img img-fluid figure-img rounded" width="300"
                          height="250" />
                      </figure>
                    </div>
                    <div class="col-6">
                      <figure class="figure w-100">
                        <figcaption class="figure-caption text-center">
                          <b>After</b>
                        </figcaption>
                        <img id="FPImage2" class="img img-fluid figure-img rounded" width="300" height="250" />
                      </figure>
                    </div>
                  </div>
                  <div class="row" v-else>
                    <div class="col-12 text-center">
                      <figure class="figure w-100">
                        <figcaption class="figure-caption text-center">
                        </figcaption>
                        <img id="FPImage2" class="img img-fluid figure-img rounded" width="300" height="250" />
                      </figure>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-12 text-center">
                      <button type="button" class="btn btn-info btn-lg" :disabled="secugen_lic === ''"
                        @click="CallSGIFPGetData(SuccessFunc2, ErrorFunc)">
                        {{ datas.scanStep ? newscan : startscan }}
                      </button>

                    </div>
                  </div>
                </section>
              </div>

              <div class="modal-footer" v-if="workcaseDetail !== null">
                <span class="me-auto" v-if="workcaseDetail.migration === false">
                  <button type="button" class="btn btn-outline-warning" @click="requestOTP"
                    v-show="count_fg >= 3 && template_2 !== ''">
                    {{ $t(`content.reqotpcode`) }}
                  </button>
                </span>
                <span class="me-auto" v-else>
                  <button type="button" class="btn btn-outline-secondary" @click="requestOTP"
                    v-show="cus_detail.cusShop.fgTemplate1Uid !== '0'">
                    {{ $t(`content.reqotpcode`) }}
                  </button>
                </span>
                <button type="button" class="btn btn-primary" data-bs-dismiss="modal" @click="finger = true" :disabled="((matchResult < idQuality && template_1 !== '') && secugen_lic !== '')
                  || ((template_1 === '' && template_2 === '') && secugen_lic !== '')">
                  {{ confirm }}
                </button>
              </div>
            </div>
          </div>
        </div>


        <!-- Search Sanction Dialog -->
        <div id="searchSanctionModal" class="modal fade" tabindex="-1" aria-labelledby="searchSanctionModalLabel"
          aria-hidden="true">
          <div class="modal-dialog modal-xl modal-dialog-centered" style="max-width:1240px;">
            <div class="modal-content">
              <div class="modal-header">
                <h4 class="modal-title text-center">
                  <span style="color:red;">
                    {{ $t('content.sanctionmsg') }} {{ $t('content.millegal') }}
                  </span>
                </h4>
                <button type="button" class="close" data-bs-dismiss="modal" aria-label="Close" id="closeModal">
                  <i class="material-icons material-symbols-outlined">close</i>
                </button>
              </div>

              <div class="modal-body">
                <div class="preview-table">
                  <section class="panel">
                    <table class="table table-striped table-responsive">
                      <thead>
                        <tr>
                          <th>#</th>
                          <th>{{ $t('content.serial') }}</th>
                          <th>{{ $t('content.refer') }}</th>
                          <th>{{ $t('content.docnum') }}</th>
                          <th>{{ $t('content.docdate') }}</th>
                          <th>{{ $t('content.criminal') }}</th>
                          <th>{{ $t('content.agency') }}</th>
                          <th>{{ $t('content.defect') }}</th>
                          <th>{{ $t('content.detail') }}</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="(row, index) in wanninglist" :key="index">
                          <td class="text-sm font-weight-normal">
                            {{ formatNumberString(index + 1) }}
                          </td>
                          <td class="text-sm font-weight-normal">
                            {{ row.serialNumber }}
                          </td>
                          <td class="text-sm font-weight-normal">
                            {{ row.reportBy }}
                          </td>
                          <td class="text-sm font-weight-normal">
                            {{ row.docNumber }}
                          </td>
                          <td class="text-sm font-weight-normal">
                            {{ format_dates(row.docDate) }}
                          </td>
                          <td class="text-sm font-weight-normal">
                            {{ row.caseNumber }}
                          </td>
                          <td class="text-sm font-weight-normal">
                            {{ row.source }}
                          </td>
                          <td class="text-sm font-weight-normal">
                            {{ row.flawMark }}
                          </td>
                          <td class="text-sm font-weight-normal">
                            {{ row.remark }}
                          </td>
                        </tr>
                        <tr v-if="wanninglist.length === 0">
                          <td colspan="5" class="text-sm font-weight-normal">
                            <p style="text-align: center">No Record Found</p>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </section>
                </div>
              </div>
              <div class="modal-footer">
              </div>
            </div>
          </div>
        </div>


        <!-- OTP Dialog -->
        <div id="otpModal" class="modal fade" tabindex="-1" aria-labelledby="otpModalLabel" aria-hidden="true"
          data-bs-keyboard="false" data-bs-backdrop="static">
          <div class="modal-dialog" style="max-width: 800px">
            <div class="modal-content">
              <div class="modal-header">
                <h4 class="modal-title text-center">{{ $t("content.amnotpcode") }}</h4>
                <button v-show="false" type="button" class="close" data-bs-dismiss="modal" aria-label="Close"
                  id="closeOtp">
                  <i class="material-icons material-symbols-outlined">close</i>
                </button>
              </div>
              <div class="modal-body">
                <p class="text-center mt-3" v-if="otpMsg !== '' && otpMsg.indexOf('ไม่สำเร็จ') > -1">
                  <span style="color: red">
                    {{ otpMsg }}
                  </span>
                </p>
                <p class="text-center mt-3" v-else-if="otpMsg !== ''">
                  <span style="color: green">
                    {{ otpMsg }}
                  </span>
                </p>
                <p class=" text-center mt-3">
                  {{ $t(`content.amnotpref`) }} :
                  <span style="color: blue">
                    {{ otpRef }}
                  </span>
                </p>
                <section class="panel">
                  <div class="d-flex justify-content-center mt-3">
                    <InputOtp v-model="otpValue" integerOnly :length="6" style="gap: 1" size="large" />
                  </div>
                  <p class="text-center mt-3">
                    {{ $t(`content.amnotptime`) }}
                    <span style="color: #05472A" id="timer-otp"></span>
                    {{ $t(`content.amnmin`) }}
                  </p>
                </section>

                <pre></pre>
                <pre></pre>
                <pre></pre>


                <div style="position: relative;width:100%;">
                  <div style="position:absolute;left:0px;">
                    <button type="button" class="btn btn-warning" :disabled="otpResend" @click="generateOTP('resend')">
                      {{ $t(`content.resend_otp`) }}
                    </button>&nbsp;
                    <span v-show="otpResend">
                      {{ $t(`content.reotptime`) }}
                      <span style="color: #05472A" id="timer-resend"></span>
                      {{ $t(`content.amnmin`) }}</span>
                  </div>

                  <div style="position:absolute;right:0px;">
                    <button type="button" class="btn btn-primary" data-dismiss="modal"
                      :disabled="String(otpValue).length !== 6" @click="validateOTP">
                      {{ confirm }}{{ $t("content.amnotpcode") }}
                    </button>
                  </div>
                </div>
              </div>

              <pre></pre>
              <pre></pre>
              <pre></pre>

              <hr />

              <p class="text-center mt-2">
                <span @click="onOpenFingerScan()" style="text-decoration: underline;color:blue; cursor: pointer">
                  {{ $t("content.amnfingerprint") }}
                </span>
              </p>
              <pre></pre>
            </div>
          </div>
        </div>




      </div>
    </div>
  </div>
</template>

<script setup>
/* eslint-disable no-unused-vars */
import InputOtp from 'primevue/inputotp';
import Modal from 'bootstrap/js/dist/modal';
import ProgressSpinner from 'primevue/progressspinner';
import { QrcodeStream } from 'vue-qrcode-reader'
import Textarea from 'primevue/textarea';
import InputMask from 'primevue/inputmask';
import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css'
import Select from 'primevue/select';
import InputNumber from 'primevue/inputnumber';
import MaterialTextarea from "@/components/MaterialTextarea.vue";
import Panel from 'primevue/panel';
import { reactive, inject, computed, onMounted, ref, onUpdated } from "vue";
import { useI18n } from "vue-i18n";
import * as Choices from "choices.js";
import NewCamera from "../../examples/WebCam/NewCamera.vue";
import MaterialButton from "@/components/MaterialButton.vue";
import MaterialInput from "@/components/MaterialInput.vue";
import MaterialCheckbox from "@/components/MaterialCheckbox.vue";
import nofinger from "@/assets/img/noimg.png"
import { VueImageZoomer } from 'vue-image-zoomer'
import 'vue-image-zoomer/dist/style.css';



import axios from "axios";
import * as st from "@/config/setting.js";
import moment from 'moment'

import { useWorkItemStore } from '@/stores/workItem'
import { useCategoryStore } from '@/stores/category'
import { useUniteStore } from '@/stores/unit'
import { useCollateralStore } from '@/stores/collateral'
import { useDocumentStore } from '@/stores/document'
import { useTicketImageStore } from '@/stores/ticketimage'
import { useFlawtStore } from '@/stores/flaw'
import { useCashAccountStore } from '@/stores/cashaccount'
import { useDocTypeStore } from '@/stores/doctype'
import { useCusTypeStore } from '@/stores/custype'
import { useCountryStore } from '@/stores/country'
import { useDistrictStore } from '@/stores/district'
import { useSubdistrictStore } from '@/stores/subdistrict'
import { usePostCodeStore } from '@/stores/postcode'
import { useProvinceStore } from '@/stores/province'
import { useCustomerStore } from '@/stores/customer'
import { useCusShopStore } from '@/stores/cusshop'
import { useWorkCaseStore } from '@/stores/workcase'
import { useCusAffiliateStore } from '@/stores/cusaffiliate'
import { usePaymentGroupStore } from '@/stores/paymentgroup'
import { useSanctionAssetsStore } from '@/stores/sanctionassets'
import { useUserSecurityStore } from "@/stores/usersecurity";




const storew = useWorkItemStore()
const storec = useCategoryStore()
const storeu = useUniteStore()
const storecl = useCollateralStore()
const stored = useDocumentStore()
const storeti = useTicketImageStore()
const storetf = useFlawtStore()
const storeca = useCashAccountStore()
const storedt = useDocTypeStore()
const storect = useCusTypeStore()
const storecountry = useCountryStore()
const storedistrict = useDistrictStore()
const storesd = useSubdistrictStore()
const storepc = usePostCodeStore()
const storep = useProvinceStore()
const storecus = useCustomerStore()
const storecussh = useCusShopStore()
const storewc = useWorkCaseStore()
const storecusa = useCusAffiliateStore()
const storepay = usePaymentGroupStore()
const storeass = useSanctionAssetsStore()
const userSStore = useUserSecurityStore();


const format = (date) => {
  return moment(String(date)).local().format('DD/MM/YYYY');
}

const { t } = useI18n();
const swal = inject("$swal");


const selectGroup = ref(false);
const selectType = ref(false);
const hideCam = ref(false);

const picWidth = ref(400);
const picHeight = ref(400);

const datas = reactive({
  hide: false,
  scanStep: false,
});

///// computed
const add = computed(() => t("buttons.addd"));
const save = computed(() => t("buttons.save"));
const cancel = computed(() => t("buttons.canc"));
const edit = computed(() => t("buttons.edit"));
// const checked = computed(() => t("content.amnrecheckdone"));
const seed = computed(() => t("buttons.seed"));
const confirm = computed(() => t("buttons.conf"));
const startscan = computed(() => t("buttons.sscan"));
const newscan = computed(() => t("buttons.nscan"));
const selects = computed(() => t("content.amnselectlist"));

const close = computed(() => t("content.close"));
const btnAddNew = computed(() => t("content.amnaddnew"));



const toggleInfo = () => {
  if (!datas.hide) {
    datas.hide = true;
  } else {
    datas.hide = false;
  }
};

const openCamera = () => {
  hideCam.value = true;
};

const images = ref([])


const listQ02 = ref([]);
const listQ02O = ref([]);
const listprint = ref([]);
const listQ02E = ref({});
const listQ02D = ref([]);
const uid_edit = ref('');
const collec_e = ref({});


const listunit = ref([]);
const listflaw = ref([]);

const balweight = ref(0);
const balweight_s = ref('');

const balprice = ref(0);
const balprice_s = ref('');

const balfull = ref(0);
const balfull_s = ref('');

const diff = ref(0);
const diff_s = ref('');


const balquanity = ref(0);
const balquanity_s = ref('');

const baldiamond = ref(0);
const baldiamond_s = ref('');

const shop_detail = ref(null)

const collect_detail = ref({
  uid: '',
  ticketUid: '',
  mainCatUid: 'no',
  mainCatCode: '',
  mainCatName: '',
  categoryUid: '',
  categoryCode: '',
  categoryName: '',
  weight: '',
  pawnValue: '',
  newPawnValue: '',
  tmpPawnValue: '',
  tmpNewPawnValue: '',
  fullValue: '',
  diamondQuantity: '',
  quantity: '',
  unitUid: '0',
  unitName: '',
  remark: '',
  collateralGroupUid: '',
  version: '',
  serialNumber: '',
});

const cus_detail = ref({
  nameTh: '',
  nameEn: '',
  birthOrRegDate: new Date(),
  address: {
    address1: "",
    subDistrict: "",
    district: "",
    province: "",
    postcode: "",
    country: ""
  },
  issuedBy: '',
  issuedDate: new Date(),
  expiredDate: new Date(),
  version: '',
  age: 0,
  docTypeUid: '0',
  docNumber: '',
  lifetime: false,
  cusShop: {
    cusTypeUid: "0",
    creditAmount: 0,
    email: "",
    phoneNo1: "",
    phoneNo2: "",
    phoneNo3: "",
    fingerPrint1Uid: "0",
    fingerPrint2Uid: "0",
    fgTemplate1Uid: "0",
    fgTemplate2Uid: "0",
    warningMsg: "",
    notifyWarning: false,
  }
})


const oldremark = ref("");
const listinfo = ref([]);

const thumb_id = ref("");
const thumb = ref(null);
const preview = ref("");

const process_c = ref(null);
const workItem_uid = ref('');
const workItem_version = ref(null);

const finger = ref(false);
const images_l = ref([])

const groupuid = ref("");
const typeTrans = ref("");
const photoFile = ref(null);

const otpValue = ref("");
const otpTime = ref(null);
const otpResend = ref(null);
const otpMsg = ref('');
const otpRef = ref('');
const otpValidate = ref(0);
const otpReTime = ref(0);

const tplist = ref([])


// infinite scroll
var input = (ref < HTMLInputElement) | (null > null);
const loadMoreStatus = ref("idle");
const parentRef = ref(null);
const list = ref(null);

const dataList = ref([]);
const loading = ref(false);
const showSuggestions = ref(false);
const limit = 10;
const page_s = ref(0);
const page_e = ref(10);
const filterText = ref("");
const filterInput = ref("");
const typesNew = ref([]);
const selected = ref("");
const textt = ref("");
const Listdata = ref([]);
const types = ref([]);

const parentWidth = ref(0);
const childWidth = ref(0);

const openFlaw = ref(false);

const custypeList = ref([])
const doctypeAccList = ref([])
const listCountry = ref([]);
const listPostcode = ref([]);
const listProvince = ref([]);
const listDistrict = ref([]);
const listSubDistrict = ref([]);
const workcaseDetail = ref(null);
const interestRate_s = ref('');

const isValid = ref(undefined)
const paused = ref(true)
const result = ref(null)
const error = ref('')

const qr_serach = ref('');
const qr_worckcaseUid = ref('');

const qr_serach2 = ref('');
const qr_worckcaseUid2 = ref('');

const loading_d = ref(false);
const activeTab = ref('tab1');

const migrateRefNumber = ref('');

const currentPageC = ref(1);
const totalRowsC = ref(1);
const perPageC = ref(10);
const totalRows_sC = ref();

const nameThC = ref('')
const nameEnC = ref('')
const docNumberC = ref('')

const cusUid_search = ref('');
const cusName_search = ref('');
const customerlist = ref([]);


const Finger1 = ref(null);
const template_1 = ref('');
const template_2 = ref('');
const bmpBase64 = ref('');
const matchResult = ref(0);
const templatef_1 = ref('');
const templatef_2 = ref('');
const bmpBase64f_1 = ref('');
const bmpBase64f_2 = ref('');
const urls = ref([]);
const secugen_lic = ref('');
const idQuality = ref(100);
const count_scan = ref(0);
const paymentGDetail = ref(null);

const wanninglist = ref([]);

const fingertrans_uid = ref('0');
const fingertrans_temp = ref('0');

const promoint_duration = ref('');
const promoint_val = ref('');

const count_fg = ref(0);


const onClickHandlerC = () => {
  updatePaginationC()
};

const selectedConstraints = ref({ facingMode: 'environment' })

const defaultConstraintOptions = [
  { label: 'rear camera', constraints: { facingMode: 'environment' } },
  { label: 'front camera', constraints: { facingMode: 'user' } }
]
const constraintOptions = ref(defaultConstraintOptions)

/*** track functons ***/

function paintOutline(detectedCodes, ctx) {
  for (const detectedCode of detectedCodes) {
    const [firstPoint, ...otherPoints] = detectedCode.cornerPoints

    ctx.strokeStyle = 'red'

    ctx.beginPath()
    ctx.moveTo(firstPoint.x, firstPoint.y)
    for (const { x, y } of otherPoints) {
      ctx.lineTo(x, y)
    }
    ctx.lineTo(firstPoint.x, firstPoint.y)
    ctx.closePath()
    ctx.stroke()
  }
}
function paintBoundingBox(detectedCodes, ctx) {
  for (const detectedCode of detectedCodes) {
    const {
      boundingBox: { x, y, width, height }
    } = detectedCode

    ctx.lineWidth = 2
    ctx.strokeStyle = '#007bff'
    ctx.strokeRect(x, y, width, height)
  }
}
function paintCenterText(detectedCodes, ctx) {
  for (const detectedCode of detectedCodes) {
    const { boundingBox, rawValue } = detectedCode

    const centerX = boundingBox.x + boundingBox.width / 2
    const centerY = boundingBox.y + boundingBox.height / 2

    const fontSize = Math.max(12, (50 * boundingBox.width) / ctx.canvas.width)

    ctx.font = `bold ${fontSize}px sans-serif`
    ctx.textAlign = 'center'

    ctx.lineWidth = 3
    ctx.strokeStyle = '#35495e'
    ctx.strokeText(detectedCode.rawValue, centerX, centerY)

    ctx.fillStyle = '#5cb984'
    ctx.fillText(rawValue, centerX, centerY)
  }
}
const trackFunctionOptions = [
  { text: 'nothing (default)', value: undefined },
  { text: 'outline', value: paintOutline },
  { text: 'centered text', value: paintCenterText },
  { text: 'bounding box', value: paintBoundingBox }
]
const trackFunctionSelected = ref(trackFunctionOptions[1])

const copyQty = () => {
  collect_detail.value.quantity = balquanity.value
  setRemark()
};

const copyDiamond = () => {
  collect_detail.value.diamondQuantity = baldiamond.value
};

const copyWeight = () => {
  collect_detail.value.weight = balweight.value
  setRemark()
};

const copyPrice = () => {
  collect_detail.value.newPawnValue = balprice.value
};

const copyFull = () => {
  collect_detail.value.fullValue = balfull.value
};

const copyListInfoDetail = (item) => {

  if (item.weight !== null) {
    collect_detail.value.weight = item.weight
  }

  if (item.quantity !== null) {
    collect_detail.value.quantity = item.quantity
  }

  if (item.pawnValue !== null) {
    collect_detail.value.newPawnValue = item.pawnValue
  }

  if (item.fullValue !== null) {
    collect_detail.value.fullValue = item.fullValue
  }

  setRemark()
}


const selectFlaw = () => {
  // console.log('listflaw : ' + JSON.stringify(listflaw.value))
  // console.log('selectFlaw')
  if (listflaw.value.length > 0) {
    if (openFlaw.value) {
      openFlaw.value = false
      if (listflaw.value.length > 0) {
        for (let i = 0; i < listflaw.value.length; i++) {
          listflaw.value[i].chk = false
        }
      }
    } else {
      openFlaw.value = true
    }
    // document.getElementById("defectsBtn").click();
    // console.log('listflaw : ' + JSON.stringify(listflaw.value))

  }
}

const flawSelection = () => {
  let fdt = ''
  if (listflaw.value.length > 0) {
    for (let i = 0; i < listflaw.value.length; i++) {
      if (listflaw.value[i].chk) {
        // console.log('flawSelection : ' + JSON.stringify(listflaw.value[i]))
        if (fdt === '') {
          fdt = listflaw.value[i].name
        } else {
          fdt = fdt + ' ' + listflaw.value[i].name
        }
      }
    }
    setRematkDetail()
    if (fdt !== '') {

      if (collect_detail.value.remark !== '') {
        collect_detail.value.remark = collect_detail.value.remark + ' ' + fdt
      } else {
        collect_detail.value.remark = fdt
      }

      if (collect_detail.value.serialNumber !== '') {
        setSerialDetail()
      }
    }
    selectFlaw()
  }

}

const setRemark = () => {
  setRematkDetail()
  if (collect_detail.value.serialNumber !== '') {
    setSerialDetail()
  }
}


const setRematkDetail = () => {
  let dt = ''
  // console.log("mainCatUid : " + collect_detail.value.mainCatUid);
  if (collect_detail.value.mainCatUid !== 'no') {
    let ld = listprint.value.filter((c) => c.uid === collect_detail.value.mainCatUid);
    // console.log("ld : " + ld.length);
    if (ld.length > 0) {
      // let ld = listprint.value.filter((c) => c.name.toLowerCase().indexOf(selected.value.t1.toLowerCase()) >= 0);
      if (ld[0].name !== 'อื่นๆ') {
        if (selected.value === '') {
          let sl = types.value.filter((c) => c.fullName.toLowerCase().indexOf(filterText.value.trim().toLowerCase()) >= 0);
          if (sl.length > 0) {
            selected.value = sl[0]
          }
        }
        // console.log("ld : " + JSON.stringify(ld[0]));
        if (selected.value !== '') {
          if (ld[0].l2Print) {
            if (selected.value.t2 !== '') {
              dt = selected.value.t2
            }

          }
          if (ld[0].l3Print) {
            if (selected.value.t3 !== '') {
              if (dt === '') {
                dt = selected.value.t3
              } else {
                dt = dt + ' ' + selected.value.t3
              }
            }

          }
          if (ld[0].l4Print) {
            if (selected.value.t4 !== '') {
              if (dt === '') {
                dt = selected.value.t4
              } else {
                dt = dt + ' ' + selected.value.t4
              }
            }

          }
          if (ld[0].l5Print) {
            if (selected.value.t5 !== '') {
              if (dt === '') {
                dt = selected.value.t5
              } else {
                dt = dt + ' ' + selected.value.t5
              }
            }

          }
          if (ld[0].l6Print) {
            if (selected.value.t6 !== '') {
              if (dt === '') {
                dt = selected.value.t6
              } else {
                dt = dt + ' ' + selected.value.t6
              }
            }
          }
        }

      } else {
        dt = filterText.value.trim()
      }
    }
    // console.log("ld : " + JSON.stringify(ld));
  }



  if (collect_detail.value.quantity !== '0' && collect_detail.value.quantity !== '' && parseFloat(collect_detail.value.quantity) > 0) {
    if (dt === '') {
      dt = collect_detail.value.quantity
    } else {
      dt = dt + ' ' + collect_detail.value.quantity
    }

    if (collect_detail.value.unitUid !== '' && collect_detail.value.unitUid !== '0') {

      if (dt === '') {
        dt = filterUnit(collect_detail.value.unitUid)
      } else {
        dt = dt + ' ' + filterUnit(collect_detail.value.unitUid)
      }
    }
  }
  if (collect_detail.value.weight !== '' && collect_detail.value.weight !== '0' && parseFloat(collect_detail.value.weight) > 0) {
    let weight = collect_detail.value.weight
    if (dt === '') {
      dt = '(' + formatNumberDecimalString(Number(weight)) + ' กรัม)'
    } else {
      dt = dt + ' (' + formatNumberDecimalString(Number(weight)) + ' กรัม)'
    }
  }

  collect_detail.value.remark = dt

  //   if (dt === '') {
  //     dt = '(' + collect_detail.value.serialNumber.trim() + ')'
  //   } else {
  //     dt = dt + ' (' + collect_detail.value.serialNumber.trim() + ')'
  //   }

  //   collect_detail.value.remark = dt
  // }
}

const setSerialDetail = () => {
  if (collect_detail.value.remark === '') {
    collect_detail.value.remark = '(' + collect_detail.value.serialNumber.trim() + ')'
  } else {
    collect_detail.value.remark = collect_detail.value.remark + ' (' + collect_detail.value.serialNumber.trim() + ')'
  }
}


const savephotoTaken = async (data) => {
  // console.log('savephotoTaken url', data)
  hideCam.value = false;
  photoFile.value = data
}


const closeCamera = (data) => {
  hideCam.value = false;
};


async function addFile(e) {
  thumb.value = null
  preview.value = ""
  if (e.target.files[0] !== undefined) {
    thumb.value = await toBase64(e.target.files[0])
    preview.value = URL.createObjectURL(e.target.files[0]);
  }

}

const toBase64 = file => new Promise((resolve, reject) => {
  const reader = new FileReader();
  reader.readAsDataURL(file);
  reader.onload = () => resolve(reader.result.replace('data:', '')
    .replace(/^.+,/, ''));
  reader.onerror = reject;
});


const loadDataQ02Api = async (val) => {
  // console.log("secugen_lic : " + secugen_lic.value);
  let data = "";
  data = {
    index: '0',
    size: '100',
  };

  const payload = data;
  // console.log("payload : " + JSON.stringify(payload));
  const response = await storew.listQ02Fetch(payload);
  // console.log("response : " + JSON.stringify(response.data));

  if (response) {
    if (response.status === 200) {
      if (response.data.rtnDesc === "success") {
        let listd = response.data.data.list;
        // console.log("listd : " + listd.length);
        let newL = []
        listd.map(function (x) {
          x.chk = false;
          x.start = null
          x.payside = null
          x.weight_s = ''
          x.quantity_s = ''
          x.newPawnValue_s = ''
          x.fullValue_s = ''
          x.interest_s = ''
          x.trans = null
          return x
        });
        for (let i = 0; i < listd.length; i++) {
          if (listd[i].weight !== null && listd[i].newPawnValue !== null
            && listd[i].fullValue !== null && listd[i].quantity !== null && listd[i].roundInterest !== null
            && listd[i].totalPay !== null && listd[i].totalReceive !== null) {
            // console.log("if 1 " + i + ' : ' + JSON.stringify(listd[i]));
            listd[i].start = format_date(new Date(listd[i].startTime))
            listd[i].payside = checkPaySide(listd[i].paySide)
            listd[i].weight_s = formatNumberDecimalString(listd[i].weight)
            listd[i].quantity_s = formatNumberDecimalString(listd[i].quantity)
            listd[i].newPawnValue_s = formatNumberDecimalString(listd[i].newPawnValue)
            listd[i].fullValue_s = formatNumberDecimalString(listd[i].fullValue)
            listd[i].interest_s = formatNumberDecimalString(listd[i].roundInterest)
            listd[i].trans = checkTransaction(listd[i].type)
            newL.push(listd[i])
          } else {

            // console.log("qo2 start " + [i] + ' : ' + format_date(new Date(listd[i].startTime)) + ' jobid ' + listd[i].jobId);
            // console.log("qo2 null  : " + JSON.stringify(listd[i]));
          }
        }
        listQ02.value = newL
      }
    }
  }
}



loadDataQ02Api()


const loadDataQ02OwnerApi = async (val) => {
  let data = "";
  data = {
    index: '0',
    size: '100',
  };

  const payload = data;
  // console.log("payload : " + JSON.stringify(payload));
  const response = await storew.listQ02OwnerFetch(payload);


  if (response) {
    if (response.status === 200) {
      if (response.data.rtnDesc === "success") {
        let listd = response.data.data.list;
        let newL = []
        listd.map(function (x) {
          x.chk = false;
          x.start = null
          x.payside = null
          x.weight_s = ''
          x.quantity_s = ''
          x.newPawnValue_s = ''
          x.fullValue_s = ''
          x.interest_s = ''
          x.trans = null
          return x
        });
        for (let i = 0; i < listd.length; i++) {
          if (listd[i].weight !== null && listd[i].newPawnValue !== null
            && listd[i].fullValue !== null && listd[i].quantity !== null && listd[i].roundInterest !== null
            && listd[i].totalPay !== null && listd[i].totalReceive !== null) {
            // console.log("if 2 " + i + ' : ' + JSON.stringify(listd[i]));
            listd[i].start = format_date(new Date(listd[i].startTime))
            listd[i].payside = checkPaySide(listd[i].paySide)
            listd[i].weight_s = formatNumberDecimalString(listd[i].weight)
            listd[i].quantity_s = formatNumberDecimalString(listd[i].quantity)
            listd[i].newPawnValue_s = formatNumberDecimalString(listd[i].newPawnValue)
            listd[i].fullValue_s = formatNumberDecimalString(listd[i].fullValue)
            listd[i].interest_s = formatNumberDecimalString(listd[i].roundInterest)
            listd[i].trans = checkTransaction(listd[i].type)
            newL.push(listd[i])
          } else {
            // console.log("qo2 null  : " + JSON.stringify(listd[i]));
            // console.log("qo2 start " + [i] + ' : ' + format_date(new Date(listd[i].startTime)) + ' workcase ' + listd[i].workCaseUid);
          }
        }
        listQ02O.value = newL
      }
    }
  }
}


const loadDataPrintApi = async () => {
  // await new Promise((res) => setTimeout(res, 500));
  let data = "";
  data = {
    uid: "",
  };
  const payload = data;
  // const response = await store.dispatch("categorylistPrintFetch", payload);
  const response = await storec.categorylistPrintFetch(payload);
  if (response) {
    if (response.status === 200) {
      if (response.data.rtnDesc === "success") {
        listprint.value = response.data.data;
        // console.log('Listdata : ' + JSON.stringify(listprint.value))
      }
    }
  }
};




const loadDataUnitApi = async (val) => {

  let data = "";
  data = {
    index: 0,
    size: 100,
    name: '',
  };
  const payload = data;
  // const response = await store.dispatch("unitListFetch", payload);
  const response = await storeu.unitListFetch(payload);
  // console.log("loadDataApi : " + JSON.stringify(response));
  if (response) {
    if (response.status === 200) {
      if (response.data.rtnDesc === "success") {
        listunit.value = response.data.data.list;
      }
    }
  }


};



const loadDataApi = async () => {
  let data = "";
  data = {
    uid: 0,
  };
  const payload = data;

  const response = await storec.categoryfullNameFetch(payload);
  if (response) {
    if (response.status === 200) {
      if (response.data.rtnDesc === "success") {
        Listdata.value = response.data.data.list;
        // console.log('Listdata : '+JSON.stringify(response.data.data.list))
        if (Listdata.value.length > 0) {
          // console.log('newData : '+JSON.stringify(Listdata.value.length))
          types.value = [];
          checkdataMatch2(Listdata.value);
        }
      }
    }
  }
};



const cashAccShopApi = async (val) => {
  shop_detail.value = null
  const response = await storeca.cashaccountInfoFromSessionFetch();
  if (response) {
    if (response.status === 200) {
      if (response.data.rtnDesc === "success") {
        // console.log("shop_detail : " + JSON.stringify(response.data.data));
        shop_detail.value = response.data.data
        if (shop_detail.value.fscanLicense !== null) {
          secugen_lic.value = shop_detail.value.fscanLicense
        }

        if (shop_detail.value.fmatchScore !== 0) {
          idQuality.value = shop_detail.value.fmatchScore
        }

        loadCusTypeListApi()
      }
    }
  }
}



const loadCusTypeListApi = async (val) => {
  let data = "";
  data = {
    index: 0,
    size: 100,
    name: "",
    shopUid: shop_detail.value.uid
  };
  const payload = data;
  // console.log("payload : " + JSON.stringify(payload));
  const response = await storect.custypeListFetch(payload);
  // console.log("loadCashAccDataApi : " + JSON.stringify(response.data));
  if (response) {
    if (response.status === 200) {
      if (response.data.rtnDesc === "success") {
        custypeList.value = response.data.data.list;
      }
    }
  }
}

const loadDocTypeAccListApi = async (val) => {
  // dev : 601433176200681039 , uat : 635673099141979585 , prod : 635691584740292063
  doctypeAccList.value = []
  let data = '';
  data = {
    index: 0,
    size: 100,
    name: "",
    docGroupCode: '001'
  };

  const payload = data;
  const response = await storedt.docTypeListFetch(payload);
  // console.log("loadDocTypeListApi : " + JSON.stringify(response.data));
  if (response) {
    if (response) {
      if (response.status === 200) {
        if (response.data.rtnDesc === "success") {
          doctypeAccList.value = response.data.data.list;
          // console.log('list : '+list.value.length)
        }
      }
    }
  }
};

loadDocTypeAccListApi()


const loadCountryListApi = async (val) => {

  let data = "";
  data = {
    uid: 0,
  };

  const payload = data;
  const response = await storecountry.countryListAllFetch(payload);
  // console.log("loadCountryListApi : " + JSON.stringify(response.data));
  if (response) {
    if (response.status === 200) {
      if (response.data.rtnDesc === "success") {
        listCountry.value = response.data.data.list
        // console.log("listCountry : " + listCountry.value.length)
      }
    }
  }

};



const loadProvinceListApi = async (val) => {

  let data = "";
  data = {
    index: 0,
    size: 100,
    nameTh: ''
  };

  const payload = data;
  const response = await storep.provinceListFetch(payload);
  // console.log("loadDataApi : " + JSON.stringify(response));
  if (response) {
    if (response.status === 200) {
      if (response.data.rtnDesc === "success") {
        listProvince.value = response.data.data.list;
      }
    }
  }

}


async function loadPostcodeListApi(uid) {

  let data = "";
  data = {
    index: 0,
    size: 100,
    nameTh: '',
    uid: uid
  };

  const payload = data;
  const response = await storepc.postcodeListFetch(payload);
  // console.log("loadDataApi : " + JSON.stringify(response));
  if (response) {
    if (response.status === 200) {
      if (response.data.rtnDesc === "success") {
        listPostcode.value = response.data.data.list;
      }
    }
  }
}


async function loadDistrictListApi(uid) {

  let data = "";
  data = {
    index: 0,
    size: 100,
    nameTh: '',
    uid: uid
  };

  const payload = data;
  const response = await storedistrict.districtListFetch(payload);
  // console.log("loadDataApi : " + JSON.stringify(response));
  if (response) {
    if (response.status === 200) {
      if (response.data.rtnDesc === "success") {
        listDistrict.value = response.data.data.list;
      }
    }
  }
}

async function loadSubDistrictListApi(uid) {

  let data = "";
  data = {
    index: 0,
    size: 100,
    nameTh: '',
    uid: uid
  };

  const payload = data;
  const response = await storesd.subdistrictListFetch(payload);
  // console.log("loadDataApi : " + JSON.stringify(response));
  if (response) {
    if (response.status === 200) {
      if (response.data.rtnDesc === "success") {
        listSubDistrict.value = response.data.data.list;
      }
    }
  }
}


async function onOpenQRModal() {
  if (activeTab.value === '1') {
    qr_serach.value = ''
    qr_worckcaseUid.value = ''
  } else if (activeTab.value === '2') {
    qr_serach2.value = ''
    qr_worckcaseUid2.value = ''
  }

  paused.value = false
  await resetValidationState()
  document.getElementById("qrModalShow").click();
}

/*** error handling ***/
function onError(err) {
  error.value = `[${err.name}]: `
  if (err.name === 'NotAllowedError') {
    error.value += 'you need to grant camera access permission'
  } else if (err.name === 'NotFoundError') {
    error.value += 'no camera on this device'
  } else if (err.name === 'NotSupportedError') {
    error.value += 'secure context required (HTTPS, localhost)'
  } else if (err.name === 'NotReadableError') {
    error.value += 'is the camera already in use?'
  } else if (err.name === 'OverconstrainedError') {
    error.value += 'installed cameras are not suitable'
  } else if (err.name === 'StreamApiNotSupportedError') {
    error.value += 'Stream API is not supported in this browser'
  } else if (err.name === 'InsecureContextError') {
    error.value +=
      'Camera access is only permitted in secure context. Use HTTPS or localhost rather than HTTP.'
  } else {
    error.value += err.message
  }

  // document.getElementById("closeqr").click();
  // paused.value = false
  // resetValidationState()
}

async function resetValidationState() {
  isValid.value = undefined
  result.value = ''
}


async function onDetect([firstDetectedCode]) {
  result.value = firstDetectedCode.rawValue
  paused.value = true
  // pretend it's taking really long
  await timeout(2000)
  isValid.value = result.value
  if (activeTab.value === '1') {
    qr_serach.value = result.value
    setResultQRTab1()
  } else if (activeTab.value === '2') {
    qr_serach2.value = result.value
    setResultQRTab2()
  }



  await resetValidationState()
  document.getElementById("closeqr").click();
  // some more delay, so users have time to read the message

}

function timeout(ms) {
  return new Promise((resolve) => {
    window.setTimeout(resolve, ms)
  })
}


async function setResultQRTab1() {
  // console.log('active 1 : ' + activeTab.value)
  if (qr_serach.value.trim() !== '' && listQ02.value.length > 0) {
    let lw = []
    if (qr_serach.value.length > 2) {
      // console.log("search tkRef : " + qr_serach.value);
      lw = listQ02.value.filter((c) => c.prevRefNumber === qr_serach.value);
    } else {
      // console.log("search trayId : " + qr_serach.value);
      lw = listQ02.value.filter((c) => c.trayId === qr_serach.value);
    }
    if (lw.length > 0) {
      // console.log('lw : ' + JSON.stringify(lw[0]))
      qr_worckcaseUid.value = lw[0].workCaseUid
      let lockL = []
      lockL.push({ uid: lw[0].uid, version: lw[0].version })
      await lock(lockL, 'auto')
    } else {
      swal("ไม่พบข้อมูล", "", "error");
    }
  }
}

async function setResultQRTab2() {
  // console.log('active 2 : ' + activeTab.value)
  if (qr_serach2.value.trim() !== '' && listQ02O.value.length > 0) {
    let lw = []
    if (qr_serach2.value.length > 2) {
      // console.log("search tkRef 2 : " + qr_serach.value);
      lw = listQ02O.value.filter((c) => c.prevRefNumber === qr_serach2.value);
    } else {
      // console.log("search trayId 2 : " + qr_serach.value);
      lw = listQ02O.value.filter((c) => c.trayId === qr_serach2.value);
    }
    if (lw.length > 0) {
      qr_worckcaseUid2.value = lw[0].workCaseUid
      openTab(lw[0])
      qr_serach2.value = ''
      qr_worckcaseUid2.value = ''
    } else {
      swal("ไม่พบข้อมูล", "", "error");
    }
  }
}

async function findQ02Owner() {
  await loadDataQ02OwnerApi()
  if (listQ02O.value.length > 0) {
    let lw = []
    lw = listQ02O.value.filter((c) => c.workCaseUid === qr_worckcaseUid.value);
    if (lw.length > 0) {
      openTab(lw[0])
      qr_serach.value = ''
      qr_worckcaseUid.value = ''
    }
  }
}

function zoom(e) {
  let offsetX, offsetY, x, y
  var zoomer = e.currentTarget;
  e.offsetX ? offsetX = e.offsetX : offsetX = e.touches[0].pageX
  e.offsetY ? offsetY = e.offsetY : offsetX = e.touches[0].pageX
  x = offsetX / zoomer.offsetWidth * 100
  y = offsetY / zoomer.offsetHeight * 100
  zoomer.style.backgroundPosition = x + '% ' + y + '%';
}


async function inputBirthday() {
  cus_detail.value.age = calculateAge(new Date(cus_detail.value.birthOrRegDate));
}


function calculateAge(birthday) { // birthday is a date
  const ageDifMs = Date.now() - birthday.getTime();
  const ageDate = new Date(ageDifMs); // miliseconds from epoch
  return Math.abs(ageDate.getUTCFullYear() - 1970);
}

async function getCustomerInfo(uid) {

  let data = "";
  data = {
    uid: uid,
  };

  const payload = data;
  const response = await storecus.customerInfoFetch(payload);
  // console.log("getCustomerInfo : " + JSON.stringify(response.data));
  if (response) {
    if (response.status === 200) {
      if (response.data.rtnDesc === "success") {
        cus_detail.value = response.data.data
      }
    }
  }
}


async function clearItemCustomerInfo() {
  cus_detail.value = {
    nameTh: '',
    nameEn: '',
    birthOrRegDate: new Date(),
    address: {
      address1: "",
      subDistrict: "",
      district: "",
      province: "",
      postcode: "",
      country: ""
    },
    issuedDate: new Date(),
    expiredDate: new Date(),
    version: '',
    age: 0,
    docTypeUid: '0',
    docNumber: '',
    cusShop: {
      cusTypeUid: "0",
      creditAmount: 0,
      email: "",
      phoneNo1: "",
      phoneNo2: "",
      phoneNo3: "",
      fingerPrint1Uid: "0",
      fingerPrint2Uid: "0",
      fgTemplate1Uid: "0",
      fgTemplate2Uid: "0",
      warningMsg: "",
      notifyWarning: false,
    }
  }
}

async function setCustomerInfo(item) {
  await getCustomerInfo(item.customerUid)
  if (cus_detail.value.birthOrRegDate === null) {
    cus_detail.value.birthOrRegDate = new Date()
  }

  if (cus_detail.value.issuedDate === null) {
    cus_detail.value.issuedDate = new Date()
  }

  if (cus_detail.value.expiredDate === null) {
    cus_detail.value.expiredDate = new Date()
  }

  if (cus_detail.value.issuedBy === null) {
    cus_detail.value.issuedBy = ""
  }

  if (cus_detail.value.docTypeUid !== '0') {
    let dn = doctypeAccList.value.filter((c) => c.uid === cus_detail.value.docTypeUid)
    if (dn.length === 0) {
      cus_detail.value.docTypeUid = '0'
    }
  }

  inputBirthday()

  if (cus_detail.value.address.country === null) {
    cus_detail.value.address.country = ''
  }

  if (cus_detail.value.address.country === '') {
    if (cus_detail.value.address.province !== '') {
      cus_detail.value.address.country = 'ไทย'
    }
  }

  if (cus_detail.value.address.country === 'ไทย') {

    let object = Object.assign({}, ...filterCountry(cus_detail.value.address.country));
    cus_detail.value.address.country = object
    // console.log('country : ' + JSON.stringify(cus_detail.value.address.country));

    if (cus_detail.value.address.province !== '') {
      object = Object.assign({}, ...filterProvince(cus_detail.value.address.province.replace("จังหวัด", "")));
      cus_detail.value.address.province = object
      await loadDistrictListApi(cus_detail.value.address.province.uid)
      // console.log('province : ' + JSON.stringify(cus_detail.value.address.province));
    }

    if (cus_detail.value.address.district !== '') {
      object = Object.assign({}, ...filterDistrict(cus_detail.value.address.district));
      cus_detail.value.address.district = object
      await loadSubDistrictListApi(cus_detail.value.address.district.uid)
      // console.log('district : ' + JSON.stringify(cus_detail.value.address.district));
    }

    if (cus_detail.value.address.subDistrict !== '') {
      object = Object.assign({}, ...filterSubDistrict(cus_detail.value.address.subDistrict));
      cus_detail.value.address.subDistrict = object
      await loadPostcodeListApi(cus_detail.value.address.subDistrict.uid)
      // console.log('subDistrict : ' + JSON.stringify(cus_detail.value.address.subDistrict));
    }

    if (cus_detail.value.address.subDistrict !== '') {
      object = Object.assign({}, ...filterPostcode(cus_detail.value.address.postcode));
      cus_detail.value.address.postcode = object
      // console.log('postcode : ' + JSON.stringify(cus_detail.value.address.postcode));
    }


  } else if (cus_detail.value.address.country !== '') {
    let object = Object.assign({}, ...filterCountry(cus_detail.value.address.country));
    cus_detail.value.address.country = object
    cus_detail.value.address.province = ""
    cus_detail.value.address.district = ""
    cus_detail.value.address.subDistrict = ""
    cus_detail.value.address.postcode = ""

  } else {
    cus_detail.value.address.country = ""
    cus_detail.value.address.province = ""
    cus_detail.value.address.district = ""
    cus_detail.value.address.subDistrict = ""
    cus_detail.value.address.postcode = ""

  }
}


async function updateCustomerShop(action) {
  const payload = cus_detail.value.cusShop;
  if ((payload.email !== '' && payload.email !== null) && !checkformatEmail(payload.email)) {
    swal("กรุณาตรวจสอบอีเมล", "", "error");
  } else {
    if (payload.phoneNo1 !== null) {
      payload.phoneNo1 = payload.phoneNo1.replace(/[^\d]/g, "")
    }

    if (payload.phoneNo2 !== null) {
      payload.phoneNo2 = payload.phoneNo2.replace(/[^\d]/g, "")
    }

    if (payload.phoneNo3 !== null) {
      payload.phoneNo3 = payload.phoneNo3.replace(/[^\d]/g, "")
    }
    // console.log('updateCustomerShop : ' + JSON.stringify(payload));
    const response = await storecussh.cusShopUpdateFetch(payload)
    // console.log('updateCustomerShop : ' + JSON.stringify(response.data));
    if (response) {
      if (response.status === 200) {
        if (response.data.rtnDesc === "success") {
          if (action === 'data') {
            swal("บันทึกสำเร็จ", "", "success");
            cus_detail.value.cusShop = response.data.data
          } else if (action === 'finger') {
            cus_detail.value.cusShop = response.data.data
          }

        } else {
          swal("บันทึกไม่สำเร็จ", response.data.rtnDesc, "error");
        }
      }
    }
  }
}


function checkformatEmail(email) {
  const reg = /^(([^<>()\]\\.,;:\s@"]+(\.[^<>()\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/;
  const validRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
  const validRegex2 = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  if (email.match(validRegex) && email.toLowerCase().match(validRegex2)) {
    return true;
  } else {
    return false;
  }
}

function filterCountry(str) {
  return listCountry.value.filter((c) => c.nameTh.toLowerCase().indexOf(str) >= 0);
}

function filterPostcode(str) {
  return listPostcode.value.filter((c) => c.postcode.toLowerCase().indexOf(str) >= 0);
  // return listPostcode.value.filter((c) => c.postcode === str);
}

function filterProvince(str) {
  return listProvince.value.filter((c) => c.nameTh.toLowerCase().indexOf(str) >= 0);
  // return listProvince.value.filter((c) => c.nameTh === str);
}

function filterDistrict(str) {
  return listDistrict.value.filter((c) => c.nameTh.toLowerCase().indexOf(str) >= 0);
  // return listDistrict.value.filter((c) => c.nameTh === str);
}

function filterSubDistrict(str) {
  return listSubDistrict.value.filter((c) => c.nameTh.toLowerCase().indexOf(str) >= 0);
  // return listSubDistrict.value.filter((c) => c.nameTh === str);
}


async function checkdataMatch2(list) {
  let list2 = [];
  let t1 = "";
  let t2 = "";
  let t3 = "";
  let t4 = "";
  let t5 = "";
  let t6 = "";


  for (let i = 0; i < list.length; i++) {
    t1 = "";
    t2 = "";
    t3 = "";
    t4 = "";
    t5 = "";
    t6 = "";

    let text = list[i].fullName;
    text = text.split(">>");
    let count = text.length - 1;
    let newText = ''
    // console.log('text sett : '+text)

    if (count > 0) {
      if (count === 1) {
        t1 = text[0];
        t2 = text[1];
        // ss = text[1] +" >> " +text[2]
        newText = text[1]
      } else if (count === 2) {
        t1 = text[0];
        t2 = text[1];
        t3 = text[2];
        // ss = text[1] +" >> " +text[2] +" >> " +text[3]
        // ss = ss +" >> " +text[2]
        newText = text[1] + '>>' + text[2]
      } else if (count === 3) {
        t1 = text[0];
        t2 = text[1];
        t3 = text[2];
        t4 = text[3];


        newText = text[1] + '>>' + text[2] + '>>' + text[3]

        // ss = text[1] +" >> " +text[2] +" >> " +text[3] +" >> " +text[4]
      } else if (count === 4) {
        t1 = text[0];
        t2 = text[1];
        t3 = text[2];
        t4 = text[3];
        t5 = text[4];

        newText = text[1] + '>>' + text[2] + '>>' + text[3] + '>>' + text[4]

        // ss = text[1] +" >> " +text[2] +" >> " +text[3] +" >> " +text[4] +" >> " +text[5]
      } else if (count === 5) {
        // console.log('data sett : '+list[i].fullName)
        t1 = text[0]
        t2 = text[1]
        t3 = text[2]
        t4 = text[3]
        t5 = text[4]
        t6 = text[5]

        newText = text[1] + '>>' + text[2] + '>>' + text[3] + '>>' + text[4] + '>>' + text[5]

      }
    }
    // console.log('data sett : '+list[i].fullName.replaceAll (">>", " "))

    const obj = {
      uid: list[i].uid,
      code: list[i].code,
      // detailt: list[i].fullName.replaceAll(">>", " "),
      // fullName: list[i].fullName,
      // datailt: list[i].fullName,
      detailt: newText.replaceAll(">>", " "),
      fullName: newText,
      datailt: newText,
      t1: t1,
      t2: t2,
      t3: t3,
      t4: t4,
      t5: t5,
      t6: t6,
    };
    list2.push(obj);
  }

  // console.log('data sett : '+JSON.stringify(list2))
  textt.value = "";
  if (list2.length > 0) {
    types.value = list2;
  }
}


async function selection(value) {
  selected.value = value;
  filterText.value = value.datailt;
  typesNew.value = [];
  textt.value = "";
  loadMoreStatus.value = "idle";
  dataList.value = [];
  // console.log('input : ' + JSON.stringify(selected.value))
  let ld = listprint.value.filter((c) => c.name.toLowerCase().indexOf(selected.value.t1.toLowerCase()) >= 0);
  if (ld.length > 0) {
    collect_detail.value.mainCatUid = ld[0].uid
    await getFlaw(ld[0].uid)
  } else {
    collect_detail.value.mainCatUid = '0'
  }
  setRemark()
}

async function newformatinput() {
  filterInput.value = "";
  page_s.value = 0;
  page_e.value = 20;
  typesNew.value = [];
  loadMoreStatus.value = "idle";
  dataList.value = [];
  collect_detail.value.mainCatUid = '0'
  listflaw.value = []
  openFlaw.value = false
  let list = []
  if (filterText.value.trim().length > 0) {
    if (filterText.value.includes(">>")) {
      let text = filterText.value.replaceAll(/\s/g, "");
      text = text.split(">>");
      let count = text.length - 1;
      if (count > 0) {
        if (count === 1) {
          // console.log('2 text[0]  : '+text[0] + ' text[1]  : '+text[1] + ' text[2]  : '+text[2])
          // console.log('count 2')
          list = types.value.filter(
            (c) =>
              c.t2.toLowerCase().replaceAll(/\s/g, "") ===
              text[0].toLowerCase() &&
              c.t3
                .replaceAll(/\s/g, "")
                .toLowerCase()
                .includes(text[1].toLowerCase())
          );
          // this.typesNew = list
          checkdataMatch(list);

          // console.log('2 text : '+text + 'text L : '+text.length + ' count : '+count + ' typesNew L : '+JSON.stringify(this.typesNew))
        } else if (count === 2) {
          // console.log('count 1')
          list = types.value.filter(
            (c) =>
              c.t2.toLowerCase().replaceAll(/\s/g, "") ===
              text[0].toLowerCase() &&
              c.t3.toLowerCase().replaceAll(/\s/g, "") ===
              text[1].toLowerCase() &&
              c.t4
                .toLowerCase()
                .replaceAll(/\s/g, "")
                .includes(text[2].toLowerCase())
          );
          // this.typesNew = list
          checkdataMatch(list);

          // console.log('1 text : '+text + 'text L : '+text.length + ' count : '+count + ' typesNew L : '+JSON.stringify(this.typesNew))
        } else if (count === 3) {
          // console.log('2 text[0]  : '+text[0] + ' text[1]  : '+text[1] + ' text[2]  : '+text[2])
          // console.log('count 2')
          list = types.value.filter(
            (c) =>
              c.t2.toLowerCase().replaceAll(/\s/g, "") ===
              text[0].toLowerCase() &&
              c.t3.toLowerCase().replaceAll(/\s/g, "") ===
              text[1].toLowerCase() &&
              c.t4.toLowerCase().replaceAll(/\s/g, "") ===
              text[2].toLowerCase() &&
              c.t5
                .replaceAll(/\s/g, "")
                .toLowerCase()
                .includes(text[3].toLowerCase())
          );
          // this.typesNew = list
          checkdataMatch(list);

          // console.log('2 text : '+text + 'text L : '+text.length + ' count : '+count + ' typesNew L : '+JSON.stringify(this.typesNew))
        } else if (count === 4) {
          // console.log('count 3')
          list = types.value.filter(
            (c) =>
              c.t2.toLowerCase().replaceAll(/\s/g, "") ===
              text[0].toLowerCase() &&
              c.t3.toLowerCase().replaceAll(/\s/g, "") ===
              text[1].toLowerCase() &&
              c.t4.toLowerCase().replaceAll(/\s/g, "") ===
              text[2].toLowerCase() &&
              c.t5.toLowerCase().replaceAll(/\s/g, "") ===
              text[3].toLowerCase() &&
              c.t6.toLowerCase().includes(text[4].toLowerCase())
          );
          // this.typesNew =  list
          checkdataMatch(list);

          // console.log('3 text : '+text + 'text L : '+text.length + ' count : '+count + ' typesNew L : '+JSON.stringify(this.typesNew))
        }
      } else {
        filterInput.value = filterText.value.replaceAll(">>", "");
        list = types.value.filter(
          (c) =>
            c.t2.toLowerCase().includes(filterInput.value.toLowerCase()) ||
            c.t3.toLowerCase().includes(filterInput.value.toLowerCase()) ||
            c.t4.toLowerCase().includes(filterInput.value.toLowerCase()) ||
            c.t5.toLowerCase().includes(filterInput.value.toLowerCase()) ||
            c.t6.toLowerCase().includes(filterInput.value.toLowerCase())
        );
        checkdataMatch(list);

        // console.log('4 text : '+text + 'text L : '+text.length + ' count : '+count + ' typesNew L : '+JSON.stringify(this.typesNew))
      }

    } else {
      if (
        filterText.value !== "" &&
        filterText.value !== undefined &&
        filterText.value !== null
      ) {
        // console.log('ft : '+this.filterText)

        filterInput.value = filterText.value.replaceAll(">>", "");
        // let stringArray = []
        // stringArray = this.filterInput.trim().toLowerCase().match(/\S+/g)
        var text = filterInput.value.trim().toLowerCase();
        var arr = text.match(/\S+/g);

        if (arr !== null) {
          // console.log('ft : ' + arr)
          list = types.value.filter((c) =>
            allAvailable(c.detailt.toLowerCase(), arr)
          );

          checkdataMatch(list);
        }
      }
    } if (list.length === 0) {
      let ld = listprint.value.filter((c) => c.name.toLowerCase().indexOf('อื่นๆ') >= 0);
      if (ld.length > 0) {
        collect_detail.value.mainCatUid = ld[0].uid
        selected.value = ''
        await getFlaw(ld[0].uid)
        setRemark()
      }
    }

  } else {
    typesNew.value = [];
    textt.value = "";
    setRemark()
  }

}


async function formatinput() {
  filterInput.value = "";
  page_s.value = 0;
  page_e.value = 20;
  typesNew.value = [];
  loadMoreStatus.value = "idle";
  dataList.value = [];
  collect_detail.value.mainCatUid = '0'
  listflaw.value = []
  openFlaw.value = false
  let list = []

  if (filterText.value.trim().length > 0) {
    if (filterText.value.includes(">>")) {
      let text = filterText.value.replaceAll(/\s/g, "");
      text = text.split(">>");
      let count = text.length - 1;
      if (count > 0) {
        if (count === 1) {
          // console.log('count 1')
          list = types.value.filter(
            (c) =>
              c.t1.toLowerCase().replaceAll(/\s/g, "") ===
              text[0].toLowerCase() &&
              c.t2
                .toLowerCase()
                .replaceAll(/\s/g, "")
                .includes(text[1].toLowerCase())
          );
          // this.typesNew = list
          checkdataMatch(list);

          // console.log('1 text : '+text + 'text L : '+text.length + ' count : '+count + ' typesNew L : '+JSON.stringify(this.typesNew))
        } else if (count === 2) {
          // console.log('2 text[0]  : '+text[0] + ' text[1]  : '+text[1] + ' text[2]  : '+text[2])
          // console.log('count 2')
          list = types.value.filter(
            (c) =>
              c.t1.toLowerCase().replaceAll(/\s/g, "") ===
              text[0].toLowerCase() &&
              c.t2.toLowerCase().replaceAll(/\s/g, "") ===
              text[1].toLowerCase() &&
              c.t3
                .replaceAll(/\s/g, "")
                .toLowerCase()
                .includes(text[2].toLowerCase())
          );
          // this.typesNew = list
          checkdataMatch(list);

          // console.log('2 text : '+text + 'text L : '+text.length + ' count : '+count + ' typesNew L : '+JSON.stringify(this.typesNew))
        } else if (count === 3) {
          // console.log('count 3')
          list = types.value.filter(
            (c) =>
              c.t1.toLowerCase().replaceAll(/\s/g, "") ===
              text[0].toLowerCase() &&
              c.t2.toLowerCase().replaceAll(/\s/g, "") ===
              text[1].toLowerCase() &&
              c.t3.toLowerCase().replaceAll(/\s/g, "") ===
              text[2].toLowerCase() &&
              c.t4.toLowerCase().includes(text[3].toLowerCase())
          );
          // this.typesNew =  list
          checkdataMatch(list);

          // console.log('3 text : '+text + 'text L : '+text.length + ' count : '+count + ' typesNew L : '+JSON.stringify(this.typesNew))
        } else if (count === 4) {
          // console.log('count 4')
          list = types.value.filter(
            (c) =>
              c.t1.toLowerCase().replaceAll(/\s/g, "") ===
              text[0].toLowerCase() &&
              c.t2.toLowerCase().replaceAll(/\s/g, "") ===
              text[1].toLowerCase() &&
              c.t3.toLowerCase().replaceAll(/\s/g, "") ===
              text[2].toLowerCase() &&
              c.t4.toLowerCase().replaceAll(/\s/g, "") ===
              text[3].toLowerCase() &&
              c.t5.toLowerCase().includes(text[4].toLowerCase())
          );
          // this.typesNew =  list
          checkdataMatch(list);

          // console.log('3 text : '+text + 'text L : '+text.length + ' count : '+count + ' typesNew L : '+JSON.stringify(this.typesNew))
        } else if (count === 5) {
          // console.log('count 5')
          list = types.value.filter(
            (c) =>
              c.t1.toLowerCase().replaceAll(/\s/g, "") ===
              text[0].toLowerCase() &&
              c.t2.toLowerCase().replaceAll(/\s/g, "") ===
              text[1].toLowerCase() &&
              c.t3.toLowerCase().replaceAll(/\s/g, "") ===
              text[2].toLowerCase() &&
              c.t4.toLowerCase().replaceAll(/\s/g, "") ===
              text[3].toLowerCase() &&
              c.t5.toLowerCase().replaceAll(/\s/g, "") ===
              text[4].toLowerCase() &&
              c.t6.toLowerCase().includes(text[5].toLowerCase())
          );
          // this.typesNew =  list
          checkdataMatch(list);

          // console.log('3 text : '+text + 'text L : '+text.length + ' count : '+count + ' typesNew L : '+JSON.stringify(this.typesNew))
        }
      } else {
        filterInput.value = filterText.value.replaceAll(">>", "");
        list = types.value.filter(
          (c) =>
            c.t1.toLowerCase().includes(filterInput.value.toLowerCase()) ||
            c.t2.toLowerCase().includes(filterInput.value.toLowerCase()) ||
            c.t3.toLowerCase().includes(filterInput.value.toLowerCase()) ||
            c.t4.toLowerCase().includes(filterInput.value.toLowerCase()) ||
            c.t5.toLowerCase().includes(filterInput.value.toLowerCase())
        );
        // this.typesNew =  list
        checkdataMatch(list);

        // console.log('4 text : '+text + 'text L : '+text.length + ' count : '+count + ' typesNew L : '+JSON.stringify(this.typesNew))
      }
    } else {
      if (
        filterText.value !== "" &&
        filterText.value !== undefined &&
        filterText.value !== null
      ) {
        // console.log('ft : '+this.filterText)

        filterInput.value = filterText.value.replaceAll(">>", "");
        // let stringArray = []
        // stringArray = this.filterInput.trim().toLowerCase().match(/\S+/g)
        var text = filterInput.value.trim().toLowerCase();
        var arr = text.match(/\S+/g);

        if (arr !== null) {
          // console.log('ft : ' + arr)
          list = types.value.filter((c) =>
            allAvailable(c.detailt.toLowerCase(), arr)
          );

          checkdataMatch(list);
        }
      }
    }

    if (list.length === 0) {
      let ld = listprint.value.filter((c) => c.name.toLowerCase().indexOf('อื่นๆ') >= 0);
      if (ld.length > 0) {
        collect_detail.value.mainCatUid = ld[0].uid
        selected.value = ''
        await getFlaw(ld[0].uid)
        setRemark
      }
    }
  } else {
    typesNew.value = [];
    textt.value = "";
    setRemark()
  }
}

function allAvailable(str, matchArr) {
  // console.log('str : '+str + ' / matchArr : ' +matchArr)
  return matchArr.every((item) => str.includes(item));
}

function filterMatch(str, matchArr) {
  // console.log('str : '+str + ' / matchArr : ' +matchArr)
  return matchArr.filter((c) => c.name.toLowerCase().includes(str.toLowerCase()));
}

async function checkdataMatch(list) {
  let list2 = [];
  for (let i = 0; i < list.length; i++) {
    const obj = {
      datailt: list[i].datailt,
      t1: list[i].t1,
      t2: list[i].t2,
      t3: list[i].t3,
      t4: list[i].t4,
      t5: list[i].t5,
      t6: list[i].t6,
      detailt: list[i].detailt,
      fullName: list[i].fullName,
      uid: list[i].uid,
      code: list[i].code
    };
    list2.push(obj);
  }

  textt.value = "";
  if (list2.length > 0) {
    // console.log('list2 : '+list2.length)
    typesNew.value = list2;
    textt.value = "result : " + formatNumberString(list2.length) + " / " + formatNumberString(types.value.length);
    // console.log("textt : " + textt.value);
    fetchData()
    // loadData();
  }
}


async function onClickTab(val) {
  if (val === '1') {
    activeTab.value = 'tab1';
    loadDataQ02Api()
    await setfirstTab()
  } else if (val === '2') {
    activeTab.value = 'tab2';
    loadDataQ02OwnerApi()
    await setsecondTab()
  } else if (val === '3') {
    activeTab.value = 'tab3';
    await setthirdTab()
  }
}

async function onSelectGroup() {
  // console.log('onSelectGroup : ' + selectGroup.value)
  if (!selectGroup.value) {
    groupuid.value = ''
    for (let i = 0; i < listQ02.value.length; i++) {
      listQ02.value[i].chk = false
    }
  }
}

async function onSelectType() {
  // console.log('onSelectType : ' + selectType.value)
  if (!selectType.value) {
    typeTrans.value = ''
    for (let i = 0; i < listQ02.value.length; i++) {
      listQ02.value[i].chk = false
    }
  }
}

async function onSelectListQ02(item) {

  if (selectGroup.value || selectType.value) {
    if (selectGroup.value) {

      if (item.chk) {
        groupuid.value = item.paymentGroupUid
      } else {
        groupuid.value = ''
      }

      for (let i = 0; i < listQ02.value.length; i++) {
        if (listQ02.value[i].paymentGroupUid === item.paymentGroupUid) {
          listQ02.value[i].chk = item.chk
        }
      }
    } else if (selectType.value) {

      if (item.chk) {
        typeTrans.value = item.type

      } else {
        typeTrans.value = ''
      }

      for (let i = 0; i < listQ02.value.length; i++) {
        if (listQ02.value[i].type === item.type) {
          //   console.log("typeTrans : " + typeTrans.value + ' type : ' + listQ02.value[i].type + " chk : " + item.chk)
          listQ02.value[i].chk = item.chk
        }
      }
    }
  }
}

function formatNumberString(num) {
  return String(num).replace(
    /(\d)(?=(\d{3})+(?!\d))/g,
    "$1,"
  );
}

function formatNumberDecimalString(num) {
  if (num !== null && num !== undefined) {
    num = num.toFixed(2)
  } else {
    num = 0
    num = num.toFixed(2)
  }
  return String(num).replace(
    /(\d)(?=(\d{3})+(?!\d))/g,
    "$1,"
  );
}

function filterData(str, matchArr) {
  // console.log('str : ' + str + " matchArr : " + JSON.stringify(matchArr))
  //  return matchArr.filter((c) => c.data.data.uid === str);
  return matchArr.filter((c) => c.uid === str);
}

function filterCat(str) {
  let side = ''
  let name = listprint.value.filter((c) => c.uid === str);
  if (name.length > 0) {
    side = name[0].name
  }
  return side
}

function checkPaySide(str) {
  // PAWN, INTEREST, REDEEM, INC_PRINCIPLE, DEC_PRINCIPLE
  let side = ''
  if (str === 'PAY') {
    side = 'จ่ายเงิน'
  } else if (str === 'RECEIVE') {
    side = 'รับเงิน'
  } else if (str === 'NONE') {
    side = 'ไม่มียอด'
  }
  return side
}


function checkTransaction(str) {
  // PAWN, INTEREST, REDEEM, INC_PRINCIPLE, DEC_PRINCIPLE
  let side = ''
  if (str === 'PAWN') {
    side = 'จำนำ'
  } else if (str === 'INTEREST') {
    side = 'ต่อดอกเบี้ย'
  } else if (str === 'REDEEM') {
    side = 'ไถ่ถอน'
  } else if (str === 'INC_PRINCIPLE') {
    side = 'เพิ่มต้น'
  } else if (str === 'DEC_PRINCIPLE') {
    side = 'ลดต้น'
  } else if (str === 'REVIEW_COLLATERAL') {
    side = 'ขอดูทรัพย์'
  }
  return side
}

function format_date(value) {
  if (value) {
    return moment(String(value)).local().format('DD/MM/YYYY HH:mm')
  }
}

function format_dates(value) {
  if (value) {
    return moment(String(value)).local().format('DD/MM/YYYY')
  }
}


async function getFlaw(uid) {
  listflaw.value = []
  let data = "";
  data = {
    categoryUid: uid,
  };
  const payload = data;
  const response = await storetf.flawListFetch(payload);
  if (response) {
    if (response.status === 200) {
      if (response.data.rtnDesc === "success") {
        // console.log('getFlaw : ' + JSON.stringify(response.data));
        let list = response.data.data.list;
        list.map(function (x) {
          x.chk = false
          return x
        });
        listflaw.value = list

      }
    }
  }
}


async function chkSelect() {
  let process = false
  let lockL = []
  for (let i = 0; i < listQ02.value.length; i++) {
    if (listQ02.value[i].chk) {
      process = true
      // console.log('chkSelect u : ' + JSON.stringify(listQ02.value[i].uid) + ' v : ' + JSON.stringify(listQ02.value[i].version));
      lockL.push({ uid: listQ02.value[i].uid, version: listQ02.value[i].version })

    }
  }

  if (!process) {
    swal("กรุณาเลือกรายการ", "", "error");
  } else {
    // console.log('lockL : ' + lockL.length);
    await lock(lockL, '')
  }

}

async function openTab(item) {
  // console.log('openTab : ' + JSON.stringify(item));
  datas.hide = false;
  if (Listdata.value.length === 0) {
    await loadDataApi();
  }

  if (listprint.value.length === 0) {
    await loadDataPrintApi()
  }

  if (listunit.value.length === 0) {
    loadDataUnitApi()
  }

  if (shop_detail.value === null) {
    cashAccShopApi()
  }

  if (listCountry.value.length === 0) {
    loadCountryListApi()
  }

  if (listProvince.value.length === 0) {
    loadProvinceListApi()
  }


  uid_edit.value = ''
  finger.value = false
  workItem_uid.value = item.uid
  workItem_version.value = item.version
  images.value = []
  images_l.value = []
  photoFile.value = null
  filterText.value = ''
  workcaseDetail.value = null
  interestRate_s.value = ''
  oldremark.value = ''
  migrateRefNumber.value = ''
  listQ02D.value = []
  listQ02E.value = []
  cusUid_search.value = ''
  cusName_search.value = ''
  urls.value = []
  templatef_1.value = ''
  templatef_2.value = ''
  bmpBase64f_1.value = ''
  bmpBase64f_2.value = ''
  paymentGDetail.value = null
  finger.value = false
  fingertrans_uid.value = '0'
  fingertrans_temp.value = '0'
  promoint_duration.value = ''
  promoint_val.value = ''
  count_fg.value = 0
  otpValidate.value = 0
  otpReTime.value = 0
  otpTime.value = false
  otpResend.value = false

  clearItemCustomerInfo()
  await getworkCaseInfo(item.workCaseUid)



  listinfo.value = []


  if (workcaseDetail.value != null) {
    // console.log('fpImageUid : ' + JSON.stringify(workcaseDetail.value.customerInfo.fpImageUid) + ' , fpTemplateUid : '
    //   + JSON.stringify(workcaseDetail.value.customerInfo.fpTemplateUid));
    if (workcaseDetail.value.customerInfo.fpImageUid !== '0' && workcaseDetail.value.customerInfo.fpTemplateUid !== '0') {
      finger.value = true
    }
    migrateRefNumber.value = workcaseDetail.value.migrateRefNumber
    uid_edit.value = workcaseDetail.value.ticket.uid
    await setCustomerInfo(workcaseDetail.value.customerInfo)
    baldiamond.value = workcaseDetail.value.ticket.totalDiamondQuantitytotalDiamondQuantity
    listQ02E.value = await item
    images_l.value = workcaseDetail.value.ticket.images
    oldremark.value = workcaseDetail.value.remark
    cusUid_search.value = workcaseDetail.value.ticket.relatedCusUid
    cusName_search.value = workcaseDetail.value.ticket.relatedCusName
    listinfo.value = workcaseDetail.value.ticket.collateralInfo
    diff.value = workcaseDetail.value.diffValue;
    diff_s.value = formatNumberString(diff.value);
    interestRate_s.value = formatNumberDecimalString(workcaseDetail.value.interestPerMonth)
    if (workcaseDetail.value.interestPromotion === null || workcaseDetail.value.interestPromotion === 0) {
      promoint_val.value = formatNumberDecimalString(0)
    } else {
      promoint_val.value = formatNumberDecimalString(workcaseDetail.value.interestPromotion)
    }
    if (workcaseDetail.value.promotionDuration === 0) {
      promoint_duration.value = '-'
    } else {
      promoint_duration.value = workcaseDetail.value.promotionDuration + ' เดือน'
    }


    if (item.type === 'REDEEM') {
      item.newPawnValue = workcaseDetail.value.newPawnValue
      item.newPawnValue_s = formatNumberDecimalString(item.newPawnValue)
      balprice.value = workcaseDetail.value.pawnValue;
    } else {
      balprice.value = workcaseDetail.value.newPawnValue;
    }

    listQ02D.value = workcaseDetail.value.ticket.collaterals

    // console.log('list : ' + JSON.stringify(listQ02D.value));
    listQ02D.value.map(function (x) {
      x.chk = false
      x.remarkL = []
      return x
    });
    // console.log('listQ02D : ' + JSON.stringify(listQ02D.value));
    // console.log('uid_edit : ' + uid_edit.value);
  }

  balweight.value = item.weight;
  balfull.value = item.fullValue;
  balquanity.value = item.quantity;
  // console.log('balprice : ' + balprice.value);

  // balprice.value = item.newPawnValue;



  if (listQ02D.value.length > 0) {
    for (let i = 0; i < listQ02D.value.length; i++) {
      balweight.value = balweight.value - listQ02D.value[i].weight
      balfull.value = balfull.value - listQ02D.value[i].fullValue
      balquanity.value = balquanity.value - listQ02D.value[i].quantity
      baldiamond.value = baldiamond.value - listQ02D.value[i].diamondQuantity
      listQ02D.value[i].remarkL.push({ val: listQ02D.value[i].remark })
      if (item.type === 'INC_PRINCIPLE' || item.type === 'DEC_PRINCIPLE') {
        balprice.value = balprice.value - listQ02D.value[i].tmpNewPawnValue
      } else {
        balprice.value = balprice.value - listQ02D.value[i].newPawnValue
      }
    }
  }

  if (balweight.value !== null) {
    balweight.value = balweight.value.toFixed(2)
  }

  if (balprice.value !== null) {
    balprice.value = balprice.value.toFixed(2)
  }

  if (balfull.value !== null) {
    balfull.value = balfull.value.toFixed(2)
  }




  balweight_s.value = formatNumberString(balweight.value);
  balprice_s.value = formatNumberString(balprice.value);
  balfull_s.value = formatNumberString(balfull.value);
  balquanity_s.value = formatNumberString(balquanity.value);
  baldiamond_s.value = formatNumberString(baldiamond.value);

  await setthirdTab()

}

async function setfirstTab() {

  if (document.getElementById("second-home-tab")) {
    document.getElementById("second-home-tab").classList.remove('show', 'active')
  }

  if (document.getElementById("secondHome")) {
    document.getElementById("secondHome").classList.remove('show', 'active')
  }

  if (document.getElementById("third-home-tab")) {
    document.getElementById("third-home-tab").classList.remove('show', 'active')
  }

  if (document.getElementById("thirdHome")) {
    document.getElementById("thirdHome").classList.remove('show', 'active')
  }

  if (document.getElementById("first-home-tab")) {
    document.getElementById("first-home-tab").classList.add('show', 'active')
  }

  if (document.getElementById("firstHome")) {
    document.getElementById("firstHome").classList.add('show', 'active')
  }

}


async function setsecondTab() {

  if (document.getElementById("first-home-tab")) {
    document.getElementById("first-home-tab").classList.remove('show', 'active')
  }

  if (document.getElementById("firstHome")) {
    document.getElementById("firstHome").classList.remove('show', 'active')
  }

  if (document.getElementById("third-home-tab")) {
    document.getElementById("third-home-tab").classList.remove('show', 'active')
  }

  if (document.getElementById("thirdHome")) {
    document.getElementById("thirdHome").classList.remove('show', 'active')
  }

  if (document.getElementById("second-home-tab")) {
    document.getElementById("second-home-tab").classList.add('show', 'active')
  }

  if (document.getElementById("secondHome")) {
    document.getElementById("secondHome").classList.add('show', 'active')
  }
}

async function setthirdTab() {

  if (document.getElementById("first-home-tab")) {
    document.getElementById("first-home-tab").classList.remove('show', 'active')
  }

  if (document.getElementById("firstHome")) {
    document.getElementById("firstHome").classList.remove('show', 'active')
  }

  if (document.getElementById("second-home-tab")) {
    document.getElementById("second-home-tab").classList.remove('show', 'active')
  }

  if (document.getElementById("secondHome")) {
    document.getElementById("secondHome").classList.remove('show', 'active')
  }

  if (document.getElementById("third-home-tab")) {
    document.getElementById("third-home-tab").classList.add('show', 'active')
  }

  if (document.getElementById("thirdHome")) {
    document.getElementById("thirdHome").classList.add('show', 'active')
  }

}

async function openGallery() {

  if (images.value.length === 0) {
    loading_d.value = true
    let url = []
    images.value = []
    for (let i = 0; i < images_l.value.length; i++) {
      // console.log("logoUid : " + item.images[i].imageUid);
      if (images_l.value[i].imageUid !== "0") {
        url.push(String(st.url_api + "/doc-svc/document/info/" + images_l.value[i].imageUid));
        // urls.value.push(axios.get((st.url_document + "/doc-svc/document/info/" + lb[i].logoUid)));
      }
    }

    if (url.length > 0) {
      await getFile(url)
      if (images.value.length > 0) {
        setGallery()
        loading_d.value = false
        document.getElementById("myModalClose").click();
      }
    }

  } else {
    setGallery()
    loading_d.value = false
    document.getElementById("myModalClose").click();
  }


}

async function clearItem() {
  collect_detail.value = {
    uid: '',
    ticketUid: '',
    mainCatUid: '0',
    mainCatCode: '',
    mainCatName: '',
    categoryUid: '0',
    categoryCode: '',
    categoryName: '',
    weight: '',
    pawnValue: '',
    newPawnValue: '',
    tmpPawnValue: '',
    tmpNewPawnValue: '',
    fullValue: '',
    diamondQuantity: '',
    quantity: '',
    unitUid: '0',
    unitName: '',
    remark: '',
    collateralGroupUid: '',
    version: '',
    serialNumber: ''
  };
}

async function backToFirstTab() {
  datas.hide = false;
  uid_edit.value = ''
  workItem_uid.value = ''
  workItem_version.value = null
  images.value = []
  images_l.value = []
  listinfo.value = []
  listQ02D.value = []
  finger.value = false
  balweight.value = 0;
  balprice.value = 0;
  balfull.value = 0;
  balquanity.value = 0;
  baldiamond.value = 0
  balweight_s.value = ''
  balprice_s.value = ''
  balfull_s.value = ''
  balquanity_s.value = ''
  baldiamond_s.value = ''
  listQ02E.value = {}
  diff.value = 0
  diff_s.value = ''
  photoFile.value = null
  oldremark.value = ''
  workcaseDetail.value = null
  interestRate_s.value = ''
  migrateRefNumber.value = ''
  cusUid_search.value = ''
  cusName_search.value = ''
  paymentGDetail.value = null
  urls.value = []
  templatef_1.value = ''
  templatef_2.value = ''
  bmpBase64f_1.value = ''
  bmpBase64f_2.value = ''
  finger.value = false
  promoint_duration.value = ''
  promoint_val.value = ''
  count_fg.value = 0
  otpValidate.value = 0
  otpReTime.value = 0
  otpTime.value = false
  otpResend.value = false
  clearItemCustomerInfo();
  await onClickTab('1')
}

async function backToSecondTab() {
  datas.hide = false;
  uid_edit.value = ''
  workItem_uid.value = ''
  workItem_version.value = null
  images.value = []
  images_l.value = []
  listinfo.value = []
  listQ02D.value = []
  finger.value = false
  balweight.value = 0;
  balprice.value = 0;
  balfull.value = 0;
  balquanity.value = 0;
  baldiamond.value = 0
  balweight_s.value = ''
  balprice_s.value = ''
  balfull_s.value = ''
  balquanity_s.value = ''
  baldiamond_s.value = ''
  listQ02E.value = {}
  diff.value = 0
  diff_s.value = ''
  photoFile.value = null
  oldremark.value = ''
  migrateRefNumber.value = ''
  workcaseDetail.value = null
  interestRate_s.value = ''
  cusUid_search.value = ''
  cusName_search.value = ''
  paymentGDetail.value = null
  urls.value = []
  templatef_1.value = ''
  templatef_2.value = ''
  bmpBase64f_1.value = ''
  bmpBase64f_2.value = ''
  finger.value = false
  promoint_duration.value = ''
  promoint_val.value = ''
  count_fg.value = 0
  otpValidate.value = 0
  otpReTime.value = 0
  otpTime.value = false
  otpResend.value = false
  clearItemCustomerInfo();
  await onClickTab('2')
}


async function showModal() {
  clearItem();
  // visible.value = true
  process_c.value = 'add'
  filterText.value = ''
  dataList.value = []
  listflaw.value = []
  selected.value = ''
  typesNew.value = []
  textt.value = ''
  document.getElementById("newPawnModal").click();


}


async function setItemDetail(item) {
  clearItem();
  collect_detail.value = {
    uid: item.uid,
    ticketUid: item.ticketUid,
    mainCatUid: item.mainCatUid,
    mainCatCode: item.mainCatCode,
    mainCatName: item.mainCatName,
    categoryUid: item.categoryUid,
    categoryCode: item.categoryCode,
    categoryName: item.categoryName,
    weight: item.weight,
    pawnValue: item.pawnValue,
    fullValue: item.fullValue,
    diamondQuantity: item.diamondQuantity,
    quantity: item.quantity,
    unitUid: item.unitUid,
    unitName: item.unitName,
    remark: item.remark,
    collateralGroupUid: item.collateralGroupUid,
    serialNumber: item.serialNumber,
    version: item.version,
    newPawnValue: item.newPawnValue,
    tmpPawnValue: item.tmpPawnValue,
    tmpNewPawnValue: item.tmpNewPawnValue,
  };
  // console.log('setItemDetail : ' + JSON.stringify(collect_detail.value));
  filterText.value = item.categoryName
  if (collect_detail.value.mainCatUid !== '0') {
    await getFlaw(collect_detail.value.mainCatUid)
  }
  process_c.value = 'edit'
  dataList.value = []
  selected.value = ''
  typesNew.value = []
  textt.value = ''
  document.getElementById("newPawnModal").click();

}

async function setItemDetailView(item) {
  clearItem();
  collect_detail.value = {
    uid: item.uid,
    ticketUid: item.ticketUid,
    mainCatUid: item.mainCatUid,
    mainCatCode: item.mainCatCode,
    mainCatName: item.mainCatName,
    categoryUid: item.categoryUid,
    categoryCode: item.categoryCode,
    categoryName: item.categoryName,
    weight: item.weight,
    pawnValue: item.pawnValue,
    fullValue: item.fullValue,
    diamondQuantity: item.diamondQuantity,
    quantity: item.quantity,
    unitUid: item.unitUid,
    unitName: item.unitName,
    remark: item.remark,
    collateralGroupUid: item.collateralGroupUid,
    serialNumber: item.serialNumber,
    version: item.version,
    newPawnValue: item.newPawnValue,
    tmpPawnValue: item.tmpPawnValue,
    tmpNewPawnValue: item.tmpNewPawnValue,
  };
  selected.value = ''
  if (listQ02E.value.type === 'INC_PRINCIPLE' || listQ02E.value.type === 'DEC_PRINCIPLE') {
    collect_detail.value.newPawnValue = item.tmpNewPawnValue
  }

  // console.log('setItemDetailView : ' + JSON.stringify(collect_detail.value));
  document.getElementById("viewPawnModal").click();
}


async function chkProess() {
  wanninglist.value = []
  if (collect_detail.value.serialNumber !== '' && collect_detail.value.serialNumber !== null) {
    await findsanctionAsset(collect_detail.value.serialNumber)
    if (wanninglist.value.length > 0) {
      const myModal = document.getElementById('searchSanctionModal');
      const modal = new Modal(myModal); // Instantiates your modal
      modal.show();
    } else {
      document.getElementById("newPawnClose").click();
      if (process_c.value === 'add') {
        addNew()
      } else if (process_c.value === 'edit') {
        update()
      }
    }
  } else {
    document.getElementById("newPawnClose").click();
    if (process_c.value === 'add') {
      addNew()
    } else if (process_c.value === 'edit') {
      update()
    }
  }
}

async function addNew() {
  collect_detail.value.ticketUid = uid_edit.value

  if (collect_detail.value.unitUid !== '' && collect_detail.value.unitUid !== '0') {
    collect_detail.value.unitName = filterUnit(collect_detail.value.unitUid)
  }

  if (collect_detail.value.mainCatUid !== '' && collect_detail.value.mainCatUid !== '0') {
    let ll = filterMainCat(collect_detail.value.mainCatUid)

    if (ll !== null) {
      collect_detail.value.mainCatCode = ll.code
      collect_detail.value.mainCatName = ll.name
      // console.log('ll : ' + JSON.stringify(ll.code) + ' ' + JSON.stringify(ll.name));
    }

  }

  if (collect_detail.value.fullValue === '') {
    collect_detail.value.fullValue = 0
  }

  if (collect_detail.value.diamondQuantity === '') {
    collect_detail.value.diamondQuantity = 0
  }

  if (collect_detail.value.weight === '') {
    collect_detail.value.weight = 0
  }

  if (selected.value !== '') {
    let ld = types.value.filter((c) => c.uid.toLowerCase().indexOf(selected.value.uid.toLowerCase()) >= 0);
    if (ld.length > 0) {
      if (ld[0].name !== 'อื่นๆ') {
        collect_detail.value.categoryUid = ld[0].uid
        collect_detail.value.categoryCode = ld[0].code
        collect_detail.value.categoryName = ld[0].fullName
      } else {
        collect_detail.value.categoryUid = ''
        collect_detail.value.categoryCode = ''
        collect_detail.value.categoryName = filterText.value.trim()
      }

    } else {
      collect_detail.value.categoryUid = ''
      collect_detail.value.categoryCode = ''
      collect_detail.value.categoryName = ''
    }
  } else {

    if (collect_detail.value.mainCatName === 'อื่นๆ') {
      collect_detail.value.categoryUid = ''
      collect_detail.value.categoryCode = ''
      collect_detail.value.categoryName = filterText.value.trim()
      await loadAssetList(collect_detail.value.mainCatUid)
      if (tplist.value.length > 0) {
        // console.log('tplist : ' + JSON.stringify(tplist.value));
        let ld = tplist.value.filter((c) => c.name.toLowerCase().indexOf('อื่นๆ') >= 0);
        if (ld.length > 0) {
          collect_detail.value.categoryUid = ld[0].uid
          collect_detail.value.categoryCode = ld[0].code
          collect_detail.value.categoryName = filterText.value.trim()
        }
      }
    } else {
      collect_detail.value.categoryUid = ''
      collect_detail.value.categoryCode = ''
      collect_detail.value.categoryName = filterText.value.trim()
    }
  }



  collect_detail.value.weight = parseFloat(collect_detail.value.weight)
  collect_detail.value.newPawnValue = parseFloat(collect_detail.value.newPawnValue)
  collect_detail.value.pawnValue = parseFloat(collect_detail.value.newPawnValue)
  collect_detail.value.tmpPawnValue = parseFloat(collect_detail.value.newPawnValue)
  collect_detail.value.tmpNewPawnValue = parseFloat(collect_detail.value.newPawnValue)
  collect_detail.value.fullValue = parseFloat(collect_detail.value.fullValue)
  collect_detail.value.diamondQuantity = parseInt(collect_detail.value.diamondQuantity)
  collect_detail.value.quantity = parseInt(collect_detail.value.quantity)


  if (collect_detail.value.unitUid === '0') {
    collect_detail.value.unitUid = ''
    collect_detail.value.unitName = ''
  }

  // console.log('addNew : ' + JSON.stringify(collect_detail.value));
  addItems()
  clearItem()
}

async function update() {

  if (collect_detail.value.unitUid !== '' && collect_detail.value.unitUid !== '0') {
    collect_detail.value.unitName = filterUnit(collect_detail.value.unitUid)
  }

  if (collect_detail.value.mainCatUid !== '' && collect_detail.value.mainCatUid !== '0') {
    let ll = filterMainCat(collect_detail.value.mainCatUid)
    if (ll !== null) {
      collect_detail.value.mainCatCode = ll.code
      collect_detail.value.mainCatName = ll.name
      // console.log('ll : ' + JSON.stringify(ll.code) + ' ' + JSON.stringify(ll.name));
    }

  }

  if (collect_detail.value.fullValue === '') {
    collect_detail.value.fullValue = 0
  }

  if (collect_detail.value.diamondQuantity === '') {
    collect_detail.value.diamondQuantity = 0
  }

  if (collect_detail.value.weight === '') {
    collect_detail.value.weight = 0
  }

  if (selected.value === '' && collect_detail.value.mainCatName !== 'อื่นๆ') {
    let sl = types.value.filter((c) => c.fullName.toLowerCase().indexOf(filterText.value.trim().toLowerCase()) >= 0);
    if (sl.length > 0) {
      selected.value = sl[0]
    }
  }




  if (selected.value !== '') {
    let ld = types.value.filter((c) => c.uid.toLowerCase().indexOf(selected.value.uid.toLowerCase()) >= 0);
    if (ld.length > 0) {
      if (ld[0].name !== 'อื่นๆ') {
        collect_detail.value.categoryUid = ld[0].uid
        collect_detail.value.categoryCode = ld[0].code
        collect_detail.value.categoryName = ld[0].fullName
      } else {
        collect_detail.value.categoryUid = ''
        collect_detail.value.categoryCode = ''
        collect_detail.value.categoryName = filterText.value.trim()
      }
    } else {
      collect_detail.value.categoryUid = ''
      collect_detail.value.categoryCode = ''
      collect_detail.value.categoryName = ''
    }
  } else {
    if (collect_detail.value.mainCatName === 'อื่นๆ') {
      collect_detail.value.categoryUid = ''
      collect_detail.value.categoryCode = ''
      collect_detail.value.categoryName = filterText.value.trim()
      await loadAssetList(collect_detail.value.mainCatUid)
      if (tplist.value.length > 0) {
        // console.log('tplist : ' + JSON.stringify(tplist.value));
        let ld = tplist.value.filter((c) => c.name.toLowerCase().indexOf('อื่นๆ') >= 0);
        if (ld.length > 0) {
          collect_detail.value.categoryUid = ld[0].uid
          collect_detail.value.categoryCode = ld[0].code
          collect_detail.value.categoryName = filterText.value.trim()
        }
      }
    } else {
      collect_detail.value.categoryUid = ''
      collect_detail.value.categoryCode = ''
      collect_detail.value.categoryName = filterText.value.trim()
    }

  }

  collect_detail.value.weight = parseFloat(collect_detail.value.weight)
  collect_detail.value.newPawnValue = parseFloat(collect_detail.value.newPawnValue)
  collect_detail.value.pawnValue = parseFloat(collect_detail.value.newPawnValue)
  collect_detail.value.tmpPawnValue = parseFloat(collect_detail.value.newPawnValue)
  collect_detail.value.tmpNewPawnValue = parseFloat(collect_detail.value.newPawnValue)
  collect_detail.value.fullValue = parseFloat(collect_detail.value.fullValue)
  collect_detail.value.diamondQuantity = parseInt(collect_detail.value.diamondQuantity)
  collect_detail.value.quantity = parseInt(collect_detail.value.quantity)




  if (collect_detail.value.unitUid === '0') {
    collect_detail.value.unitUid = ''
    collect_detail.value.unitName = ''
  }

  // console.log('update : ' + JSON.stringify(collect_detail.value));
  updateItems()
  clearItem()
}


function filterMainCat(str) {
  let name = listprint.value.filter((c) => c.uid === str);
  return name[0];
}

function filterUnit(str) {
  let name = listunit.value.filter((c) => c.uid === str);
  return name[0].name;
}

async function onSelectCollect(item) {
  // console.log('type : ' + listQ02E.value.type);
  if (listQ02E.value.type === 'PAWN') {
    collec_e.value = item
    await setItemDetail(item)
  } else {
    await setItemDetailView(item)
  }

}

async function onDelete(item) {
  // console.log('onDelete : ' + JSON.stringify(item.uid));
  await deleteItems(item)
}

async function onReturn(item) {
  // console.log('onReturn : ' + workItem_uid.value + " version : " + workItem_version.value);
  let obj = {
    uid: workItem_uid.value,
    action: 'return',
    version: workItem_version.value
  }
  await completeStep(obj)


}

async function onComplete() {
  // console.log('onComplete : ' + workItem_uid.value + " version : " + workItem_version.value);
  let obj = {
    uid: workItem_uid.value,
    action: 'complete',
    version: workItem_version.value
  }
  // console.log('listQ02E : ' + JSON.stringify(listQ02E.value));
  if (photoFile.value !== null || thumb.value !== null) {
    let date = new Date()
    let filename = 'ticketimg-' + date.getFullYear() + date.getMonth() + 1
      + date.getDate() + date.getHours() + date.getMinutes() + date.getSeconds();
    await addfileApi(filename)
  }

  if (cusUid_search.value !== workcaseDetail.value.ticket.relatedCusUid
    || cusName_search.value !== workcaseDetail.value.ticket.relatedCusName) {
    await getPaymentGroupInfo()
    let list = paymentGDetail.value.workCases

    for (let i = 0; i < list.length; i++) {
      if (list[i].ticket !== null && list[i].transactionType === 'PAWN') {
        let version = ''
        if (list[i].ticket.uid === uid_edit.value) {
          version = workcaseDetail.value.ticket.version
        } else {
          version = list[i].ticket.version
        }
        let objR = {
          ticketUid: list[i].ticket.uid,
          relatedCusUid: cusUid_search.value,
          relatedCusName: cusName_search.value,
          version: version,
        }
        await updateTicketRelatedCus(objR)
      }
    }
  }

  if (workcaseDetail.value.migration) {
    await updateWorkCase(obj)
  } else {
    await completeStep(obj)
  }

}

async function onUnLock(item) {
  // console.log('onUnLock : ' + JSON.stringify(item.uid) + " version : " + JSON.stringify(item.version));
  let lockL = []
  lockL.push({ uid: item.uid, version: item.version })
  await unlock(lockL)

}


async function completeStep(item) {

  let data = "";
  data = {
    uid: item.uid,
    action: item.action,
    version: item.version,
  };
  const payload = data;
  // console.log('completeStep  payload : ' + JSON.stringify(payload));
  const response = await storew.completeStepFetch(payload);
  // console.log('completeStep : ' + JSON.stringify(response.data));
  if (response) {
    if (response.status === 200) {
      if (response.data.rtnDesc === "success") {
        swal("บันทึกสำเร็จ", "", "success");
        if (item.action === 'complete') {
          await getPaymentGroupInfo()
          if (workcaseDetail.value.shopRepresent) {
            if ((cus_detail.value.cusShop.fingerPrint1Uid !== '0' || cus_detail.value.cusShop.fingerPrint2Uid !== '0')
              && (workcaseDetail.value.customerInfo.fpImageUid === '0' && workcaseDetail.value.customerInfo.fpTemplateUid === '0')) {
              let fingger = '0'
              let temp = '0'
              if (cus_detail.value.cusShop.fingerPrint1Uid !== '0') {
                fingger = cus_detail.value.cusShop.fingerPrint1Uid
                temp = cus_detail.value.cusShop.fgTemplate1Uid
              } else if (cus_detail.value.cusShop.fingerPrint2Uid !== '0') {
                fingger = cus_detail.value.cusShop.fingerPrint2Uid
                temp = cus_detail.value.cusShop.fgTemplate2Uid
              }
              let objF = {
                paymentGroupUid: workcaseDetail.value.paymentGroupUid,
                fpImageUid: fingger,
                fpTemplateUid: temp,
                version: paymentGDetail.value.version,
                otp: otpValue.value
              }
              // console.log('FPImage shopRepresent : ' + JSON.stringify(objF));
              await updateFPImage(objF)
            }

          } else {
            if (cus_detail.value.cusShop.fingerPrint1Uid === '0' && template_2.value !== '' &&
              (workcaseDetail.value.customerInfo.fpImageUid === '0' && workcaseDetail.value.customerInfo.fpTemplateUid === '0')
            ) {
              await addFinger()
              let objF = {
                paymentGroupUid: workcaseDetail.value.paymentGroupUid,
                fpImageUid: cus_detail.value.cusShop.fingerPrint1Uid,
                fpTemplateUid: cus_detail.value.cusShop.fgTemplate1Uid,
                version: paymentGDetail.value.version,
                otp: otpValue.value
              }
              // console.log('FPImage newfinger ' + JSON.stringify(objF));
              await updateFPImage(objF)
            } else if ((cus_detail.value.cusShop.fingerPrint1Uid !== '0' || cus_detail.value.cusShop.fingerPrint2Uid !== '0')
              && (workcaseDetail.value.customerInfo.fpImageUid === '0' && workcaseDetail.value.customerInfo.fpTemplateUid === '0')
              && template_2.value !== '') {
              fingertrans_uid.value = '0'
              fingertrans_temp.value = '0'
              await uploadFingerTransaction()
              if (fingertrans_uid.value !== '0' && fingertrans_temp.value !== '0') {
                let objF = {
                  paymentGroupUid: workcaseDetail.value.paymentGroupUid,
                  fpImageUid: fingertrans_uid.value,
                  fpTemplateUid: fingertrans_temp.value,
                  version: paymentGDetail.value.version,
                  otp: otpValue.value
                }
                // console.log('FPImage trans' + JSON.stringify(objF));
                await updateFPImage(objF)
              }
            }
          }
        }
      }
      await backToFirstTab()
    } else {
      swal("บันทึกไม่สำเร็จ", response.data.rtnDesc, "error");
    }
  }
}


async function addFinger() {
  let name = ''
  let date = new Date()
  if (template_2.value !== '') {
    await getCustomerInfo(cus_detail.value.uid)
    if (cus_detail.value.cusShop !== null) {
      name = 'fingerprint1-' + date.getFullYear() + date.getMonth() + 1
        + date.getDate() + date.getHours() + date.getMinutes() + date.getSeconds()
      await addFingerApi(name, bmpBase64.value)
      if (thumb_id.value !== '0') {
        cus_detail.value.cusShop.fingerPrint1Uid = thumb_id.value
      }

      name = 'template1-' + date.getFullYear() + date.getMonth() + 1
        + date.getDate() + date.getHours() + date.getMinutes() + date.getSeconds()
      await addFingerApi(name, template_2.value)
      if (thumb_id.value !== '0') {
        cus_detail.value.cusShop.fgTemplate1Uid = thumb_id.value
      }

      if (cus_detail.value.cusShop.fingerPrint1Uid !== '0' && cus_detail.value.cusShop.fgTemplate1Uid !== '0') {
        await updateCustomerShop('finger')

      }
    } else {
      swal("บันทึกไม่สำเร็จ", 'ไม่มีข้อมูลลูกค้าสาขา', "error");
    }

  }
}

async function uploadFingerTransaction() {
  let name = ''
  let date = new Date()
  if (template_2.value !== '') {
    if (cus_detail.value.cusShop !== null) {
      name = 'fingertrans-' + date.getFullYear() + date.getMonth() + 1
        + date.getDate() + date.getHours() + date.getMinutes() + date.getSeconds()
      await addFingerApi(name, bmpBase64.value)
      if (thumb_id.value !== '0') {
        fingertrans_uid.value = thumb_id.value
      }

      name = 'templatetrans-' + date.getFullYear() + date.getMonth() + 1
        + date.getDate() + date.getHours() + date.getMinutes() + date.getSeconds()
      await addFingerApi(name, template_2.value)
      if (thumb_id.value !== '0') {
        fingertrans_temp.value = thumb_id.value
      }
    }

  }
}

async function getPaymentGroupInfo() {
  let data = "";
  data = {
    uid: workcaseDetail.value.paymentGroupUid,
  }
  const payload = data;
  const response = await storepay.paymentGroupinfoFetch(payload);
  // console.log('getPaymentGroupInfo r : ' + JSON.stringify(response.data.data));
  if (response) {
    if (response.status === 200) {
      if (response.data.rtnDesc === "success") {
        paymentGDetail.value = response.data.data
        // let objF = {
        //   paymentGroupUid: workcaseDetail.value.paymentGroupUid,
        //   fpImageUid: '0',
        //   fpTemplateUid: '0',
        //   version: paymentGDetail.value.version,
        // }
        // console.log('updateFPImage xxx' + JSON.stringify(objF));
        // await updateFPImage(objF)

        // console.log('paymentGDetail : ' + JSON.stringify(paymentGDetail.value.version));
        // let list = paymentGDetail.value.workCases
        // for (let i = 0; i < list.length; i++) {
        //   if (list[i].ticket !== null && list[i].transactionType === 'PAWN') {
        //     let objR = {
        //       ticketUid: list[i].ticket.uid,
        //       relatedCusUid: cusUid_search.value,
        //       relatedCusName: cusName_search.value,
        //       version: list[i].ticket.version,
        //     }
        //     console.log('tuid : ' + uid_edit.value + ' objR : ' + JSON.stringify(objR));
        //     // await updateTicketRelatedCus(objR)
        //   }
        // }
        // console.log('paymentGDetail : ' + JSON.stringify(paymentGDetail.value.workCases[0].ticket));

      } else {
        // swal("บันทึกไม่สำเร็จ", response.data.rtnDesc, "error");
      }
    }
  }

}


async function updateWorkCase(obj) {

  let data = "";
  data = {
    uid: workcaseDetail.value.uid,
    ticket: workcaseDetail.value.ticket,
    interestUid: workcaseDetail.value.interestUid,
    promotionUid: workcaseDetail.value.promotionUid,
    interestRef: workcaseDetail.value.interestRef,
    interestCalc: workcaseDetail.value.interestCalc,
    interest: workcaseDetail.value.interest,
    roundInterest: workcaseDetail.value.roundInterest,
    incPrinciple: workcaseDetail.value.incPrinciple,
    decPrinciple: workcaseDetail.value.decPrinciple,
    newPawnValue: workcaseDetail.value.newPawnValue,
    totalPay: workcaseDetail.value.totalPay,
    totalReceive: workcaseDetail.value.totalReceive,
    diffValue: workcaseDetail.value.diffValue,
    pawnValue: workcaseDetail.value.pawnValue,
    interestPerMonth: workcaseDetail.value.interestPerMonth,
    dueDate: workcaseDetail.value.dueDate,
    trayId: workcaseDetail.value.trayId,
    migrateRefNumber: migrateRefNumber.value,
    remark: workcaseDetail.value.remark,
    version: workcaseDetail.value.version,
  };

  const payload = data;

  // console.log('updateWorkCase p : ' + JSON.stringify(payload));
  const response = await storewc.workCaseupdateFetch(payload);
  // console.log('updateWorkCase r : ' + JSON.stringify(response.data));
  if (response) {
    if (response.status === 200) {
      if (response.data.rtnDesc === "success") {
        workcaseDetail.value = response.data.data
        await completeStep(obj)
      } else {
        swal("บันทึกไม่สำเร็จ", response.data.rtnDesc, "error");
      }
    }
  }
}

async function lock(item, val) {
  let data = "";
  data = {
    lock: item,
  };
  const payload = data;
  // console.log('payload : ' + JSON.stringify(payload));
  const response = await storew.lockFetch(payload);
  // console.log('response : ' + JSON.stringify(response.data));
  if (response) {
    if (response.status === 200) {
      if (response.data.rtnDesc === "success") {
        selectGroup.value = false
        selectType.value = false
        loadDataQ02Api()
        if (val === 'auto') {
          swal({
            title: "บันทึกสำเร็จ",
            text: "",
            type: "success",
            timer: 1000
          }).then(() => {
            findQ02Owner()
          })
        } else {
          swal("บันทึกสำเร็จ", "", "success");
        }
      } else {
        swal("บันทึกไม่สำเร็จ", response.data.rtnDesc, "error");
      }
    }
  }
}

async function unlock(item) {
  let data = "";
  data = {
    lock: item,
  };
  const payload = data;
  // console.log('payload : ' + JSON.stringify(payload));
  const response = await storew.unlockFetch(payload);
  // console.log('response : ' + JSON.stringify(response.data));
  if (response) {
    if (response.status === 200) {
      if (response.data.rtnDesc === "success") {
        swal("บันทึกสำเร็จ", "", "success");
        if (workItem_uid.value !== '' && workItem_uid.value === item[0].uid) {
          backToSecondTab()
        } else {
          loadDataQ02OwnerApi()
        }

      } else {
        swal("บันทึกไม่สำเร็จ", response.data.rtnDesc, "error");
      }
    }
  }
}

async function addItems() {
  const payload = collect_detail.value;
  payload.remark = payload.remark.trim()
  payload.version = workcaseDetail.value.ticket.version
  // console.log('addItems p : ' + JSON.stringify(payload));
  const response = await storecl.collateralNewFetch(payload);
  // console.log('addItems r : ' + JSON.stringify(response.data));
  if (response) {
    if (response.status === 200) {
      if (response.data.rtnDesc === "success") {
        workcaseDetail.value.ticket.version = response.data.data.version
        // console.log('tk version : ' + JSON.stringify(workcaseDetail.value.ticket.version));
        swal("บันทึกสำเร็จ", "", "success");
        getcollateralList();
      } else {
        swal("บันทึกไม่สำเร็จ", response.data.rtnDesc, "error");
        if (response.data.rtnDesc === 'object already modified') {
          backToSecondTab()
        }
      }
    }
  }
}

async function updateItems() {
  const payload = collect_detail.value;
  payload.remark = payload.remark.trim()
  payload.version = workcaseDetail.value.ticket.version
  // console.log('updateItems p : ' + JSON.stringify(payload));
  const response = await storecl.collateralUpdateFetch(payload);
  // console.log('updateItems r : ' + JSON.stringify(response.data));
  if (response) {
    if (response.status === 200) {
      if (response.data.rtnDesc === "success") {
        workcaseDetail.value.ticket.version = response.data.data.version
        // console.log('tk version : ' + JSON.stringify(workcaseDetail.value.ticket.version));
        swal("บันทึกสำเร็จ", "", "success");
        getcollateralList();
      } else {
        swal("บันทึกไม่สำเร็จ", response.data.rtnDesc, "error");
        if (response.data.rtnDesc === 'object already modified') {
          backToSecondTab()
        }
      }
    }
  }
}


async function deleteItems(item) {
  let data = "";
  data = {
    uid: item.uid,
    version: workcaseDetail.value.ticket.version
  };
  const payload = data;
  // console.log('deleteItems p : ' + JSON.stringify(payload));
  const response = await storecl.collateralDeleteFetch(payload);
  // console.log('deleteItems r : ' + JSON.stringify(response.data));
  if (response) {
    if (response.status === 200) {
      if (response.data.rtnDesc === "success") {
        workcaseDetail.value.ticket.version = response.data.data.version
        // console.log('tk version : ' + JSON.stringify(workcaseDetail.value.ticket.version));
        swal("บันทึกสำเร็จ", "", "success");
        getcollateralList();
      } else {
        swal("บันทึกไม่สำเร็จ", response.data.rtnDesc, "error");
        if (response.data.rtnDesc === 'record not found') {
          getcollateralList()
        } else if (response.data.rtnDesc === 'object already modified') {
          backToSecondTab()
        }
      }
    }
  }
}

async function updateTicketRelatedCus(item) {
  let data = "";
  data = {
    ticketUid: item.ticketUid,
    relatedCusUid: item.relatedCusUid,
    relatedCusName: item.relatedCusName,
    version: item.version,
  };
  const payload = data;
  // console.log('updateTicketRelatedCus p : ' + JSON.stringify(payload));
  const response = await storewc.updateTicketRelatedCusFetch(payload);
  // console.log('updateTicketRelatedCus r : ' + JSON.stringify(response.data));
  if (response) {
    if (response.status === 200) {
      if (response.data.rtnDesc === "success") {
        if (item.ticketUid === uid_edit.value) {
          workcaseDetail.value.ticket.version = response.data.data.version
          // console.log('tk version : ' + JSON.stringify(workcaseDetail.value.ticket.version));
        }

      } else {
        swal("บันทึกไม่สำเร็จ", response.data.rtnDesc, "error");
      }
    }
  }
}

async function updateFPImage(obj) {
  const payload = obj;
  // console.log('updateFPImage p : ' + JSON.stringify(payload));
  const response = await storepay.updateFPImageFetch(payload);
  // console.log('updateFPImage r : ' + JSON.stringify(response.data));
  if (response) {
    if (response.status === 200) {
      if (response.data.rtnDesc === "success") {
        // paymentGDetail.value.version = response.data.data.version
      } else {
        swal("บันทึกไม่สำเร็จ", response.data.rtnDesc, "error");
        if (response.data.rtnDesc === 'object already modified') {
          backToSecondTab()
        }
      }
    }
  }

}


async function openDialogCus() {
  nameThC.value = ''
  nameEnC.value = ''
  docNumberC.value = ''
  customerlist.value = [];
  currentPageC.value = 1
  cusUid_search.value = ''
  cusName_search.value = ''
  loadCusAffiliateListApi()

}

async function selectCusAf(item) {
  cusUid_search.value = item.affiliateCusUid
  cusName_search.value = item.nameTh
  document.getElementById("closeModal").click();
  // console.log("obj : " + JSON.stringify(obj));
}


async function loadCusAffiliateListApi() {

  let data = "";

  data = {
    index: currentPageC.value - 1,
    size: perPageC.value,
    cusUid: cus_detail.value.uid,
  };

  const payload = data;
  // console.log("payload : " + JSON.stringify(payload));
  const response = await storecusa.cusAffiliateListFetch(payload);
  // console.log("loadCusAffiliateListApi : " + JSON.stringify(response.data));
  if (response) {
    if (response.status === 200) {
      if (response.data.rtnDesc === "success") {
        customerlist.value = response.data.data.list;
        totalRowsC.value = response.data.data.ext.total
        totalRows_sC.value = formatNumberString(totalRowsC.value)
        document.getElementById("searchNameCus").click();
      }
    }
  }
};

async function updatePaginationC() {
  customerlist.value = []
  let data = "";
  data = {
    index: currentPageC.value - 1,
    size: perPageC.value,
    cusUid: cus_detail.value.uid,
  };

  const payload = data;
  const response = await storecusa.cusAffiliateListFetch(payload);
  // console.log("loadDataApi : " + JSON.stringify(response));
  if (response) {
    if (response.status === 200) {
      if (response.data.rtnDesc === "success") {
        customerlist.value = response.data.data.list;
        totalRowsC.value = response.data.data.ext.total
        totalRows_sC.value = formatNumberString(totalRowsC.value)
      }
    }
  }
}

async function getcollateralList() {
  let list = []
  let data = "";
  data = {
    index: 0,
    size: 100,
    tuid: uid_edit.value,
  };
  const payload = data;
  // const response = await store.dispatch("unitListFetch", payload);
  const response = await storecl.collateralListFetch(payload);
  // console.log("loadDataApi : " + JSON.stringify(response));
  if (response) {
    if (response.status === 200) {
      if (response.data.rtnDesc === "success") {
        list = response.data.data.list;
        list.map(function (x) {
          x.chk = false
          x.remarkL = []
          return x
        });
        balweight.value = listQ02E.value.weight;
        balfull.value = listQ02E.value.fullValue;
        balquanity.value = listQ02E.value.quantity;
        baldiamond.value = listQ02E.value.totalDiamondQuantity
        balprice.value = listQ02E.value.newPawnValue;

        if (list.length > 0) {
          for (let i = 0; i < list.length; i++) {
            balweight.value = balweight.value - list[i].weight
            balfull.value = balfull.value - list[i].fullValue
            balquanity.value = balquanity.value - list[i].quantity
            baldiamond.value = baldiamond.value - list[i].diamondQuantity
            if (listQ02E.value.type === 'INC_PRINCIPLE' || listQ02E.value.type === 'DEC_PRINCIPLE') {
              balprice.value = balprice.value - list[i].tmpNewPawnValue
            } else {
              balprice.value = balprice.value - list[i].newPawnValue
            }

            list[i].remarkL.push({ val: list[i].remark })

          }
        }
        if (balweight.value !== null) {
          balweight.value = balweight.value.toFixed(2)
        }

        if (balprice.value !== null) {
          balprice.value = balprice.value.toFixed(2)
        }

        if (balfull.value !== null) {
          balfull.value = balfull.value.toFixed(2)
        }

        balweight_s.value = formatNumberString(balweight.value);
        balprice_s.value = formatNumberString(balprice.value);
        balfull_s.value = formatNumberString(balfull.value);
        balquanity_s.value = formatNumberString(balquanity.value);
        baldiamond_s.value = formatNumberString(baldiamond.value);
        listQ02D.value = list

      }
    }
  }
}

async function getworkCaseInfo(id) {
  let data = "";
  data = {
    uid: id,
  };
  const payload = data;

  const response = await storewc.workCaseinfoFetch(payload);
  // console.log("getworkCaseInfo : " + JSON.stringify(response.data));
  if (response) {
    if (response.status === 200) {
      if (response.data.rtnDesc === "success") {
        workcaseDetail.value = response.data.data
      }
    }
  }
}



async function findsanctionAsset(serial) {
  // 'abc-123' , 'ccc888'
  let data = "";
  data = {
    serialNumber: serial.trim(),
  };
  const payload = data;
  // console.log("ass : " + JSON.stringify(payload));
  const response = await storeass.sanctionaAssetFetch(payload);
  // console.log("ass : " + JSON.stringify(response.data));
  if (response) {
    if (response.status === 200) {
      if (response.data.rtnDesc === "success") {
        if (response.data.data !== null) {
          wanninglist.value.push(response.data.data)
          // console.log("ass : " + JSON.stringify(response.data));
        }

      }
    }
  }
}





async function addfileApi(filename) {
  let fileInput = null
  if (photoFile.value !== null) {
    fileInput = photoFile.value
  } else if (thumb.value !== null) {
    fileInput = thumb.value
  }
  let data = "";


  data = {
    name: filename,
    data: fileInput,
  };

  const payload = data;
  // console.log('addfileApi : ' + JSON.stringify(payload));
  // const response = await store.dispatch("addFetch", payload);
  const response = await stored.addFetch(payload);
  // console.log('addfileApi : ' + JSON.stringify(response.data));
  if (response) {
    if (response.status === 200) {
      if (response.data.rtnDesc === "success") {
        thumb_id.value = response.data.data.uid
        // console.log('thumb_id : ' + thumb_id.value);
        addTicketImage()
      } else {
        swal("บันทึกไฟล์ไม่สำเร็จ", response.data.rtnDesc, "error");
      }
    }
  }
}


async function addTicketImage() {
  let data = "";
  data = {
    ticketUid: uid_edit.value, // always required
    uid: "0",
    imageUid: thumb_id.value,
    remark: "",
    version: "0"
  };

  const payload = data;
  // console.log('addTicketImage : ' + JSON.stringify(payload));
  // const response = await store.dispatch("addFetch", payload);
  const response = await storeti.ticketImageNewFetch(payload);
  // console.log('addTicketImage : ' + JSON.stringify(response.data));
  if (response) {
    if (response.status === 200) {
      if (response.data.rtnDesc === "success") {
        // swal("บันทึกสำเร็จ", "", "success");
        // loadDataApi();
      } else {
        swal("บันทึกไม่สำเร็จ", response.data.rtnDesc, "error");
      }
    }
  }
}


async function onReturnDialog() {
  swal({
    title: "แก้ไขรายการ",
    text: "คุณต้องการตีกลับรายการนี้",
    icon: "warning",
    allowOutsideClick: false,
    showCloseButton: false,
    showCancelButton: true,
    closeOnConfirm: false,
    focusConfirm: false,
    animation: "slide-from-top",
    confirmButtonText: "ตกลง",
    cancelButtonText: "ยกเลิก",
  }).then((result) => {
    if (result.isConfirmed) {
      onReturn()
    } else if (result.dismiss == "cancel") {
      swal("ยกเลิกรายการ", "", "error")
    }
  });
}

async function onCompleteDialog() {
  swal({
    title: "ตรวจสอบรายการ",
    text: "คุณต้องการบันทึกรายละเอียดรายการทรัพย์จำนำนี้",
    icon: "info",
    allowOutsideClick: false,
    showCloseButton: false,
    showCancelButton: true,
    closeOnConfirm: false,
    focusConfirm: false,
    animation: "slide-from-top",
    confirmButtonText: t('buttons.conf'),
    cancelButtonText: t('buttons.canc'),
  }).then((result) => {
    if (result.isConfirmed) {
      onComplete()
    } else if (result.dismiss == "cancel") {
      swal("ยกเลิกรายการ", "", "error")
    }
  });
}

async function onUnLockDialog(item) {
  swal({
    title: "ปลดล็อกรายการ",
    text: "คุณต้องการปลดล็อกรายการนี้",
    icon: "warning",
    allowOutsideClick: false,
    showCloseButton: false,
    showCancelButton: true,
    closeOnConfirm: false,
    focusConfirm: false,
    animation: "slide-from-top",
    confirmButtonText: t('buttons.conf'),
    cancelButtonText: t('buttons.canc'),
  }).then((result) => {
    if (result.isConfirmed) {
      onUnLock(item)
    } else if (result.dismiss == "cancel") {
      swal("ยกเลิกรายการ", "", "error")
    }
  });
}


async function onImgDialog() {
  swal({
    title: "",
    text: "คุณต้องการเพิ่มภาพจาก",
    icon: "info",
    allowOutsideClick: false,
    showCloseButton: false,
    showCancelButton: true,
    closeOnConfirm: false,
    focusConfirm: false,
    animation: "slide-from-top",
    confirmButtonText: "ตกลง",
    cancelButtonText: "ยกเลิก",
    input: 'select',
    inputOptions: {
      'Camera': 'ถ่ายภาพ',
      'File': 'แนบไฟล์ภาพถ่าย',
    },
    inputPlaceholder: 'กรุณาเลือก',
    inputValidator: function (value) {
      return new Promise(function (resolve, reject) {
        if (value === 'Camera') {
          resolve()
          thumb.value = null
          preview.value = ''
          photoFile.value = null
          openCamera()
        } else if (value === 'File') {
          thumb.value = null
          preview.value = ''
          photoFile.value = null
          document.getElementById("upload").value = null;
          resolve()
          document.getElementById("attachmenDialog").click();
        } else {
          resolve("กรุณาเลือกรายการ :)");
        }
      })
    }
  });
}



async function getFile(urls) {
  let file_L = []
  const key = JSON.parse(localStorage.getItem("FR-SDK-nvtx-boapp"));
  const accessToken = key.accessToken;
  axios.defaults.headers["Authorization"] = `Bearer ${accessToken}`;
  const requests = urls.map((url) => axios.get(url));
  await Promise.all(requests).then(allResults => {
    if (allResults) {
      file_L = allResults
      if (file_L !== null) {

        for (let i = 0; i < file_L.length; i++) {
          // console.log('file_L : ' + JSON.stringify(file_L.value[i].data.data.data) + ' file_L 2 : ' + JSON.stringify(file_L.value[i].data.data.name));
          if (file_L[i].data.data !== null) {
            const file = getFileFromBase64(file_L[i].data.data.data, file_L[i].data.data.name)
            const data = URL.createObjectURL(file);
            let obj = {
              src: data,
              thumbnailImageSrc: data,
              alt: '',
              title: ''
            }
            images.value.push(obj)
          } else {
            // console.log('no dataFile ')
            loading_d.value = false
          }

          // console.log('File xx : ', file_L.value[i].file.type + 'File size : ', file_L.value[i].file.size + ' bytes in size  name : ' + file_L.value[i].data.data.name);
        }
      }
    }
  })

}

function getFileFromBase64(string64, fileName) {
  var ext = fileName.split('.').pop();
  let type = '';
  let trimmedString = '';
  if (ext === 'jpg') {
    type = 'image/jpeg'
  } else if (ext === 'png') {
    type = 'image/png'
  } else if (ext === 'bmp') {
    type = 'image/bmp'
  }
  if (type !== 'image/bmp') {
    trimmedString = string64.replace('dataimage/jpegbase64', '');
  } else {
    string64.replace('data:image/bmp;base64,', '');
  }

  // const trimmedString = string64;
  const imageContent = atob(trimmedString);
  const buffer = new ArrayBuffer(imageContent.length);
  const view = new Uint8Array(buffer);

  for (let n = 0; n < imageContent.length; n++) {
    view[n] = imageContent.charCodeAt(n);
  }



  const blob = new Blob([buffer], { type });
  return new File([blob], fileName, { lastModified: new Date().getTime(), type });
}




function setGallery() {

  var i, y = "";

  for (i = 0; i < images.value.length; i++) {

    if (i == 0) {
      // console.log('itemImg xxx : ' + i + " " + JSON.stringify(images.value[i].src))
      y = y + '<button type = "button" data-bs-target="#lookupImages" data-bs-slide-to="' + String(i) + '"' +
        ' class="active" aria-current="true" aria-label="Slide ' + Number(i + 1) + '"> </button >';
    } else {
      // console.log('itemImg zzz : ' + i + " " + JSON.stringify(images.value[i].src))
      y = y + '<button type = "button" data-bs-target="#lookupImages" data-bs-slide-to="' + String(i) + '"' +
        ' aria-label="Slide ' + Number(i + 1) + '"> </button >';
    }

  }
  document.getElementById("demo1").innerHTML = y;
}


// Fetch data function
const fetchData = async () => {
  try {
    loading.value = true;
    await new Promise(res => setTimeout(res, 500));
    // console.log('typesNew fetch : ' + JSON.stringify(typesNew.value))
    const res = typesNew.value.slice(page_s.value, page_e.value);
    // console.log('res fetch : ' + JSON.stringify(res))
    dataList.value.push(...res);
    loadMoreStatus.value = res.length < limit ? "no-more" : "idle";
    page_s.value = page_s.value + res.length;
    page_e.value = page_s.value + limit;
    showSuggestions.value = true;

    let re = String(res.length).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
    let ret = String(dataList.value.length).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
    // total.value = ' result : ' + re + ' / ' + ret
  } catch (error) {
    console.error('Error fetching data:', error);
    loading.value = false;
  } finally {
    loading.value = false;
  }
};

// Handle scroll event for infinite loading
const handleScroll = () => {
  const scrollContainer = list.value;
  // console.error('scrollTop :', scrollContainer.scrollTop);
  // console.error('clientHeight :', scrollContainer.clientHeight);
  // console.error('scrollHeight :', scrollContainer.scrollHeight);
  if (scrollContainer.scrollTop + scrollContainer.clientHeight >= scrollContainer.scrollHeight) {
    // Reached the bottom of the scroll container, load more data
    if (dataList.value.length <= 10) {
      // console.log("data : " + dataList.value.length);
      scrollContainer.scrollTo(0, 0);
    }

    if (typesNew.value.length > 0) {
      fetchData();
    }

  }
};


// Function to update widths
const updateWidths = () => {
  if (parentRef.value && list.value) {
    parentWidth.value = parentRef.value.clientWidth;
    childWidth.value = parentRef.value.clientWidth;
  }
}
/* eslint-disable no-useless-escape */
const updateText = () => {
  let inp = document.querySelector("#inp");
  let list = document.querySelectorAll('#myList li')
  // console.log("updated() list : " + list.length);
  var filtter = inp.value.trim().replaceAll(">>", " ");
  var text = filtter.toLowerCase().replaceAll("'", " ");
  var invalid = /[°"§%()\[\]{}=\\?´`'#<>|,;.:+_-]+/g;
  var repl = text.replace(invalid, "")
  var arrt = repl.match(/\S+/g);

  if (arrt !== null) {
    const arr = Array.from(list);
    arr.forEach((x) => {
      // if (re.test(x.textContent)) {
      const txtValue = x.innerText || x.textContent;
      var string = txtValue;

      var finalAns = string;
      for (var i = arrt.length - 1; i >= 0; i--) {
        finalAns = finalAns.replace(
          RegExp(arrt[i], "gi"),
          '<b style="color:orange;">$&</b>'
        );
      }
      x.innerHTML = finalAns;
      x.style.display = "block";
    });
  }
}


async function checkbeforeScan() {
  if (count_fg.value === 0) {
    onScanFinger()
  } else {
    document.getElementById("fingerModal").click()
  }

}


async function onScanFinger() {
  matchResult.value = 0
  count_scan.value = 0
  template_1.value = ''
  template_2.value = ''

  if (urls.value.length === 0) {
    templatef_1.value = '';
    templatef_2.value = '';
    bmpBase64f_1.value = '';
    bmpBase64f_2.value = '';
    if (cus_detail.value.cusShop.fingerPrint1Uid !== '0') {
      // console.log('finger1 : ' + cus_detail.value.cusShop.fingerPrint1Uid)
      urls.value.push(String(st.url_api + "/doc-svc/document/info/" + cus_detail.value.cusShop.fingerPrint1Uid));
    }
    if (cus_detail.value.cusShop.fgTemplate1Uid !== '0') {
      // console.log('temp1 : ' + cus_detail.value.cusShop.fgTemplate1Uid)
      urls.value.push(String(st.url_api + "/doc-svc/document/info/" + cus_detail.value.cusShop.fgTemplate1Uid));
    }
    if (cus_detail.value.cusShop.fingerPrint2Uid !== '0') {
      // console.log('finger2 : ' + cus_detail.value.cusShop.fingerPrint2Uid)
      urls.value.push(String(st.url_api + "/doc-svc/document/info/" + cus_detail.value.cusShop.fingerPrint2Uid));
    }
    if (cus_detail.value.cusShop.fgTemplate2Uid !== '0') {
      // console.log('temp2 : ' + cus_detail.value.cusShop.fgTemplate2Uid)
      urls.value.push(String(st.url_api + "/doc-svc/document/info/" + cus_detail.value.cusShop.fgTemplate2Uid));
    }

    if (urls.value.length > 0) {
      await getFilefinger()
      if (bmpBase64f_1.value !== '') {
        document.getElementById('FPImage1').src = bmpBase64f_1.value
        template_1.value = templatef_1.value
      } else if (bmpBase64f_2.value !== '') {
        document.getElementById('FPImage1').src = bmpBase64f_2.value
        template_1.value = templatef_2.value
      }
      document.getElementById('FPImage2').src = nofinger
      document.getElementById("fingerModal").click()
    } else {
      document.getElementById('FPImage2').src = nofinger
      document.getElementById("fingerModal").click()
    }

  } else if (bmpBase64f_1.value !== '' || bmpBase64f_2.value !== '') {
    if (bmpBase64f_1.value !== '') {
      document.getElementById('FPImage1').src = bmpBase64f_1.value
      template_1.value = templatef_1.value
    } else if (bmpBase64f_2.value !== '') {
      document.getElementById('FPImage1').src = bmpBase64f_2.value
      template_1.value = templatef_2.value
    }
    document.getElementById('FPImage2').src = nofinger
    document.getElementById("fingerModal").click()
  } else {
    document.getElementById('FPImage2').src = nofinger
    document.getElementById("fingerModal").click()
  }

}


function SuccessFunc2(result) {
  if (result.ErrorCode == 0) {
    /* 	Display BMP data in image tag
        BMP data is in base 64 format 
    */
    if (result != null && result.BMPBase64.length > 0) {
      document.getElementById('FPImage2').src = "data:image/bmp;base64," + result.BMPBase64;
      // console.log('result.BMPBase64 : ' + result.BMPBase64)
      bmpBase64.value = result.BMPBase64
    }
    template_2.value = result.TemplateBase64;
    // console.log('template_2 : ' + template_2.value)
    if (template_1.value !== "" && template_2.value !== "") {
      startMatch()
    }
  }
  else {
    // alert("Fingerprint Capture Error Code:  " + result.ErrorCode + ".\nDescription:  " + ErrorCodeToString(result.ErrorCode) + ".");
    console.log("Fingerprint Capture Error Code:  " + result.ErrorCode + ".\nDescription:  " + ErrorCodeToString(result.ErrorCode) + ".")
  }
}

function ErrorFunc(status) {
  /* 	
      If you reach here, user is probabaly not running the 
      service. Redirect the user to a page where he can download the
      executable and install it. 
  */
  // alert("Check if SGIBIOSRV is running; status = " + status + ":");
  console.log("Check if SGIBIOSRV is running; status = " + status + ":")
}

function CallSGIFPGetData(successCall, failCall) {
  template_2.value = ''
  document.getElementById('FPImage2').src = nofinger
  var uri = "https://localhost:8443/SGIFPCapture";
  var xmlhttp = new XMLHttpRequest();
  xmlhttp.onreadystatechange = function () {
    if (xmlhttp.readyState == 4 && xmlhttp.status == 200) {
      let fpobject = JSON.parse(xmlhttp.responseText);
      successCall(fpobject);
    }
    else if (xmlhttp.status == 404) {
      failCall(xmlhttp.status)
    }
  }
  xmlhttp.onerror = function () {
    failCall(xmlhttp.status);
  }
  var params = "Timeout=" + "10000";
  params += "&Quality=" + "50";
  params += "&licstr=" + encodeURIComponent(secugen_lic.value);
  params += "&templateFormat=" + "ISO";
  xmlhttp.open("POST", uri, true);
  xmlhttp.send(params);
}

function startMatch() {
  if (template_1.value == "" || template_2.value == "") {
    alert("Please scan two fingers to verify!!");
    return;
  } else {
    count_scan.value = 0
    matchScore(succMatch, failureFunc)
  }
}

function matchScore(succFunction, failFunction) {

  var uri = "https://localhost:8443/SGIMatchScore";

  var xmlhttp = new XMLHttpRequest();
  xmlhttp.onreadystatechange = function () {
    if (xmlhttp.readyState == 4 && xmlhttp.status == 200) {
      let fpobject = JSON.parse(xmlhttp.responseText);
      succFunction(fpobject);
    }
    else if (xmlhttp.status == 404) {
      failFunction(xmlhttp.status)
    }
  }

  xmlhttp.onerror = function () {
    failFunction(xmlhttp.status);
  }
  var params = "template1=" + encodeURIComponent(template_1.value);
  params += "&template2=" + encodeURIComponent(template_2.value);
  params += "&licstr=" + encodeURIComponent(secugen_lic.value);
  params += "&templateFormat=" + "ISO";
  xmlhttp.open("POST", uri, false);
  xmlhttp.send(params);
}

function succMatch(result) {
  // var idQuality = document.getElementById("quality").value;
  matchResult.value = 0

  if (result.ErrorCode == 0) {
    matchResult.value = Number(result.MatchingScore)
    if (result.MatchingScore >= idQuality.value) {
      // alert("MATCHED ! (" + result.MatchingScore + ")");
    } else {
      // alert("NOT MATCHED ! (" + result.MatchingScore + ")");
      if (bmpBase64f_1.value !== '' && bmpBase64f_2.value !== '' && count_scan.value === 0) {
        findOtherfinger()
      } else {
        if (count_scan.value === 0) {
          count_fg.value = count_fg.value + 1
        }
      }

    }
  } else {
    alert("Error Scanning Fingerprint ErrorCode = " + result.ErrorCode);
  }
}

function failureFunc(error) {
  alert("On Match Process, failure has been called");
}

function findOtherfinger() {
  count_scan.value = 1
  if (template_1.value === templatef_1.value) {
    document.getElementById('FPImage1').src = bmpBase64f_2.value
    template_1.value = templatef_2.value
    matchScore(succMatch, failureFunc)
  } else if (template_1.value === templatef_2.value) {
    document.getElementById('FPImage1').src = bmpBase64f_1.value
    template_1.value = templatef_1.value
    matchScore(succMatch, failureFunc)
  }
  if (Number(matchResult.value) < idQuality.value) {
    count_fg.value = count_fg.value + 1
  }
}



function ErrorCodeToString(ErrorCode) {
  var Description;
  switch (ErrorCode) {
    // 0 - 999 - Comes from SgFplib.h
    // 1,000 - 9,999 - SGIBioSrv errors 
    // 10,000 - 99,999 license errors
    case 51:
      Description = "System file load failure";
      break;
    case 52:
      Description = "Sensor chip initialization failed";
      break;
    case 53:
      Description = "Device not found";
      break;
    case 54:
      Description = "Fingerprint image capture timeout";
      break;
    case 55:
      Description = "No device available";
      break;
    case 56:
      Description = "Driver load failed";
      break;
    case 57:
      Description = "Wrong Image";
      break;
    case 58:
      Description = "Lack of bandwidth";
      break;
    case 59:
      Description = "Device Busy";
      break;
    case 60:
      Description = "Cannot get serial number of the device";
      break;
    case 61:
      Description = "Unsupported device";
      break;
    case 63:
      Description = "SgiBioSrv didn't start; Try image capture again";
      break;
    default:
      Description = "Unknown error code or Update code to reflect latest result";
      break;
  }
  return Description;
}


async function addFingerApi(name, datafile) {
  thumb_id.value = '0'
  let data = "";
  data = {
    name: name + ".bmp",
    data: datafile,
  };

  const payload = data;
  // console.log('addfileApi : ' + JSON.stringify(payload));
  const response = await stored.addFetch(payload);
  // console.log('addFingerApi : ' + JSON.stringify(response.data));
  if (response.status === 200) {
    if (response.data.rtnDesc === "success") {
      thumb_id.value = response.data.data.uid
    } else {
      swal("บันทึกไฟล์ไม่สำเร็จ", response.data.rtnDesc, "error");
    }
  }
}


async function getFilefinger() {
  let file_L = []
  const keyA = JSON.parse(localStorage.getItem("FR-SDK-nvtx-boapp"));
  const accessToken = keyA.accessToken;
  axios.defaults.headers["Authorization"] = `Bearer ${accessToken}`;
  const requests = urls.value.map((url) => axios.get(url));
  await axios.all(requests).then(allResults => {
    if (allResults) {
      file_L = allResults
      if (file_L !== null) {

        for (let i = 0; i < file_L.length; i++) {
          // console.log('file_L : ' + JSON.stringify(file_L[i].data.data) + ' file_L 2 : ' + JSON.stringify(file_L[i].data.data.name));
          if (file_L[i].data.data !== null) {
            if (file_L[i].data.data.name.includes('fingerprint1')) {
              // document.getElementById('FPImage1').src = "data:image/bmp;base64," + file_L[i].data.data.data;
              bmpBase64f_1.value = "data:image/bmp;base64," + file_L[i].data.data.data;
            } else if (file_L[i].data.data.name.includes('fingerprint2')) {
              // document.getElementById('FPImage2').src = "data:image/bmp;base64," + file_L[i].data.data.data;
              bmpBase64f_2.value = "data:image/bmp;base64," + file_L[i].data.data.data;
            } else if (file_L[i].data.data.name.includes('template1')) {
              // document.getElementById('FPImage2').src = "data:image/bmp;base64," + file_L[i].data.data.data;
              templatef_1.value = file_L[i].data.data.data;
            } else if (file_L[i].data.data.name.includes('template2')) {
              // document.getElementById('FPImage2').src = "data:image/bmp;base64," + file_L[i].data.data.data;
              templatef_2.value = file_L[i].data.data.data;
            }

          } else {
            // console.log('no dataFile ')
          }

          // console.log('File xx : ', file_L.value[i].file.type + 'File size : ', file_L.value[i].file.size + ' bytes in size  name : ' + file_L.value[i].data.data.name);
        }
      }
    }
  })
}

async function requestOTP() {
  document.getElementById("fingerModal").click()
  if (cus_detail.value.cusShop.phoneNo1 === null || cus_detail.value.cusShop.phoneNo1 === '') {
    swal("กรุณาเพิ่มเบอร์มือถือ", "", "error");
  } else {
    if (otpTime.value) {
      const myModal = document.getElementById('otpModal');
      const modal = new Modal(myModal); // Instantiates your modal
      modal.show();
    } else {
      await generateOTP('new')
    }
  }
}


async function generateOTP(val) {
  otpMsg.value = ''
  otpRef.value = ''
  let data = "";
  data = {
    userLevel: 'customer',
    userUid: cus_detail.value.uid,
    transactionUid: cus_detail.value.uid,
  };
  const payload = data;
  userSStore.generateOTP(payload).then((data) => {
    // console.log("generateOTP x : ", JSON.stringify(data));
    otpRef.value = data.otpPrefix
    otpValidate.value = data.validity
    if (otpRef.value !== '' && otpValidate.value !== null && val === 'new') {
      onOpenOTPModal()
    } else if (otpRef.value !== '' && otpValidate.value !== null && val === 'resend') {
      otpValue.value = ''
      otpTime.value = true
      otpResend.value = true
      otpReTime.value = 30
      timerOtp(true)
      timerResend(true)
    }
  }).catch((err) => {
    if (err.response) {
      console.error("", err);
      otpMsg.value = 'ไม่สำเร็จ : ' + err.response.data.message
      otpReTime.value = 30
      timerResend(true)
    }
  });
}

async function validateOTP() {
  otpMsg.value = ''
  let data = "";
  data = {
    transactionUid: cus_detail.value.uid,
    otpPrefix: otpRef.value,
    otp: otpValue.value,
  };

  const payload = data;
  userSStore.validateOTP(payload).then((data) => {
    // console.log("validateOTP x : ", JSON.stringify(data));
    otpMsg.value = data.message
    if (data.message === 'OK') {
      document.getElementById("closeOtp").click()
      otpValidate.value = 1
      timerOtp(false)
      otpReTime.value = 1
      timerResend(false)
      finger.value = true
      if (workcaseDetail.value.migration) {
        template_2.value = templatef_1.value;
        bmpBase64.value = bmpBase64f_1.value.replace('data:image/bmp;base64,', '');
      }
    }
  }).catch((err) => {
    if (err.response) {
      console.error("", err);
      otpMsg.value = 'ไม่สำเร็จ : ' + err.response.data.message
      otpValidate.value = 1
      timerOtp(false)
      if (document.getElementById('timer-otp')) {
        document.getElementById('timer-otp').innerHTML = '00:00';
      }

    }
  });
}

async function onOpenOTPModal() {
  otpValue.value = ''
  otpMsg.value = ''
  otpTime.value = true
  otpResend.value = true
  const myModal = document.getElementById('otpModal');
  const modal = new Modal(myModal); // Instantiates your modal
  modal.show();
  timerOtp(true)
  otpReTime.value = 30
  timerResend(true)
}
async function onOpenFingerScan() {
  document.getElementById("closeOtp").click()
  document.getElementById("fingerModal").click()
}

function timerOtp(timerOn) {
  var m = Math.floor(otpValidate.value / 60);
  var s = otpValidate.value % 60;
  m = m < 10 ? '0' + m : m;
  s = s < 10 ? '0' + s : s;
  if (document.getElementById('timer-otp')) {
    document.getElementById('timer-otp').innerHTML = m + ':' + s;
  }

  otpValidate.value -= 1;



  if (otpValidate.value >= 0 && timerOn) {
    setTimeout(function () {
      timerOtp(otpValidate.value, timerOn);
    }, 1000);
    return;
  }


  if (otpValidate.value <= 0) {
    otpTime.value = false
  }


  if (!timerOn) {
    // Do validate stuff here
    return;
  }
  // Do timeout stuff here


}


function timerResend(timerOn) {
  var m = Math.floor(otpReTime.value / 60);
  var s = otpReTime.value % 60;
  m = m < 10 ? '0' + m : m;
  s = s < 10 ? '0' + s : s;
  if (document.getElementById('timer-resend')) {
    document.getElementById('timer-resend').innerHTML = m + ':' + s;
  }
  otpReTime.value -= 1;

  if (otpReTime.value >= 0 && timerOn) {
    setTimeout(function () {
      timerResend(timerOn);
    }, 1000);
    return;
  }

  if (otpReTime.value <= 0) {
    otpResend.value = false
  }

  if (!timerOn) {
    // Do validate stuff here
    return;
  }
  // Do timeout stuff here
}


const loadAssetList = async (uid) => {
  tplist.value = []
  let data = "";
  data = {
    index: 0,
    size: 100,
    uid: uid,
  };

  const payload = data;
  // console.log("payload : " + JSON.stringify(payload));
  const response = await storec.categoryListFetch(payload);
  // console.log("response : " + JSON.stringify(response.data.data));
  if (response) {
    if (response.status === 200) {
      if (response.data.rtnDesc === "success") {
        tplist.value = response.data.data.list
      }
    }
  };
}




onUpdated(() => {
  updateWidths();
  updateText()

})


onMounted(() => {
  updateWidths();
  window.addEventListener('resize', updateWidths);

  if (document.getElementById("choices-gender")) {
    var gender = document.getElementById("choices-gender");
    new Choices(gender);
  }

  if (document.getElementById("choices-language")) {
    var language = document.getElementById("choices-language");
    new Choices(language);
  }

  if (document.getElementById("choices-month")) {
    var month = document.getElementById("choices-month");
    setTimeout(function () {
      new Choices(month);
    }, 1);

    // eslint-disable-next-line no-unused-vars
    var d = new Date();
    var monthArray = new Array();
    monthArray[0] = "January";
    monthArray[1] = "February";
    monthArray[2] = "March";
    monthArray[3] = "April";
    monthArray[4] = "May";
    monthArray[5] = "June";
    monthArray[6] = "July";
    monthArray[7] = "August";
    monthArray[8] = "September";
    monthArray[9] = "October";
    monthArray[10] = "November";
    monthArray[11] = "December";
    for (let m = 0; m <= 11; m++) {
      var optn = document.createElement("OPTION");
      optn.text = monthArray[m];
      // server side month start from one\
      optn.value = m + 1;
      // if june selected
      if (m == 1) {
        optn.selected = true;
      }
      month.options.add(optn);
    }
  }

  if (document.getElementById("choices-day")) {
    var day = document.getElementById("choices-day");
    setTimeout(function () {
      new Choices(day);
    }, 1);

    for (let y = 1; y <= 31; y++) {
      var optn1 = document.createElement("OPTION");
      optn1.text = y;
      optn1.value = y;

      if (y == 1) {
        optn1.selected = true;
      }

      day.options.add(optn1);
    }
  }

  if (document.getElementById("choices-year")) {
    var year = document.getElementById("choices-year");
    setTimeout(function () {
      new Choices(year);
    }, 1);

    for (let y = 1900; y <= 2020; y++) {
      var optn2 = document.createElement("OPTION");
      optn2.text = y;
      optn2.value = y;

      if (y == 2020) {
        optn.selected = true;
      }

      year.options.add(optn2);
    }
  }

  if (document.getElementById("choices-skills")) {
    var skills = document.getElementById("choices-skills");
    new Choices(skills, {
      delimiter: ",",
      editItems: true,
      maxItemCount: 5,
      removeItemButton: true,
      addItems: true,
    });
  }

  if (document.getElementById("first-home-tab")) {
    document.getElementById("first-home-tab").classList.add('show', 'active')
  }

  if (document.getElementById("firstHome")) {
    document.getElementById("firstHome").classList.add('show', 'active')
  }


});

</script>

<style lang="scss" scoped>
.review {
  border: 2px solid #1a73e8;
  border-radius: 6px;
  background: #d9e9ff;
}

.grid-container {
  max-width: 990px;
  display: grid;
  grid-gap: 10px;
  grid-template-columns: 1fr 1fr 1fr 1fr;
}

.btnClose {
  border-color: transparent !important;
  background-color: white !important;
  vertical-align: baseline;

  i.material-icons {
    padding-top: 8px;
    color: gray-600;
  }
}

.left {
  display: flex;
  justify-content: flex-end;
  margin-right: auto;
  margin-left: 0;
}

.inline-icon {
  display: inline-flex;
  vertical-align: top;
  font-size: 28px !important;
}

.error {
  font-weight: bold;
  color: red;
}

.loading-state {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: 9999;
  display: flex;
  justify-content: center;
  align-items: center;
}

.grid-containerB {
  max-width: 990px;
  display: grid;
  grid-gap: 10px;
  grid-template-columns: 1fr 1fr 1fr 1fr 1f;
}
</style>
