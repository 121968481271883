<template>
  <div class="container-fluid">
    <div class="row mb-5">
      <div class="col-12">
        <div class="card h-100 mt-3">
          <div class="card-header mb-0">
            <h5>{{ $t("content.gcashmove") }}</h5>
          </div>
          <div class="card-body pt-0">
            <div class="row">
              <div class="col-lg-3">
                <label class="form-label ms-0">{{ $t("content.sdate") }}</label>
                <VueDatePicker v-model="start_date" :format="format" auto-apply :teleport="true" :max-date="new Date()"
                  :enable-time-picker="false" month-name-format="long" :clearable="false" :text-input="textInputOptions"
                  fluid />
              </div>

              <div class="col-lg-3">
                <label class="form-label ms-0">{{ $t("content.edate") }}</label>
                <VueDatePicker v-model="end_date" :format="format" auto-apply :teleport="true" :max-date="new Date()"
                  :enable-time-picker="false" month-name-format="long" :clearable="false" :text-input="textInputOptions"
                  fluid />
              </div>



              <div class="col-lg-3">
                <label class="form-label ms-0">{{
                  $t("content.amnsourcemoney")
                }}</label>
                <select v-model="search_sourcemoney" class="form-select">
                  <option value="" selected>{{ $t("content.optall") }}</option>
                  <optgroup :label="$t('apps.mcash')">
                    <option v-for="(item, index) in listcashAcc" :value="item.uid" :key="index">
                      {{ item.name }}
                    </option>
                  </optgroup>
                  <optgroup :label="$t('content.amnbankacc')">
                    <option v-for="(item, index) in listbankpawn" :value="item.uid" :key="index">
                      {{ item.shortName }}
                    </option>
                  </optgroup>
                </select>
              </div>

              <div class="col-lg-3">
                <label class="form-label ms-0">{{ $t("content.transc") }}</label>
                <select class="form-select" v-model="search_cat" @change="checkSubCat">
                  <option value="" selected>{{ $t("content.optall") }}</option>
                  <option v-for="(a, opt1) in listtx" :key="opt1" :value="a.val">
                    {{ a.items }}
                  </option>
                </select>
              </div>

              <div class="col-lg-3">
                <label class="form-label ms-0">{{ $t("content.transc_sub") }}</label>
                <select class="form-select" v-model="search_subcat"
                  :disabled="search_cat === '' || listsubcat.length === 0">
                  <option value="" selected>{{ $t("content.optall") }}</option>
                  <option v-for="(a, opt1) in listsubcat" :key="opt1" :value="a.uid">
                    {{ a.name }}
                  </option>
                </select>
              </div>

              <div class="col-lg-3">
                <label class="form-label ms-0">{{ $t("content.names") }}</label>
                <div class="input-group">
                  <input v-model="cusName_search" type="text" class="form-control" aria-describedby="searchName"
                    readonly />
                  <button class="btn btn-primary" type="button" @click="openDialogCus">
                    <i class="material-icons material-symbols-outlined">search</i>
                  </button>
                  <button id="searchNameCus" class="btn btn-primary" type="button" data-bs-toggle="modal"
                    data-bs-target="#searchNameModal" v-show="false">
                  </button>
                </div>
              </div>

              <div class="col-lg-3">
                <label class="form-label ms-0">{{ $t("content.amnfinanceno") }}</label>
                <material-input type="text" v-model="search_payno" />
              </div>

            </div>




            <div class="row mt-4">
              <div class="col-3 d-flex justify-content-between align-items-center"></div>
              <div class="col-9 d-flex justify-content-end align-items-center">
                <div class="me-3">
                  <material-button id="cardSearch-fn" color="primary" class="float-lg-start float-sm-end mb-3"
                    @click="loadDataApi" :disabled="start_date > end_date">
                    <i class="material-icons material-symbols-outlined">search</i>
                    {{ $t("buttons.sear") }}
                  </material-button>
                  <button id="searchRef" class="btn btn-primary" type="button" data-bs-toggle="modal"
                    data-bs-target="#searchRefModal" v-show="false">
                  </button>
                </div>
                <div class="me-3">
                  <material-button id="cardSearch" color="danger" class="float-lg-start float-sm-end mb-3"
                    @click="cancelBtn">
                    <i class="material-icons material-symbols-outlined">close</i>
                    {{ $t("buttons.canc") }}
                  </material-button>
                </div>
              </div>
            </div>
            <hr />

            <div class="row mt-3">
              <div class="table-responsive">
                <table class="table table-flush">
                  <thead class="thead-light">
                    <tr>
                      <th>#</th>
                      <th>{{ $t("content.dant") }}</th>
                      <th>{{ $t("content.names") }}</th>
                      <th>{{ $t("content.transc") }}</th>
                      <th>{{ $t("content.transc_sub") }}</th>
                      <th>{{ $t("content.amnfinanceno") }}</th>
                      <th>{{ $t("content.fromsource") }}</th>
                      <th>{{ $t("content.file") }}</th>
                      <th colspan="5" class="align-top text-center" style="background-color:#dfffdd;">
                        <span style="color:black;">
                          {{ $t("content.amnincome") }}
                        </span>
                      </th>
                      <th colspan="4" class="align-top text-center" style="background-color:#fecac7;">
                        <span style="color:black;">
                          {{ $t("content.amnoutcome") }}
                        </span>
                      </th>
                      <th colspan="4" class="align-top text-center" style="background-color:#CFEBFD;">
                        <span style="color:black;">
                          {{ $t("content.fee") }}
                        </span>
                      </th>
                    </tr>
                    <tr>
                      <th colspan="8"></th>
                      <th class="align-top text-center" style="background-color:#dfffdd;">
                        <span style="color:black;">
                          {{ $t("content.amncash") }}
                        </span>
                      </th>
                      <th colspan="2" class="align-top text-center" style="background-color:#dfffdd;">
                        <span style="color:black;">
                          {{ $t("content.amnbanktransfer") }}
                        </span>
                      </th>
                      <th class="align-top text-center" style="background-color:#dfffdd;">
                        <span style="color:black;">
                          {{ $t("content.amncreditcard") }}
                        </span>

                      </th>
                      <th class="align-top text-center" style="background-color:#dfffdd;">
                        <span style="color:black;">
                          {{ $t("content.amncheque") }}
                        </span>
                      </th>
                      <th class="align-top text-center" style="background-color:#fecac7;">
                        <span style="color:black;">
                          {{ $t("content.amncash") }}
                        </span>
                      </th>
                      <th colspan="3" class="align-top text-center" style="background-color:#fecac7;">
                        <span style="color:black;">
                          {{ $t("content.amnbanktransfer") }}
                        </span>
                      </th>
                      <th class="align-top text-center" style="background-color:#CFEBFD;">
                        <span style="color:black;">
                          {{ $t("content.amncash") }}
                        </span>
                      </th>
                      <th colspan="2" class="align-top text-center" style="background-color:#CFEBFD;">
                        <span style="color:black;">
                          {{ $t("content.amnbanktransfer") }}
                        </span>
                      </th>
                      <th class="align-top text-center" style="background-color:#CFEBFD;">
                        <span style="color:black;">
                          {{ $t("content.amncreditcard") }}
                        </span>
                      </th>
                    </tr>
                    <tr>
                      <th colspan="8"></th>
                      <th style="background-color:#dfffdd;"></th>
                      <th class="align-top text-center" style="background-color:#dfffdd;">
                        <span style="color:black;">
                          {{ $t("content.amnbankacc") }}
                        </span>
                      </th>
                      <th class="align-top text-center" style="background-color:#dfffdd;">
                        <span style="color:black;">
                          QR Code
                        </span>
                      </th>
                      <th colspan="2" style="background-color:#dfffdd;"></th>
                      <th style="background-color:#fecac7;"></th>
                      <th class="align-top text-center" style="background-color:#fecac7;">
                        <span style="color:black;">
                          {{ $t("content.amnbankacc") }}
                        </span>
                      </th>
                      <th class="align-top text-center" style="background-color:#fecac7;">
                        <span style="color:black;">
                          {{ $t("content.amntransfer_promt_mobile") }}
                        </span>
                      </th>
                      <th class="align-top text-center" style="background-color:#fecac7;">
                        <span style="color:black;">
                          {{ $t("content.amntransfer_promt_taxid") }}
                        </span>
                      </th>
                      <th style="background-color:#CFEBFD;"></th>
                      <th class="align-top text-center" style="background-color:#CFEBFD;">
                        <span style="color:black;">
                          {{ $t("content.amnbankacc") }}
                        </span>
                      </th>
                      <th class="align-top text-center" style="background-color:#CFEBFD;">
                        <span style="color:black;">
                          QR Code
                        </span>
                      </th>
                      <th style="background-color:#CFEBFD;"></th>
                    </tr>

                  </thead>
                  <tbody>
                    <tr v-for="(row, index) in listjourney" :key="index">
                      <td class="text-sm font-weight-normal">
                        {{ formatNumberString((currentPage - 1) * perPage + index + 1) }}
                      </td>
                      <td class="text-sm font-weight-normal">
                        {{ format_datetime(row.txDate) }}
                      </td>
                      <td class="text-sm font-weight-normal">
                        {{ row.cusName }}
                      </td>
                      <td class="text-sm font-weight-normal">
                        {{ filterfundCategory(row.fundCategory) }}
                      </td>
                      <td class="text-sm font-weight-normal">
                        {{ filterfundTranType(row.fundTranTypeId) }}
                      </td>
                      <td class="text-sm font-weight-normal">
                        <span @click="openDialogDetail(row)"
                          style="text-decoration: underline;color:blue; cursor: pointer">
                          {{ row.refNumber }}
                        </span>

                      </td>
                      <td class="text-sm font-weight-normal">
                        <span v-if="row.accountType !== 'OTHERS'">
                          {{ filterMoneySource(row.accountUid) }}
                        </span>
                        <span v-else>
                          {{ $t("content.amnothers") }}
                        </span>
                      </td>

                      <td class="text-sm font-weight-normal">

                        <material-button color="primary" variant="outline" class="rounded-circle"
                          @click="getFileRef(row)" v-if="row.refDocUid !== '0'">
                          <i class="material-icons material-symbols-outlined">attach_file</i>
                        </material-button>
                        <material-button color="primary" data-bs-toggle="modal" data-bs-target="#attachmentsModal"
                          v-show="false" id="attachmenDialog">
                          <i class="material-icons material-symbols-outlined">attach_file</i>
                        </material-button>
                      </td>
                      <td class="text-sm font-weight-normal text-center">
                        <span v-if="row.cashInflow > 0 && row.paymentType === 'CASH'" style="color:green;">
                          {{ formatNumberDecimalString(row.cashInflow) }}
                        </span>
                      </td>
                      <td class="text-sm font-weight-normal text-center">
                        <span v-if="row.cashInflow > 0 && row.paymentType === 'BANK_TRANSFER'" style="color:green;">
                          {{ formatNumberDecimalString(row.cashInflow) }}
                        </span>
                      </td>
                      <td class="text-sm font-weight-normal text-center">
                        <span v-if="row.cashInflow > 0 && row.paymentType === 'QR_CODE'" style="color:green;">
                          {{ formatNumberDecimalString(row.cashInflow) }}
                        </span>
                      </td>
                      <td class="text-sm font-weight-normal text-center">
                        <span v-if="row.cashInflow > 0 && row.paymentType === 'CREDIT_CARD'" style="color:green;">
                          {{ formatNumberDecimalString(row.cashInflow) }}
                        </span>
                      </td>
                      <td class="text-sm font-weight-normal text-center">
                        <span v-if="row.cashInflow > 0 && row.paymentType === 'CHEQUE'" style="color:green;">
                          {{ formatNumberDecimalString(row.cashInflow) }}
                        </span>
                      </td>
                      <td class="text-sm font-weight-normal text-center">
                        <span v-if="row.cashOutflow > 0 && row.paymentType === 'CASH'" style="color:red;">
                          {{ formatNumberDecimalString(row.cashOutflow) }}
                        </span>
                      </td>
                      <td class="text-sm font-weight-normal text-center">
                        <span v-if="row.cashOutflow > 0 && row.paymentType === 'BANK_TRANSFER'" style="color:red;">
                          {{ formatNumberDecimalString(row.cashOutflow) }}
                        </span>
                      </td>
                      <td class="text-sm font-weight-normal text-center">
                        <span v-if="row.cashOutflow > 0 && row.paymentType === 'PROMPT_PAY_MOBILE'" style="color:red;">
                          {{ formatNumberDecimalString(row.cashOutflow) }}
                        </span>
                      </td>
                      <td class="text-sm font-weight-normal text-center">
                        <span v-if="row.cashOutflow > 0 && row.paymentType === 'PROMPT_PAY_TAX_ID'" style="color:red;">
                          {{ formatNumberDecimalString(row.cashOutflow) }}
                        </span>
                      </td>
                      <td class="text-sm font-weight-normal text-center">
                        <!-- <span v-if="row.cashInflow > 0 && row.paymentType === 'CASH'" style="color:blue;">
                          {{ formatNumberDecimalString(row.cashInflow) }}
                        </span> -->
                      </td>
                      <td class="text-sm font-weight-normal text-center">
                        <!-- <span v-if="row.cashInflow > 0 && row.paymentType === 'BANK_TRANSFER'" style="color:blue;">
                          {{ formatNumberDecimalString(row.cashInflow) }}
                        </span> -->
                      </td>
                      <td class="text-sm font-weight-normal text-center">
                        <!-- <span v-if="row.cashInflow > 0 && row.paymentType === 'QR_CODE'" style="color:blue;">
                          {{ formatNumberDecimalString(row.cashInflow) }}
                        </span> -->
                      </td>
                      <td class="text-sm font-weight-normal text-center">
                        <!-- <span v-if="row.cashInflow > 0 && row.paymentType === 'CREDIT_CARD'" style="color:blue;">
                          {{ formatNumberDecimalString(row.cashInflow) }}
                        </span> -->
                      </td>
                    </tr>
                    <tr v-if="listjourney.length === 0">
                      <td colspan="20" class="text-sm font-weight-normal">
                        <p style="text-align: center">No Record Found</p>
                      </td>
                    </tr>
                  </tbody>
                  <tfoot>
                    <tr>
                      <td colspan="25" style="text-align: right">Total Rows : {{ totalRows_s }}</td>
                    </tr>
                  </tfoot>
                </table>
              </div>
            </div>
            <pre></pre>
            <div style="display: flex; justify-content: flex-end">
              <ul v-show="listjourney.length > 0" class="pagination justify-content-end">
                <div class="project-container">
                  <div class="example-one">
                    <vue-awesome-paginate v-model="currentPage" :total-items="totalRows" :items-per-page="perPage"
                      @click="onClickHandler" />
                  </div>
                </div>
              </ul>
            </div>
          </div>
        </div>


        <!-- Attachments Dialog -->
        <div id="attachmentsModal" class="modal fade" tabindex="-1" aria-labelledby="lookupModalLabel"
          aria-hidden="true">
          <div class="modal-dialog modal-dialog-centered" style="max-width: 520px">
            <div class="modal-content">
              <div class="modal-header">
                <h4 class="modal-title">{{ $t("content.detail") }}</h4>
                <button type="button" class="close" data-bs-dismiss="modal" aria-label="Close">
                  <i class="material-icons material-symbols-outlined">close</i>
                </button>
              </div>

              <div class="modal-body">
                <div id="attachmentsImages" class="carousel slide" data-bs-ride="carousel">
                  <div class="carousel-indicators" id="demo2">
                  </div>
                  <div class="carousel-inner" id="demo3">
                  </div>
                  <button class="carousel-control-prev" type="button" data-bs-target="#attachmentsImages"
                    data-bs-slide="prev">
                    <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                    <span class="visually-hidden">Previous</span>
                  </button>
                  <button class="carousel-control-next" type="button" data-bs-target="#attachmentsImages"
                    data-bs-slide="next">
                    <span class="carousel-control-next-icon" aria-hidden="true"></span>
                    <span class="visually-hidden">Next</span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>


        <!-- Search Refnumber Dialog -->
        <div id="searchRefModal" class="modal fade" tabindex="-1" aria-labelledby="searchRefModalLabel"
          aria-hidden="true">
          <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
              <div class="modal-header">
                <h4 class="modal-title">{{ $t("content.detfinanceinfo") }}</h4>
                <button type="button" class="close" data-bs-dismiss="modal" aria-label="Close" id="closeModal">
                  <i class="material-icons material-symbols-outlined">close</i>
                </button>
              </div>

              <div class="modal-body">
                <div class="preview-table">
                  <section class="panel">
                    <table class="table table-responsive">
                      <thead>
                        <tr>
                          <th>#</th>
                          <th>{{ $t("content.amnticketold") }}</th>
                          <th>{{ $t("content.ticketno") }}</th>
                          <th>{{ $t("content.migraref") }}</th>
                          <th>{{ $t("content.amnbiz") }}</th>
                          <th>{{ $t("content.amnanalysval") }}</th>
                          <th>{{ $t("content.gpay") }}</th>
                          <th>{{ $t("content.status") }}</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="(row, index) in listrefDetail" :class="row.status != 'TERMINATE' ? '' : 'bg-working'"
                          :key="index">
                          <template v-if="row.ticket !== null && row.transactionType !== 'PAYMENT'">
                            <td>{{ formatNumberString(index + 1) }}</td>
                            <td>{{ row.prevRefNumber }}</td>
                            <td>{{ row.tkRefNumber }}</td>
                            <td>{{ row.migrateRefNumber }}</td>
                            <td>
                              <span v-if="row.migration">
                                {{ checkTransaction(row.transactionType) }} ({{ checkTransaction(row.migrationType) }})
                              </span>
                              <span v-else>
                                {{ checkTransaction(row.transactionType) }}
                              </span>
                            </td>
                            <td>
                              <span v-if="row.transactionType !== 'REDEEM'">
                                {{ formatNumberDecimalString(row.newPawnValue) }}
                              </span>
                              <span v-else>
                                {{ formatNumberDecimalString(row.pawnValue) }}
                              </span>
                            </td>
                            <td>
                              <span v-if="row.migration">
                                <span
                                  v-if="row.migrationType === 'PAWN' || row.migrationType === 'INC_PRINCIPLE' && row.totalPay > 0"
                                  style="color:red;">
                                  {{ formatNumberDecimalString(row.totalPay) }}
                                </span>
                                <span v-else-if="row.totalReceive > 0" style="color:green;">
                                  {{ formatNumberDecimalString(row.totalReceive) }}
                                </span>
                                <span v-else style="color:gray;">
                                  {{ formatNumberDecimalString(row.totalReceive) }}
                                </span>
                              </span>
                              <span v-else>
                                <span
                                  v-if="row.transactionType === 'PAWN' || row.transactionType === 'INC_PRINCIPLE' && row.totalPay > 0"
                                  style=" color:red;">
                                  {{ formatNumberDecimalString(row.totalPay) }}
                                </span>
                                <span v-else-if="row.totalReceive > 0" style="color:green;">
                                  {{ formatNumberDecimalString(row.totalReceive) }}
                                </span>
                                <span v-else style="color:gray;">
                                  {{ formatNumberDecimalString(row.totalReceive) }}
                                </span>
                              </span>
                            </td>
                            <td>
                              {{ checkProcess(row.status) }}
                            </td>
                          </template>
                        </tr>
                        <tr v-if="listrefDetail.length === 0">
                          <td colspan="5" class="text-sm font-weight-normal">
                            <p style="text-align: center">No Record Found</p>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </section>
                </div>
              </div>

              <div class="modal-footer">
              </div>
            </div>
          </div>
        </div>

        <!-- Search Customer Dialog -->
        <div id="searchNameModal" class="modal fade" tabindex="-1" aria-labelledby="searchNameModalLabel"
          aria-hidden="true">
          <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
              <div class="modal-header">
                <h4 class="modal-title">{{ $t("content.cnamelist") }}</h4>
                <button type="button" class="close" data-bs-dismiss="modal" aria-label="Close" id="closecusModal">
                  <i class="material-icons material-symbols-outlined">close</i>
                </button>
              </div>

              <div class="modal-body">
                <div class="preview-table">
                  <section class="panel">
                    <table class="table table-striped table-responsive">
                      <thead>
                        <tr>
                          <!-- <th style="width: 100px">{{ $t("content.ccode") }}</th> -->
                          <th>#</th>
                          <th>{{ $t("content.cnameth") }}</th>
                          <th>{{ $t("content.cnameen") }}</th>
                          <th>{{ $t("content.docno") }}</th>
                          <th></th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr class="row-search">
                          <td></td>
                          <td>
                            <material-input v-model="nameThC" type="text" :label="t('content.cnameth')" />
                          </td>
                          <td>
                            <material-input v-model="nameEnC" type="text" :label="t('content.cnameen')" />
                          </td>
                          <td>
                            <material-input v-model="docNumberC" type="text" :label="t('content.docno')" />
                          </td>
                          <td>
                            <material-button color="primary" variant="contain" class="rounded-circle ms-1"
                              data-bs-toggle="tooltip" data-bs-placement="top" :title="btnSearch"
                              :disabled="nameThC.length < 2 && nameEnC.length < 2 && docNumberC.length < 5"
                              @click="loadCustomerAfListApi">
                              <i class="material-icons material-symbols-outlined">search</i>
                            </material-button>
                          </td>
                        </tr>
                        <tr v-for="(row, index) in customerlist" :key="index" @click="selectCusAf(row)">
                          <td>{{ formatNumberString((currentPageC - 1) * perPageC + index + 1) }}</td>
                          <td>{{ row.nameTh }}</td>
                          <td>{{ row.nameEn }}</td>
                          <td colspan="2">{{ row.docNumber }}</td>
                        </tr>
                        <tr v-if="customerlist.length === 0">
                          <td colspan="5" class="text-sm font-weight-normal">
                            <p style="text-align: center">No Record Found</p>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </section>
                </div>
              </div>

              <div class="modal-footer">
                <pre></pre>
                <div style="display: flex; justify-content: flex-end">
                  <ul v-show="customerlist.length > 0" class="pagination justify-content-end">
                    <div class="project-container">
                      <div class="example-one">
                        <vue-awesome-paginate v-model="currentPageC" :total-items="totalRowsC"
                          :items-per-page="perPageC" @click="onClickHandlerC" />
                      </div>
                    </div>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>


      </div>
    </div>
  </div>
</template>

<script setup>
/* eslint-disable no-unused-vars */
import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css'
import { reactive, computed, ref } from "vue";
import { useI18n } from "vue-i18n";


import MaterialButton from "@/components/MaterialButton.vue";
import MaterialInput from "@/components/MaterialInput.vue";
import ExampleFive from "@/examples/ExampleOne";

import axios from "axios";
import * as st from "@/config/setting.js";
import moment from 'moment'


import { useCashAccountStore } from '@/stores/cashaccount'
import { useShopBankStore } from '@/stores/shopbank'
import { useFinancialTranTypeStore } from '@/stores/financialtrantype'
import { useFundTxStore } from '@/stores/fundtx'
import { useJourneyStore } from '@/stores/journey'
import { usePaymentGroupStore } from '@/stores/paymentgroup'
import { useCustomerStore } from '@/stores/customer'


const storec = useCashAccountStore()
const storesb = useShopBankStore()
const storef = useFinancialTranTypeStore()
const storejo = useJourneyStore()
const storefund = useFundTxStore()
const storepay = usePaymentGroupStore()
const storecus = useCustomerStore()


const format = (date) => {
  return moment(String(date)).local().format('DD/MM/YYYY');
}

const textInputOptions = {
  format: 'dd/MM/yyyy'
};

const { t } = useI18n();

const datas = reactive({
  types: [
    {
      id: "selectedDepo",
      vl: 0,
      lb: "content.ctranstde",
    },
    {
      id: "selectedWithd",
      vl: 1,
      lb: "content.ctranstwi",
    },
    {
      id: "selectedTrans",
      vl: 2,
      lb: "content.ctransttr",
    },
  ],
  FinancAll: [
    { items: computed(() => t("content.ctranstin")), val: 'INTERNAL' },
    { items: computed(() => t("content.ctranstout")), val: 'EXTERNAL' },
    { items: computed(() => t("content.direct_lo")), val: 'DIRECTOR_LOAN' },
    { items: computed(() => t("content.sell_pr")), val: 'SELLING_PRODUCTS' },
    { items: computed(() => t("content.txfee")), val: 'FEE_TRANSACTION' },
    { items: computed(() => t("content.fee")), val: 'FEE' },
    { items: computed(() => t("content.ctranstexp")), val: 'EXPENSE' },
    { items: computed(() => t("content.ctranstacc")), val: 'ACCOUNT_CLOSURE' },
    { items: computed(() => t("content.acc_adj")), val: 'ACCOUNT_ADJUST' },
  ],
});

const shop_detail = ref(null)
const listcashAcc = ref([]);
const listbankpawn = ref([]);
const start_date = ref(new Date(new Date().setHours(0, 0, 0, 0)));
const end_date = ref(new Date());
const listfinancialtrans = ref([]);
const listjourney = ref([]);

const search_payno = ref('');
const search_cat = ref('');
const search_subcat = ref('');
const search_trans = ref('');
const search_sourcemoney = ref('');
const listsubcat = ref([]);

const currentPage = ref(1);
const totalRows = ref(1);
const perPage = ref(10);
const totalRows_s = ref();


const loading = ref(false);
const images = ref([])

const cusUid_search = ref('');
const cusName_search = ref('');
const customerlist = ref([])

const currentPageC = ref(1);
const totalRowsC = ref(1);
const perPageC = ref(10);
const totalRows_sC = ref();

const nameThC = ref('')
const nameEnC = ref('')
const docNumberC = ref('')

const listtx = ref([
  { items: computed(() => t("content.cuttrans")), val: 'PAWN_TRANSACTION' },
  { items: computed(() => t("content.ctranstin")), val: 'INTERNAL' },
  { items: computed(() => t("content.ctranstout")), val: 'EXTERNAL' },
  { items: computed(() => t("content.direct_lo")), val: 'DIRECTOR_LOAN' },
  { items: computed(() => t("content.sell_pr")), val: 'SELLING_PRODUCTS' },
  { items: computed(() => t("content.txfee")), val: 'FEE_TRANSACTION' },
  { items: computed(() => t("content.fee")), val: 'FEE' },
  { items: computed(() => t("content.ctranstexp")), val: 'EXPENSE' },
  { items: computed(() => t("content.ctranstacc")), val: 'ACCOUNT_CLOSURE' },
  { items: computed(() => t("content.acc_adj")), val: 'ACCOUNT_ADJUST' },
]);

const listrefDetail = ref([]);

const onClickHandler = () => {
  updatePagination()
};

const onClickHandlerC = () => {
  updatePaginationC()
};


function cancelBtn() {
  start_date.value = new Date(new Date().setHours(0, 0, 0, 0));
  end_date.value = new Date();
  currentPage.value = 1
  listjourney.value = []
  totalRows_s.value = ''
  search_cat.value = '';
  search_subcat.value = '';
  search_trans.value = '';
  search_sourcemoney.value = ''
  cusUid_search.value = ''
  cusName_search.value = '';
  search_payno.value = '';

  loadDataApi()

}


function format_datetime(value) {
  if (value) {
    return moment(String(value)).local().format('DD/MM/YYYY HH:mm')
  }
}


const getshopInfoApi = async (val) => {
  shop_detail.value = null
  const response = await storec.cashaccountInfoFromSessionFetch();
  if (response) {
    if (response.status === 200) {
      if (response.data.rtnDesc === "success") {
        // console.log("cashAccShopApi : " + JSON.stringify(response.data.data));
        shop_detail.value = response.data.data
        await loadCashAccDataApi()
        await loadBankPawnApi()
        await loadFinancialtransApi()
        await loadDataApi()
      }
    }
  }
}

getshopInfoApi()

const loadBankPawnApi = async (val) => {
  listbankpawn.value = [];
  let data = "";
  data = {
    index: 0,
    size: 100,
    bankUid: '',
    shortName: '',
    acctNumber: '',
    shopUid: shop_detail.value.uid
  };
  const payload = data;
  // console.log("payload : " + JSON.stringify(payload));
  const response = await storesb.shopbankListFetch(payload);
  // console.log("loadBankPawnApi : " + JSON.stringify(response.data));
  if (response) {
    if (response.status === 200) {
      if (response.data.rtnDesc === "success") {
        // listbankpawn.value = response.data.data.list;
        let listd = response.data.data.list;
        for (let i = 0; i < listd.length; i++) {
          if (listd[i].channels.includes('BO_WEB')) {
            listbankpawn.value.push(listd[i])
          }
        }

      }
    }
  }
}


const loadCashAccDataApi = async (val) => {
  listcashAcc.value = [];
  let data = "";
  data = {
    index: 0,
    size: 100,
    name: "",
    shopUid: shop_detail.value.uid
  };
  const payload = data;
  // console.log("payload : " + JSON.stringify(payload));
  const response = await storec.cashaccountListFetch(payload);
  // console.log("loadCashAccDataApi : " + JSON.stringify(response.data));
  if (response) {
    if (response.status === 200) {
      if (response.data.rtnDesc === "success") {
        // listcashAcc.value = response.data.data.list;
        let listd = response.data.data.list;
        for (let i = 0; i < listd.length; i++) {
          if (listd[i].channels.includes('BO_WEB')) {
            listcashAcc.value.push(listd[i])
          }
        }
      }
    }
  }
}

const loadFinancialtransApi = async (val) => {
  listfinancialtrans.value = [];
  let data = "";
  data = {
    index: 0,
    size: 100,
    name: "",
    shopUid: shop_detail.value.uid
  };
  const payload = data;
  // console.log("payload : " + JSON.stringify(payload));
  const response = await storef.financialTranTypeListFetch(payload);
  // console.log("loadFinancialtransApi : " + JSON.stringify(response.data));
  if (response) {
    if (response.status === 200) {
      if (response.data.rtnDesc === "success") {
        listfinancialtrans.value = response.data.data.list;
      }
    }
  }
}


const getTxTypeApi = async () => {

  const response = await storefund.getTxTypeFetch();
  if (response) {
    if (response.status === 200) {
      if (response.data.rtnDesc === "success") {
        // console.log("TxType : " + JSON.stringify(response.data.data));
      }
    }
  }
}

const loadfundCategoryApi = async () => {

  const response = await storefund.getfundCategoryInfoFetch();
  if (response) {
    if (response.status === 200) {
      if (response.data.rtnDesc === "success") {
        // console.log("fundCategory : " + JSON.stringify(response.data.data));
      }
    }
  }
}



const loadDataApi = async (val) => {
  let data = "";
  let sd = ''
  let ed = ''
  sd = moment(new Date(start_date.value)).format('YYYY-MM-DD')
  ed = moment(new Date(end_date.value)).format('YYYY-MM-DD')

  let accountType = ''
  let accountUid = ''

  if (search_sourcemoney.value !== '' || search_cat.value !== '' || search_subcat.value !== '' || cusName_search.value !== '' || search_payno.value !== '') {
    currentPage.value = 1
  }

  if (search_sourcemoney.value !== '') {
    if (search_sourcemoney.value !== 'OTHERS') {
      accountType = checkMoneySource(search_sourcemoney.value)
      accountUid = search_sourcemoney.value
    } else {
      accountType = 'OTHERS'
    }
  }

  let txType = ''
  if (search_trans.value === 'txTransfer') {
    txType = "TRANSFER"
  } else if (search_trans.value === 'txWithdrawal') {
    txType = "WITHDRAW"

  } else if (search_trans.value === 'txDeposit') {
    txType = "DEPOSIT"
  }

  data = {
    index: currentPage.value - 1,
    size: perPage.value,
    start: sd,
    end: ed,
    fundCategory: search_cat.value,
    fundTranTypeId: search_subcat.value,
    accountUid: accountUid,
    accountType: accountType,
    cusName: cusName_search.value,
    refNumber: search_payno.value.trim(),

  };


  const payload = data;
  // console.log("payload : " + JSON.stringify(payload));
  const response = await storejo.journeyListFetch(payload);
  // console.log("journey  res : " + JSON.stringify(response.data));
  if (response) {
    if (response.status === 200) {
      if (response.data.rtnDesc === "success") {
        listjourney.value = response.data.data.list;
        totalRows.value = response.data.data.ext.total
        totalRows_s.value = formatNumberString(totalRows.value)
      }
    }
  }
}

async function updatePagination() {
  let data = "";
  let sd = ''
  let ed = ''
  sd = moment(new Date(start_date.value)).format('YYYY-MM-DD')
  ed = moment(new Date(end_date.value)).format('YYYY-MM-DD')

  let accountType = ''
  let accountUid = ''

  if (search_sourcemoney.value !== '') {
    if (search_sourcemoney.value !== 'OTHERS') {
      accountType = checkMoneySource(search_sourcemoney.value)
      accountUid = search_sourcemoney.value
    } else {
      accountType = 'OTHERS'
    }
  }

  let txType = ''
  if (search_trans.value === 'txTransfer') {
    txType = "TRANSFER"
  } else if (search_trans.value === 'txWithdrawal') {
    txType = "WITHDRAW"

  } else if (search_trans.value === 'txDeposit') {
    txType = "DEPOSIT"
  }

  data = {
    index: currentPage.value - 1,
    size: perPage.value,
    start: sd,
    end: ed,
    fundCategory: search_cat.value,
    fundTranTypeId: search_subcat.value,
    accountUid: accountUid,
    accountType: accountType,
    cusName: cusName_search.value,
    refNumber: search_payno.value.trim(),
  };


  const payload = data;
  // console.log("payload : " + JSON.stringify(payload));
  const response = await storejo.journeyListFetch(payload);
  // console.log("loadDataApi  res : " + JSON.stringify(response.data));
  if (response) {
    if (response.status === 200) {
      if (response.data.rtnDesc === "success") {
        listjourney.value = response.data.data.list;
        totalRows.value = response.data.data.ext.total
        totalRows_s.value = formatNumberString(totalRows.value)
      }
    }
  }
}

function formatNumberString(num) {
  return String(num).replace(
    /(\d)(?=(\d{3})+(?!\d))/g,
    "$1,"
  );
}

function formatNumberDecimalString(num) {
  if (num !== null && num !== undefined && num !== '') {
    num = num.toFixed(2)
  } else {
    num = 0
    num = num.toFixed(2)
  }
  return String(num).replace(
    /(\d)(?=(\d{3})+(?!\d))/g,
    "$1,"
  );
}



function checkSubCat() {
  listsubcat.value = []
  search_subcat.value = ''
  // search_trans.value = ''
  // listtransc.value = []

  if (search_cat.value !== '') {
    let ld = listfinancialtrans.value.filter((c) => c.finCat === search_cat.value);
    if (ld.length > 0) {
      listsubcat.value = ld
    }
  }

  return listsubcat.value
}

function checkMoneySource(str) {
  let side = ''
  if (str !== '0') {
    let ld = filterBank(str)
    if (ld.length > 0) {
      side = "BANK_ACCOUNT"
    } else {
      ld = filterCash(str)
      if (ld.length > 0) {
        side = "CASH_ACCOUNT"
      }
    }
  }
  return side
}

function filterCash(str) {
  return listcashAcc.value.filter((c) => c.uid === str);
}

function filterBank(str) {
  return listbankpawn.value.filter((c) => c.uid === str);
}


function filterfundCategory(str) {
  let side = ''
  // side = datas.FinancAll.filter((c) => c.val === str);
  let ld = listtx.value.filter((c) => c.val === str);
  if (ld.length > 0) {
    side = ld[0].items
  }
  return side
}

function filterfundTranType(str) {
  let side = ''
  let ld = listfinancialtrans.value.filter((c) => c.uid === str);
  if (ld.length > 0) {
    side = ld[0].name
  }
  return side
}


function filterMoneySource(str) {
  let side = ''
  if (str !== '0') {
    let ld = filterBank(str)
    if (ld.length > 0) {
      side = ld[0].shortName
    } else {
      ld = filterCash(str)
      if (ld.length > 0) {
        side = ld[0].name
      }
    }
  }
  return side
}


async function openDialogCus() {
  nameThC.value = ''
  nameEnC.value = ''
  docNumberC.value = ''
  cusUid_search.value = ''
  cusName_search.value = ''
  customerlist.value = []
  document.getElementById("searchNameCus").click();
}

async function selectCusAf(item) {
  // console.log('selectCusAf : ' + JSON.stringify(item));
  cusUid_search.value = item.uid
  cusName_search.value = item.nameTh
  document.getElementById("closecusModal").click();
}


async function loadCustomerAfListApi() {
  currentPageC.value = 1
  let data = "";
  data = {
    index: currentPageC.value - 1,
    size: perPageC.value,
    nameEn: nameEnC.value,
    nameTh: nameThC.value,
    docNumber: docNumberC.value

  };

  const payload = data;
  // console.log("payload : " + JSON.stringify(payload));
  const response = await storecus.searchCustomerFetch(payload);
  // console.log("loadCustomerListApi : " + JSON.stringify(response.data));
  if (response) {
    if (response.status === 200) {
      if (response.data.rtnDesc === "success") {
        let listd = response.data.data.list;
        customerlist.value = listd;
        totalRowsC.value = response.data.data.ext.total
        totalRows_sC.value = formatNumberString(totalRowsC.value)
      }
    }
  }
}

async function updatePaginationC() {
  customerlist.value = []
  let data = "";
  data = {
    index: currentPageC.value - 1,
    size: perPageC.value,
    nameEn: nameEnC.value,
    nameTh: nameThC.value,
    docNumber: docNumberC.value

  };
  const payload = data;
  // console.log("payload : " + JSON.stringify(payload));
  const response = await storecus.searchCustomerFetch(payload);
  // console.log("loadCashAccDataApi : " + JSON.stringify(response.data));
  if (response) {
    if (response.status === 200) {
      if (response.data.rtnDesc === "success") {
        customerlist.value = response.data.data.list;
        totalRowsC.value = response.data.data.ext.total
        totalRows_sC.value = formatNumberString(totalRowsC.value)
      }
    }
  }
}



async function getFileRef(item) {
  loading.value = true
  let url = []
  images.value = []
  url.push(String(st.url_api + "/doc-svc/document/info/" + item.refDocUid));
  if (url.length > 0) {
    await getFile(url)
    if (images.value.length > 0) {
      if (images.value[0].title === 'application/pdf') {
        window.open(images.value[0].src, '_blank').focus();
      } else {
        setGallery2()
        document.getElementById("attachmenDialog").click();
      }

      loading.value = false
    }
  }
}


async function getFile(urls) {
  let file_L = []
  const key = JSON.parse(localStorage.getItem("FR-SDK-nvtx-boapp"));
  const accessToken = key.accessToken;
  axios.defaults.headers["Authorization"] = `Bearer ${accessToken}`;
  const requests = urls.map((url) => axios.get(url));
  await Promise.all(requests).then(allResults => {
    if (allResults) {
      file_L = allResults
      if (file_L !== null) {
        // console.log('file_L : ' + JSON.stringify(file_L))
        for (let i = 0; i < file_L.length; i++) {
          // console.log('file_L : ' + JSON.stringify(file_L.value[i].data.data) + ' file_L 2 : ' + JSON.stringify(file_L.value[i].data.name));
          if (file_L[i].data.data !== null) {
            const file = getFileFromBase64(file_L[i].data.data.data, file_L[i].data.data.name)
            // console.log('file : ' + file.size + ' file t ' + file.type)
            const data = URL.createObjectURL(file);
            let obj = {
              src: data,
              thumbnailImageSrc: data,
              alt: '',
              title: file.type,
              filename: file_L[i].data.data.name,
            }
            images.value.push(obj)
          } else {
            console.log('no dataFile ')
            loading.value = false
          }

          // console.log('File xx : ', file_L.value[i].file.type + 'File size : ', file_L.value[i].file.size + ' bytes in size  name : ' + file_L.value[i].data.data.name);
        }
      }
    }
  })

}


function getFileFromBase64(string64, fileName) {
  const trimmedString = string64.replace('dataimage/jpegbase64', '');
  var ext = fileName.split('.').pop();
  let type = '';
  let imageContent = null
  if (ext === 'jpg') {
    type = 'image/jpeg'
  } else if (ext === 'png') {
    type = 'image/png'
  } else if (ext === 'pdf') {
    type = 'application/pdf'
  }

  if (type === 'application/pdf') {
    imageContent = atob(string64);
  } else {
    imageContent = atob(trimmedString);
  }

  const buffer = new ArrayBuffer(imageContent.length);
  const view = new Uint8Array(buffer);

  for (let n = 0; n < imageContent.length; n++) {
    view[n] = imageContent.charCodeAt(n);
  }

  const blob = new Blob([buffer], { type });
  return new File([blob], fileName, { lastModified: new Date().getTime(), type });
}




function setGallery2() {

  var x = "", i, y = "";

  for (i = 0; i < images.value.length; i++) {

    if (i == 0) {
      x = x + '<div class="carousel-item active"> ' +
        '<img src="' + images.value[i].src + '" class="d-block w-100" alt="..." />' +
        '</div>';
      y = y + '<button type = "button" data-bs-target="#attachmentsImages" data-bs-slide-to="' + String(i) + '"' +
        ' class="active" aria-current="true" aria-label="Slide ' + Number(i + 1) + '"> </button >';
    } else {
      x = x + '<div class="carousel-item"> ' +
        '<img src="' + images.value[i].src + '" class="d-block w-100" alt="..." />' +
        '</div>';
      y = y + '<button type = "button" data-bs-target="#attachmentsImages" data-bs-slide-to="' + String(i) + '"' +
        ' aria-label="Slide ' + Number(i + 1) + '"> </button >';
    }

  }
  document.getElementById("demo2").innerHTML = y;
  document.getElementById("demo3").innerHTML = x;

}

function checkTransaction(str) {
  // PAWN, INTEREST, REDEEM, INC_PRINCIPLE, DEC_PRINCIPLE
  let side = ''
  if (str === 'PAWN') {
    side = 'จำนำ'
  } else if (str === 'INTEREST') {
    side = 'ต่อดอกเบี้ย'
  } else if (str === 'REDEEM') {
    side = 'ไถ่ถอน'
  } else if (str === 'INC_PRINCIPLE') {
    side = 'เพิ่มต้น'
  } else if (str === 'DEC_PRINCIPLE') {
    side = 'ลดต้น'
  }
  return side
}


async function openDialogDetail(item) {
  // console.log("item : " + JSON.stringify(item));
  listrefDetail.value = []
  await getRefDetail(item.transactionUid)

}


async function getRefDetail(uid) {
  let data = "";
  data = {
    uid: uid,
  };
  const payload = data;
  // console.log("Ref : " + JSON.stringify(payload));
  const response = await storepay.paymentGroupinfoFetch(payload);
  // console.log("Ref r : " + JSON.stringify(response.data));
  if (response) {
    if (response.status === 200) {
      if (response.data.rtnDesc === "success") {
        listrefDetail.value = response.data.data.workCases
        // console.log("Ref : " + JSON.stringify(listrefDetail.value));
        document.getElementById("searchRef").click();
      }
    }
  }
}


function checkProcess(str) {
  let side = ''
  if (str === 'FINISH') {
    side = 'เสร็จสิ้น'
  } else if (str === 'ACTIVE') {
    side = 'กำลังดำเนินการ'
  } else if (str === 'FREEZE') {
    side = 'อายัด'
  } else if (str === 'TERMINATE') {
    side = 'ยกเลิก'
  }
  return side
}



</script>

<style lang="scss" scoped></style>
